<div class="u-section-2 u-clearfix u-layout-wrap vote-container">
    <div *ngIf="this.epe.sop_status == 'pending'" class="u-layout sopcard">
        <div class="u-layout-col">
            <div class="u-container-style u-layout-cell u-layout-cell-small-card">
                <h6 class="u-text u-text-small">Eligible</h6>
                <input (click)="change()" *ngIf="!checked && auth.isCallManager()" class="form-check-input" type="checkbox">
                <input (click)="change()" *ngIf="checked && auth.isCallManager()" class="form-check-input checked" type="checkbox">
            </div>
            <div>
                <label>This Epe is in pending state, he will stay in this status until a Call Manager evaluates it.</label>
            </div>
            <div *ngIf="auth.isCallManager()">
                <button (click)="submitEligibility()" class="u-btn sop-btn">Submit</button>
            </div>
        </div>
    </div>
    <div *ngIf="this.epe.sop_status == 'not_eligible'" class="u-layout sopcard">
        <div class="u-layout-col">
            <div>
                <label>This Epe has been evaluated Not Eligible.</label>
            </div>
        </div>
    </div>
    <div *ngIf="this.epe.sop_status != 'pending' && this.epe.sop_status != 'not_eligible'" class="u-layout sopcard vote-layout">
        <div>
            <div class="vote-field">    
                <div class="vote-text"><h6>Criteria 1: <br>Roles and Years of Specific Experience in TWGs (30%)</h6></div>
                <input class="vote-input" type="number" [ngModel]="vote.automatic" disabled>
                <div class="vote-text-static"> / 10 </div>
            </div>
            <div class="vote-field">
                <div class="vote-text"><h6>Criteria 2: <br>TWG Participation (30%)</h6></div>
                <input *ngIf="!votedByMe && auth.isProjectBoard()" min=0 max=10 [(ngModel)]="engagment" class="vote-input" type="number">
                <input *ngIf="votedByMe" min=0 max=10  [(ngModel)]="engagment" class="vote-input" type="number" disabled>
                <div class="vote-text-static"> / 10 </div>
            </div>
            <div class="vote-field">
                <div class="vote-text"><h6>Criteria 3: <br>Other Expertise and Professional Background (30%)</h6></div>            
                <input *ngIf="!votedByMe && auth.isProjectBoard()" min=0 max=10  [(ngModel)]="standardisation" class="vote-input" type="number"> 
                <input *ngIf="votedByMe" min=0 max=10  [(ngModel)]="standardisation" class="vote-input" type="number" disabled> 
                <div class="vote-text-static"> / 10 </div>
            </div>
            <div class="vote-field">    
                <div class="vote-text"><h6>Criteria 4: <br>Gender Balance (10%)</h6></div>
                <input class="vote-input" type="number" [ngModel]="vote.gender"  disabled> 
                <div class="vote-text-static"> / 10 </div>
            </div>
            <button *ngIf="auth.isProjectBoard() && !votedByMe && this.epe.sop_status!='voted'" (click)="submitVote()" class="u-btn sop-btn">Submit</button>
            <label *ngIf="auth.isProjectBoard() && votedByMe && this.epe.sop_status!='voted'" >You already voted this EPE</label>
            <div *ngIf="isWarning()" class="warning-msg">
                <label *ngIf="epe.engVariance! >= 2"> <img style="margin-bottom:3px" src="/assets/icons/warning.png"> High variance value on Criteria 2 votes.</label>
                <label *ngIf="epe.standVariance! >= 2"> <img style="margin-bottom:3px" src="/assets/icons/warning.png"> High variance value on Criteria 3 votes.</label>
            </div>
            <button *ngIf="auth.isCallManager() && this.epe.sop_status!='voted'" (click)="stopVote()" class="u-btn sop-btn">Close Voting session for all</button>
        </div>
    </div>
    <div class="u-layout sopcard" *ngIf="this.epe.sop_status != 'pending' && (auth.isCallManager() || auth.isProjectBoard())">
        <div>
            <div>
                <h6 class="u-text u-text-small">Take a note about the EPE</h6>
            </div>
            <div>
                <form (ngSubmit)="submitNote()" #noteForm="ngForm">
                <div class="form-group">
                    <!-- <label for="note">Note for the EPE</label> -->
                    <textarea class="form-control" id="note"
                        [(ngModel)]="note" name="note" rows="5" cols="50">
                    </textarea>
                </div>
                <button type="submit" class="u-btn sop-btn">Submit note</button>
                </form>
            </div>
        </div>
    </div>
</div>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert" ></simple-alert>