import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "app/shared/shared.module";
import { FirstMeetingComponent } from "./first-meeting/first-meeting.component";
import { DateSettedComponent } from "./form-steps/date-setted/date-setted.component";
import { MeetingCompletedComponent } from "./form-steps/meeting-completed/meeting-completed.component";
import { MeetingFormComponent } from "./meeting-form.component";
import { MeetingFormRoutingModule } from "./meeting-form.routing";
import { MeetingFormService } from "./meeting-form.service";
import { SecondMeetingComponent } from './second-meeting/second-meeting.component';
import { ThirdMeetingComponent } from './third-meeting/third-meeting.component';
import { FourthMeetingComponent } from './fourth-meeting/fourth-meeting.component';
import { FormComposerComponent } from './form-steps/form-composer/form-composer.component';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { AvailableFilesComponent } from "./available-files/available-files.component";
import { SurveyComponent } from './survey/survey.component';
import { MaterialsComponent } from "./form-steps/materials/materials.component";
import { FirstMeetingTemplateComponent } from './form-steps/template/first-meeting-template/first-meeting-template.component';
import { FollowupTemplateComponent } from './form-steps/template/followup-template/followup-template.component';
import { NextMeetingTemplateComponent } from './form-steps/template/next-meeting-template/next-meeting-template.component';
import { FinalMeetingTemplateComponent } from './form-steps/template/final-meeting-template/final-meeting-template.component';
import { FirstMeetingFirstStepComponent } from './form-steps/first-meeting-first-step/first-meeting-first-step.component';
import { FirstMeetingSecondStepComponent } from './form-steps/first-meeting-second-step/first-meeting-second-step.component';
import { NextOrSkipMeetingsComponent } from './form-steps/next-or-skip-meetings/next-or-skip-meetings.component';
import { SecondMeetingFirstStepComponent } from './form-steps/second-meeting-first-step/second-meeting-first-step.component';
import { SecondMeetingSecondStepComponent } from './form-steps/second-meeting-second-step/second-meeting-second-step.component';
import { ThirdMeetingSecondStepComponent } from './form-steps/third-meeting-second-step/third-meeting-second-step.component';
import { ThirdMeetingFirstStepComponent } from './form-steps/third-meeting-first-step/third-meeting-first-step.component';
import { FifthMeetingFirstStepComponent } from './form-steps/fifth-meeting-first-step/fifth-meeting-first-step.component';
import { FifthMeetingSecondStepComponent } from './form-steps/fifth-meeting-second-step/fifth-meeting-second-step.component';
import { FifthMeetingThirdStepComponent } from './form-steps/fifth-meeting-third-step/fifth-meeting-third-step.component';
import { AdviceStepComponent } from "./form-steps/advice-step/advice-step.component";
import { FifthMeetingComponent } from "./fifth-meeting/fifth-meeting.component";
import { SurveyFormComponent } from './survey-form/survey-form.component';
import { SurveyAdminModule } from "./survey-admin/survey-admin.module";
import { SurveyProjectMeetingModule } from './survey-project-meeting/survey-project-meeting.module';
import { SurveyProjectAdviceModule } from './survey-project-advice/survey-project-advice.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MeetingFormRoutingModule,
        SharedModule,
        CKEditorModule,
        SurveyProjectMeetingModule,
        SurveyProjectAdviceModule,
        SurveyAdminModule
    ],
    declarations: [
        MeetingFormComponent,
        FirstMeetingComponent,
        DateSettedComponent,
        MeetingCompletedComponent,
        SecondMeetingComponent,
        ThirdMeetingComponent,
        FourthMeetingComponent,
        FormComposerComponent,
        AvailableFilesComponent,
        SurveyComponent,
        MaterialsComponent,
        FirstMeetingTemplateComponent,
        FollowupTemplateComponent,
        NextMeetingTemplateComponent,
        FinalMeetingTemplateComponent,
        FirstMeetingFirstStepComponent,
        FirstMeetingSecondStepComponent,
        NextOrSkipMeetingsComponent,
        SecondMeetingFirstStepComponent,
        SecondMeetingSecondStepComponent,
        ThirdMeetingSecondStepComponent,
        ThirdMeetingFirstStepComponent,
        FifthMeetingFirstStepComponent,
        FifthMeetingSecondStepComponent,
        FifthMeetingThirdStepComponent,
        AdviceStepComponent,
        FifthMeetingComponent,
        SurveyFormComponent,
  ],
    providers: [
      MeetingFormService
    ],
    exports: [
    ]
  })
  export class MeetingFormModule { }