<div class="u-section-2 u-clearfix u-palette-1-base card-border">    
  
    <div class="u-layout sopcard"> 
      <div class="u-layout-col">        
        <div class="u-container-layout sopcard-title-section">            
          <h6 class="u-text sopcard-title">Project information</h6>
          <span (click)="accordionAction()" class="u-file-icon u-icon expand-icon">
            <img *ngIf="!opened" src="/assets/icons/expand.png" alt="">
            <img *ngIf="opened" src="/assets/icons/reduce.png" alt="">
          </span>          
        </div>
          <div *ngIf="opened" class="u-container-layout sopcard-section">
            <div class="row">
              <div class="col-md-6">
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Grants Agreement Number</span>
                  <br>{{project.grant_agreement}}<br>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Full project name</span>
                  <br>{{project.full_project_name}}
                </p>
                <p>

                </p>
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Call Topic ID</span>
                  <br>{{project.project_call_topic_id}}<br>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Project officer name</span>
                  <br>{{project.project_officer_name}}
                </p>
              </div>          
              <div class="col-md-6">
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Project acronym</span>
                  <br>{{project.project_acronym}}<br>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Funding programme</span>
                  <br>{{project.funding_programme}}
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Other Funding programme</span>
                  <br>{{project.other_funding_programme}}
                </p>
                <p>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Funding programme detail</span>
                  <br>{{project.funding_programme_detail}}
                </p>
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Project Website</span>
                  <br><a style="color: #01bef0;" href="{{project.project_website}}" target="_blank">{{project.project_website}}</a><br>
                </p>          
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Project Submission (UTC)</span>
                  <br>{{ timestampToDate(project.created_at) }}<br>
                </p>          
              </div>
            </div>
            <div style="margin-left: 0;" class="row">
              <h6 class="u-text sopcard-section-header">Topics</h6>         
            </div>            
          </div>       
          <div *ngIf="opened" class="u-container-layout sopcard-section">
            <ul class="topic-ul-1" *ngFor="let topic of getTopicTags()">            
              <li class="topic-li-1">                            
                <p class="u-small-text u-text sopcard-deep-details">                              
                  <span style="font-size: 1rem; font-weight: 700;" class="u-text-palette-2-light-1">{{topic}}</span>                            
                </p>                              
                <ul *ngFor="let tag of getTopicTags(topic)">                              
                  <li class="topic-li-2">                                  
                    <span style="font-size: 0.925rem; font-weight: 700;" class="u-text-palette-2-light-1">{{tag}}</span>                                  
                    <ul *ngFor="let t of getTopicTags(topic, tag)">              
                      <li class="topic-li-3">              
                        <p class="u-small-text u-text sopcard-deep-details">{{t}}</p>              
                      </li>              
                    </ul>              
                  </li>              
                </ul>            
              </li>            
            </ul>                    
            <h6 class="u-text sopcard-section-header">The support the project requires</h6>                  
          </div>
          <div *ngIf="opened" class="u-container-layout sopcard-section">
            <div class="row">
              <div class="col-md-12">
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">The type of information or activity that the project would like to attain from the booster services</span>
                  <span *ngFor="let information_to_attain of project.information_to_attain">
                      <br> - {{information_to_attain || 'N/A'}}<br>
                  </span>
                </p>
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">A short description of the project and its objectives</span>
                </p>
                <div [innerHTML]="project.project_short_description || 'N/A'"></div>
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">The objectives related to standardisation in the project</span>
                </p>
                <div [innerHTML]="project.objectives_standardisation || 'N/A'"></div>
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Why the project is applying for a Standardisation Booster service</span>
                </p>
                <div [innerHTML]="project.why_the_project_applying || 'N/A'"></div>
                <span *ngIf="project.version == 'v1'">
                  <p class="u-small-text u-text">
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Which Technical Committee(s), Sub-committee(s) or Working Group(s) the project would like to contribute</span>
                  </p>
                  <div [innerHTML]="project.which_group_contribute_to || 'N/A'"></div>
                </span>
              </div>          
            </div>
            <div *ngIf="project.version == 'v2'" style="margin-left: 0;" class="row">
              <h6 class="u-text sopcard-section-header">Which standards the project need to targeting</h6>         
            </div>            
          </div>
          <div *ngIf="opened" class="u-container-layout sopcard-section">
            <div *ngFor="let standard_reference of project.standard_reference" class="u-small-text u-text">        
              <div class="row">
                <div class="col-md-6">
                  <p class="u-small-text u-text">
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">SDO/NSB/NC</span>
                    <br>{{standard_reference.represent}}<br>
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Number of TC/SC/WG</span>
                    <br>{{standard_reference.number_sdo}}<br>
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Wants to CONTRIBUTE to the work on this standard</span>
                    <br>
                    <i *ngIf="standard_reference.is_contribute == '1'" class="fa fa-check"></i>
                    <i *ngIf="standard_reference.is_contribute == '0'" class="fa fa-times-circle"></i>
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Other options</span>
                    <br>{{standard_reference.other_options}}<br>
                  </p>
                </div>          
                <div class="col-md-6">
                  <p class="u-small-text u-text">
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1"></span>
                    <br><br>
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Title of TC/SC/WG</span>
                    <br>{{standard_reference.title_sdo}}<br>
                    <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Wants to USE this standard in the project's work</span>
                    <br>
                    <i *ngIf="standard_reference.is_use == '1'" class="fa fa-check"></i>
                    <i *ngIf="standard_reference.is_use == '0'" class="fa fa-times-circle"></i>
                  </p>        
                </div>
              </div>
            </div>
            <div style="margin-left: 0;" class="row">
              <h6 class="u-text sopcard-section-header">Other related attachments</h6>         
            </div>            
          </div>
          <div *ngFor="let document of project.related_doc_application">      
            <div *ngIf="opened" style="border:none; text-align:right" class="u-container-layout">      
                <h6 style="margin:25px 0 25px 25px" class="u-text sopcard-section-header">      
                  <span style="cursor:pointer" class="u-icon"><img src="/assets/icons/download.png" alt=""></span>      
                  <a href="{{document!.url}}" target="_blank" style="cursor:pointer">Download document: {{document!.description}}</a>
                </h6>      
            </div>      
          </div>
          <div *ngIf="project.related_doc_application?.length ==0 && opened">
            <h6 style="margin:25px 0 25px 25px" class="u-text sopcard-section-header">N/A</h6>
          </div>
          <div *ngIf="opened" style="margin-left: 0;" class="row">
            <h6 class="u-text sopcard-section-header">Project representative information</h6>         
          </div>   
          <div *ngIf="opened" class="u-container-layout sopcard-section"> 
            <div class="row">
              <div class="col-md-6">
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Name</span>
                  <br><span *ngIf="project.version == 'v2'">{{project.title}} </span>{{project.name}}<br>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Organisation</span>
                  <br>{{project.organisation}}
                </p>
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Organisation type</span>
                  <br>{{project.organisation_type}}<br>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Phone number</span>
                  <br>{{project.phone_number || 'N/A'}}
                </p>
              </div>          
              <div class="col-md-6">
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Surname</span>
                  <br>{{project.surname}}<br>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Email address</span>
                  <br>{{project.email}}
                </p>
                <p class="u-small-text u-text">
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Country</span>
                  <br>{{project.country}}<br>
                  <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Gender</span>
                  <br>{{project.gender}}
                </p>          
              </div>
            </div>         
          </div>
      </div> 
    </div>  
  </div>