import { Injectable } from "@angular/core";
import { Form, FormArray, FormControl, FormGroup } from "@angular/forms";

@Injectable({
    providedIn:'root'
})
export class MeetingFormService {

    reportForm ?: FormGroup
    surveyEpeForm ?: FormGroup
    surveyProjectForm ?: FormGroup
    surveyProjectAdviceForm ?: FormGroup
    surveyProjectMeetingForm ?: FormGroup
    form ?: FormGroup
    step : number = 0
    surveyProjectStep: number = 0
  
  constructor() {
    
  }

  clear(): void {
    this.form = undefined
    this.step = 0
  }

  clearProjectMeeting(): void {
    this.surveyProjectMeetingForm = undefined
    this.surveyProjectStep = 0
  }


  getSubmitMeetingValue(meeting: number): number{
    switch(meeting){
      case 0:
        return this.getStep() === 'third' ? meeting + 1 : meeting
      default: return meeting
    }
  }

  updateForm(form: { [key: string]: any; }): void{
    if(!this.form)
      return
    Object.keys(form).forEach(
      key => {
        if(typeof form[key] != 'object')
          this.form!.patchValue({
            [key]: form[key]
          })
        else{
          const selectedAnswers = (this.form!.controls[key] as FormArray)
          form[key].forEach(
            (x: string) => selectedAnswers.push(new FormControl(x))
          )
        }
      }
    )


    this.setForm(this.form)
  }

  updateProjectMeetingForm(form: { [key: string]: any; }): void{
    if(!this.surveyProjectMeetingForm)
      return
    Object.keys(form).forEach(
      key => {
        if(typeof form[key] != 'object')
          this.surveyProjectMeetingForm!.patchValue({
            [key]: form[key]
          })
        else{
          const selectedAnswers = (this.surveyProjectMeetingForm!.controls[key] as FormArray)
          form[key].forEach(
            (x: string) => selectedAnswers.push(new FormControl(x))
          )
        }
      }
    )
    this.setProjectMeetingForm(this.surveyProjectMeetingForm)
  }

  updateProjectAdviceForm(form: { [key: string]: any; }): void{
    if(!this.surveyProjectAdviceForm)
      return
    Object.keys(form).forEach(
      key => {
        if(typeof form[key] != 'object')
          this.surveyProjectAdviceForm!.patchValue({
            [key]: form[key]
          })
        else{
          const selectedAnswers = (this.surveyProjectAdviceForm!.controls[key] as FormArray)
          form[key].forEach(
            (x: string) => selectedAnswers.push(new FormControl(x))
          )
        }
      }
    )
    this.setProjectAdviceForm(this.surveyProjectAdviceForm)
  }

  updateEpeSurveyForm(form: { [key: string]: any; }): void{
    if(!this.surveyEpeForm)
      return
    Object.keys(form).forEach(
      key => {
        if(typeof form[key] != 'object')
          this.surveyEpeForm!.patchValue({
            [key]: form[key]
          })
        else{
          const selectedAnswers = (this.surveyEpeForm!.controls[key] as FormArray)
          form[key].forEach(
            (x: string) => selectedAnswers.push(new FormControl(x))
          )
        }
      }
    )
    this.setEpeSurveyForm(this.surveyEpeForm)
  }

  updateProjectSurveyForm(form: { [key: string]: any; }): void{
    if(!this.surveyProjectForm)
      return
    Object.keys(form).forEach(
      key => {
        if(typeof form[key] != 'object')
          this.surveyProjectForm!.patchValue({
            [key]: form[key]
          })
        else{
          const selectedAnswers = (this.surveyProjectForm!.controls[key] as FormArray)
          form[key].forEach(
            (x: string) => selectedAnswers.push(new FormControl(x))
          )
        }
      }
    )
    this.setProjectSurveyForm(this.surveyProjectForm)
  }

  updateReportForm(form: { [key: string]: any; }): void{
    if(!this.reportForm)
      return
    Object.keys(form).forEach(
      key => {
        if(typeof form[key] != 'object')
          this.reportForm!.patchValue({
            [key]: form[key]
          })
        else{
          const selectedAnswers = (this.reportForm!.controls[key] as FormArray)
          form[key].forEach(
            (x: string) => selectedAnswers.push(new FormControl(x))
          )
        }
      }
    )


    this.setReportForm(this.reportForm)
  }

  setStep(step: number): void {
    this.step = step
  }

  setProjectSurveyStep(step: number): void {
    this.surveyProjectStep = step
  }

  getProjectSurveyStep(meeting: number = 1): string{
    switch(this.step){
      case 0:
        return 'edit'
      case 1:
        return 'summary'
      default:
        return 'edit'
    }
  }

  getStep(meeting: number = 1): string{
    switch(this.step){
      case 0:
        return 'first'
      case 1:
        return meeting == 4 ? 'first' : 'second'
      case 2:
        return meeting == 3 ? 'first' : meeting < 6 ? 'third' : 'summary'
      case 3:
        return 'first'
      default:
        return 'first'
    }
  }

  getNextStep(meeting: number = 1): string{
    this.step++
    return this.getStep(meeting)
  }

  getProjectSurveyNextStep(meeting: number = 1): string{
    this.surveyProjectStep++
    return this.getProjectSurveyStep(meeting)
  }

  setEpeSurveyForm(f: FormGroup){
    this.surveyEpeForm = f
  }

  setProjectSurveyForm(f: FormGroup){
    this.surveyProjectForm = f
  }

  setProjectAdviceForm(f: FormGroup){
    this.surveyProjectAdviceForm = f
  }

  getEpeSurveyForm() : FormGroup{
    if(this.surveyEpeForm)
        return this.surveyEpeForm
        throw new Error('Form not found!!');
  }

  getProjectSurveyForm() : FormGroup{
    if(this.surveyProjectForm)
        return this.surveyProjectForm
        throw new Error('Form not found!!');
  }

  getProjectSurveyMeetingForm() : FormGroup{
    if(this.surveyProjectMeetingForm)
        return this.surveyProjectMeetingForm
        throw new Error('Form not found!!');
  }

  getProjectAdviceForm() : FormGroup{
    if(this.surveyProjectAdviceForm)
        return this.surveyProjectAdviceForm
        throw new Error('Form not found!!');
  }

  setReportForm(f: FormGroup){
    this.reportForm = f
  }

  getReportForm() : FormGroup{
    if(this.reportForm)
        return this.reportForm
        throw new Error('Form not found!!');
  }

  getReportSubmit() : {[index:string]:any} {
    if(!this.reportForm)
      return {}
    var toInject: {[index:string]:any} = {}
    Object.keys(this.reportForm.controls).forEach(
        key => {
            
            let value = this.reportForm!.value[key]
            if(this.reportForm!.value[key])
                toInject[key] = value
        
        }
    )
    return toInject
  }

  getEpeSurveySubmit() : {[index:string]:any} {
    if(!this.surveyEpeForm)
      return {}
    var toInject: {[index:string]:any} = {}

    Object.keys(this.surveyEpeForm.controls).forEach(
        key => {
            let value = this.surveyEpeForm!.value[key]
            if(this.surveyEpeForm!.value[key])
                toInject[key] = value
        
        }
    )
    return toInject
  }

  getProjectSurveySubmit() : {[index:string]:any} {
    if(!this.surveyProjectForm)
      return {}
    var toInject: {[index:string]:any} = {}

    Object.keys(this.surveyProjectForm.controls).forEach(
        key => {
            let value = this.surveyProjectForm!.value[key]
            if(this.surveyProjectForm!.value[key])
                toInject[key] = value
        
        }
    )
    return toInject
  }

  setForm(f: FormGroup){
    this.form = f
  }

  setProjectMeetingForm(f: FormGroup){
    this.surveyProjectMeetingForm = f
  }

  getForm() : FormGroup{
    if(this.form)
        return this.form
        throw new Error('Form not found!!');
  }


  getProjectMeetingFormSubmit() : {[index:string]:any} {
    if(!this.surveyProjectMeetingForm)
      return {}
    var toInject: {[index:string]:any} = {}
    Object.keys(this.surveyProjectMeetingForm.controls).forEach(
        key => {
            
            let value = this.surveyProjectMeetingForm!.value[key]
            if(this.surveyProjectMeetingForm!.value[key])
                toInject[key] = value
        
        }
    )
    return toInject
  }

  getProjectAdviceFormSubmit() : {[index:string]:any} {
    if(!this.surveyProjectAdviceForm)
      return {}
    var toInject: {[index:string]:any} = {}
    Object.keys(this.surveyProjectAdviceForm.controls).forEach(
        key => {
            
            let value = this.surveyProjectAdviceForm!.value[key]
            if(this.surveyProjectAdviceForm!.value[key])
                toInject[key] = value
        
        }
    )
    return toInject
  }

  getSubmit() : {[index:string]:any} {
    if(!this.form)
      return {}
    var toInject: {[index:string]:any} = {}
    Object.keys(this.form.controls).forEach(
        key => {
            
            let value = this.form!.value[key]
            if(this.form!.value[key])
                toInject[key] = value
        
        }
    )
    return toInject
  }
}