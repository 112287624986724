import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexChart, ApexNonAxisChartSeries, ChartComponent, ApexTitleSubtitle } from 'ng-apexcharts';
import * as Highcharts from 'highcharts/highmaps';
import worldMap from "@highcharts/map-collection/custom/world-eckert3-highres.geo.json";
import { Project } from 'app/models/project';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';
import { SopService } from 'app/services/sop.service';
import { countries } from 'app/shared/store/country-data-store';
import { forEach } from 'lodash';
import { Epe } from 'app/models/epe';
import { AssociationMongo } from 'app/models/association';
import { EMPTY, catchError, forkJoin, of } from 'rxjs';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationsService } from 'app/services/associations.service';

@Component({
  selector: 'statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss', '../sop.component.scss']
})
export class StatisticsComponent implements OnInit {

  loading: boolean = true;
  epes: Epe[] | null = []
  associations: AssociationMongo[] | null = []
  associationsArray: any[] = []
  projects:Project[] | null = []
  projectsCompleted:Project[] | null = []
  applications : number = 0;
  fundingProgrammes : string[] = ['Horizon Europe', 'Digital Europe<br/> Programme', 'H2020'];
  fundingProgrammesCount : number[] = [0, 0, 0];
  callTopics : string[] = ['Health', 'Resilience', 'Sustainable digitalisation', 'Green transition<br/> in Europe', 'Smart Cities<br/> and Circular Economy<br/> in Buildings'];
  callTopicsCount : number[] = [0, 0, 0, 0, 0];
  serviceDeliveryStatus : string[] = ['Approved', 'Completed', 'In assigment', 'Closed'];
  serviceDeliveryStatusCount : number[] = [0, 0, 0, 0];

  updateFlag = false;

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  public countries:any = countries;

  fundingProgrammeChart = {
    series: <ApexNonAxisChartSeries> [0 , 0, 0],
    chart: <ApexChart> {
      type: 'donut',
      toolbar: {
        show: true
      }
    },
    labels: this.fundingProgrammes,
    chartTitle: <ApexTitleSubtitle> {
      text: 'Fundings Programme',
      align: 'left',
    }
  };

  callTopicsChart = {
    series: <ApexNonAxisChartSeries> [0 , 0, 0, 0, 0],
    chart: <ApexChart> {
      type: 'pie',
      toolbar: {
        show: true
      }
    },
    labels: this.callTopics,
    chartTitle: <ApexTitleSubtitle> {
      text: 'Call Topics',
      align: 'left',
    }
  };

  serviceDeliveryStatusChart = {
    series: <ApexNonAxisChartSeries> this.serviceDeliveryStatusCount,
    chart: <ApexChart> {
      type: 'donut',
      toolbar: {
        show: true
      }
    },
    labels: this.serviceDeliveryStatus,
    chartTitle: <ApexTitleSubtitle> {
      text: 'Service Delivery Status',
      align: 'left',
    }
  };

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartData = [{ code3: "ABW", z: 105 }, { code3: "AFG", z: 35530 }];

  chartOptions: Highcharts.Options = {
    chart: {
      map: worldMap
    },
    title: {
      text: "<b>Geographical</b> category of projects"
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox"
      }
    },
    legend: {
      enabled: true
    },
    colorAxis: {
      min: 0
    },
    series: [
      {
        type: "map",
        name: "Random data",
        states: {
          hover: {
            color: "#BADA55"
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        },
        allAreas: false,
        data: [
        ]
      }
    ]
  };

  p: number = 1;
  itemsPerPage: number = 10;

  

  constructor(
    private cache: SopStorage,
    private logger: Logger,
    public sopService: SopService,
    private associationService: AssociationsService,
  ) { }

  ngOnInit(): void {

    this.projects = this.cache.getProjects()
    this.epes = this.cache.getEpes()

    forkJoin({
      projects: !this.projects ? this.sopService.getAll('project') : of(null),
      epes: !this.epes ? this.sopService.getAll('epe') : of(null),
      associations: this.associationService.getAllServices()
    })
    .pipe(
      catchError(
        err => {
          this.addAlert("Association retriving error", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
        //console.log(then)
        this.epes = then.epes ? then.epes : this.epes
        this.projects = then.projects ? then.projects : this.projects
        // assoiciation meeting 7 is the last one or association status = completed ???
        this.associationsArray = then.associations?.filter(association => association.meeting === 7)?.map(
          association => (
            {
              ...association,
              'Project': this.projects?.find(project => project.id == association.project_id)?.project_acronym,
              'ActualDuration': this.getActualDuration(association.meeting_form_1.date, association.meeting_form_5.date)
            }
          )
        )
        this.associations = then.associations;
        this.cache.setEpes(this.epes!)
        this.cache.setProjects(this.projects!)
        this.loadCharts()
        //this.associations?.sort(this.statusSort(this.sortDirection, this.sortSelected))
        this.loading = false
      })
    

  }

  getActualDuration(meeting_form_1: string, meeting_form_5: string){
    const date1 = new Date(meeting_form_5);
    const date2 = new Date(meeting_form_1);
    const timeDifference = date1.getTime() - date2.getTime();
    const daysDifference = Math.abs(Math.floor(timeDifference / (24 * 60 * 60 * 1000)));
    return daysDifference
  }
    

  loadCharts(){
    this.applications = this.projects?.length || 0;
    this.countFundingProgrammes()
    this.fundingProgrammeChart.series = this.fundingProgrammesCount;
    this.countCallTopics()
    this.callTopicsChart.series = this.callTopicsCount;
    this.loadCountries()
    this.countServiceDeliveryStatus()
    this.serviceDeliveryStatusChart.series = this.serviceDeliveryStatusCount;
    
  }
    

  countFundingProgrammes(){
    this.projects?.forEach(project => {
      if(project.funding_programme == 'HorizonEurope'){
        this.fundingProgrammesCount[0] += 1
      } else if(project.funding_programme == 'DigitalEuropeProgramme'){
        this.fundingProgrammesCount[1] += 1
      } else if(project.funding_programme == 'H2020'){
        this.fundingProgrammesCount[2] += 1
      } else if(project.funding_programme == 'Other'){
        this.fundingProgrammesCount[3] += 1
      }
    });
  }

  countCallTopics(){
    this.projects?.forEach(project => {
      for (let key in project.topics){
        if(key == 'Health'){
          this.callTopicsCount[0] += 1
        } else if(key == 'Resilience'){
          this.callTopicsCount[1] += 1
        } else if(key == 'Sustainable digitalisation'){
          this.callTopicsCount[2] += 1
        } else if(key == 'Green transition in Europe'){
          this.callTopicsCount[3] += 1
        } else if(key == 'Smart Cities and Circular Economy in Buildings'){
          this.callTopicsCount[4] += 1
        }
      }
    });
  }

  countServiceDeliveryStatus(){
    // this.serviceDeliveryStatusCount[1] = this.associations?.filter(association => association.meeting === 7).length || 0;
    this.serviceDeliveryStatusCount[0] = this.associations?.filter(association => association.association_status === 'approved').length || 0;
    this.serviceDeliveryStatusCount[1] = this.associations?.filter(association => association.association_status === 'completed').length || 0;
    this.serviceDeliveryStatusCount[2] = this.projects!.length - this.associations!.length || 0;
    this.serviceDeliveryStatusCount[3] = this.associations?.filter(association => association.association_status === 'closed').length || 0;
  }

  loadCountries(){
    forEach(this.projects, (project) => {
      if(project.country){
        let country
        if (country = this.countries.filter((country:any) => country.name == project.country)){
          this.updateStateValue(country[0].code, country[0].value + 1)
        }
      }
    })
    let arrayData: any[] = [];
    forEach(this.countries, (country:any) => {
      arrayData.push([country.code.toLowerCase(), country.value])
    })
    this.chartOptions.series = [{
      type: "map",
      name: "Projects",
      states: {
        hover: {
          color: "#BADA55"
        }
      },
      dataLabels: {
        enabled: true,
        format: "{point.name}"
      },
      allAreas: false,
      data: arrayData
    }]

    this.updateFlag = true;

  }


  updateStateValue(countryCode: string, newValue: number): void {
    let countryIndex = countries.findIndex((country) => country.code === countryCode);

    if (countryIndex !== -1) {
        countries[countryIndex].value = newValue;
        //console.log(`Updated value of ${countries[countryIndex].name} to ${newValue}`);
    } else {
        //console.log(`Country with code ${countryCode} not found.`);
    }
  }

  openNewTab(url: any){
    window.open(url)
  }

  calculateRowIndex(indexOnPage: number): number {
    return (this.p - 1) * this.itemsPerPage + indexOnPage + 1;
  }
  

  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

}
