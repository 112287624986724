<div class="u-section-2 u-clearfix u-palette-1-base card-border">    
  
  <div class="u-layout sopcard"> 
    <div class="u-layout-col">        
      <div class="u-container-layout sopcard-title-section">            
        <h6 class="u-text sopcard-title">Personal information</h6>
        <span (click)="accordionAction()" class="u-file-icon u-icon expand-icon">
          <img *ngIf="!opened" src="/assets/icons/expand.png" alt="">
          <img *ngIf="opened" src="/assets/icons/reduce.png" alt="">
        </span>          
      </div>        
      <div *ngIf="opened" class="u-container-layout sopcard-section">
        <div class="row">
          <div class="col-md-6">
            <p class="u-small-text u-text">
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Name</span>
              <br><span *ngIf="epe.version == 'v2'">{{epe.title}} </span>{{epe.name}}<br>
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Organisation</span>
              <br>{{epe.organisation}}
            </p>
            <p class="u-small-text u-text">
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Linkedin</span>
              <br><a style="color: #01bef0;" href="{{epe.linkedin}}" target="_blank">Click here to open Linkedin profile</a><br>
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Phone number</span>
              <br>{{epe.phone_number || 'N/A'}}
            </p>
          </div>          
          <div class="col-md-6">
            <p class="u-small-text u-text">
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Surname</span>
              <br>{{epe.surname}}<br>
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Email address</span>
              <br>{{epe.email}}
            </p>
            <p class="u-small-text u-text">
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Country</span>
              <br>{{epe.country}}<br>
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Gender</span>
              <br>{{epe.gender}}
            </p>          
          </div>
        </div>
        <div style="margin-left: 0;" class="row">
          <h6 class="u-text sopcard-section-header">Topics</h6>         
        </div>            
      </div>        
      <div *ngIf="opened" class="u-container-layout sopcard-section">
        <ul class="topic-ul-1" *ngFor="let topic of getTopicTags()">            
          <li class="topic-li-1">                            
            <p class="u-small-text u-text sopcard-deep-details">                              
              <span style="font-size: 1rem; font-weight: 700;" class="u-text-palette-2-light-1">{{topic}}</span>                            
            </p>                              
            <ul *ngFor="let tag of getTopicTags(topic)">                              
              <li class="topic-li-2">                                  
                <span style="font-size: 0.925rem; font-weight: 700;" class="u-text-palette-2-light-1">{{tag}}</span>                                  
                <ul *ngFor="let t of getTopicTags(topic, tag)">              
                  <li class="topic-li-3">              
                    <p class="u-small-text u-text sopcard-deep-details">{{t}}</p>              
                  </li>              
                </ul>              
              </li>              
            </ul>            
          </li>            
        </ul>                    
        <h6 *ngIf="epe.version == 'v2'" class="u-text sopcard-section-header">Employment</h6>                  
      </div>
      <div *ngIf="opened" class="u-container-layout sopcard-section">
        <div *ngIf="epe.version == 'v2'" class="u-small-text u-text sopcard-deep-details">
          <h6 class="u-text sopcard-section-header">Current Position</h6>
          <div class="row">
            <div class="col-md-6">
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Organisation</span>      
              <span><br>{{epe.organisation}}<br></span> 
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Position</span>      
              <span><br>{{epe.position}}<br></span> 
            </div>
            <div class="col-md-6">
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Organisation type</span>      
              <span><br>{{epe.organisation_type}}<br></span> 
              <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Start date</span>      
              <span><br>{{epe.position_dates}}<br></span> 
            </div>
          </div>
        </div>
        
        <span *ngIf="epe.version == 'v2'">
          <h6 class="u-text sopcard-section-header">Previous Positions</h6>
          <div *ngFor="let previous_positions of epe.previous_positions" class="u-small-text u-text">      
            <div class="row">
              <div class="col-md-6">
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">From</span>      
                <span><br>{{previous_positions.from}}<br></span> 
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Employer</span>      
                <span><br>{{previous_positions.employer}}<br></span> 
              </div>
              <div class="col-md-6">  
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">To</span>      
                <span><br>{{previous_positions.to}}<br></span> 
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Position held</span>      
                <span><br>{{previous_positions.position_held}}<br></span> 
              </div>
            </div>
          </div>
        </span>
        <h6 *ngIf="epe.version == 'v2'" class="u-text sopcard-section-header">Academic Qualifications</h6>                  
      </div>  
      <div *ngIf="opened" class="u-container-layout sopcard-section">   
        <span *ngIf="epe.version == 'v2'">
          <div *ngFor="let role of epe.academic_qualifications" class="u-small-text u-text sopcard-deep-details">      
            <div class="row">
              <div class="col-md-6">
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">From</span>      
                <span><br>{{role.from}}<br></span> 
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Higher education institution</span>      
                <span><br>{{role.higher_education}}<br></span> 
              </div>
              <div class="col-md-6">
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">To</span>      
                <span><br>{{role.to}}<br></span> 
                <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Academic Qualification</span>      
                <span><br>{{role.academic_qualification}}<br></span> 
              </div>
            </div>
          </div>  
        </span>    
        <h6 class="u-text sopcard-section-header">Expertise</h6>      
      </div>    
      <div *ngIf="opened" class="u-container-layout sopcard-section">      
        <div *ngFor="let role of epe.standardisation_roles" class="u-small-text u-text sopcard-deep-details">      
          <span *ngIf="role.represent || (epe.version == 'v1' && role.other_represent)" style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Represent</span>      
          <span *ngIf="role.represent || (epe.version == 'v1' && role.other_represent)"><br>{{role.represent || (epe.version == 'v1' && role.other_represent)}}<br></span>
          <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Role</span>      
          <br>{{role.role}}<br>      
          <span style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Years of Experience</span>      
          <br>{{role.years}}<br>      
          <span *ngIf="epe.version == 'v1'" style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Technical Committee, Sub-committee or Working Group</span>      
          <span *ngIf="epe.version == 'v1'"><br><div class="role-details"  [innerHtml]="role.details"></div> </span>
          
          <span *ngIf="epe.version == 'v2'" style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Number of Technical Commitee/Sub-commitee/WG</span>      
          <span *ngIf="epe.version == 'v2'"><br>{{role.number_sdo}}<br></span> 
          <span *ngIf="epe.version == 'v2'" style="font-size: 0.625rem; font-weight: 700;" class="u-text-palette-2-light-1">Title of Technical Commitee/Sub-commitee/WG</span>      
          <span *ngIf="epe.version == 'v2'"><br>{{role.title_sdo}}<br></span> 
        </div>      
        <h6 *ngIf="epe.version == 'v2'" class="u-text sopcard-section-header">Tell us more about yourself and how you think you can help EC-funded projects improve their approach to standardisation as an expert in HSBooster.eu</h6>      
      </div>
      <div *ngIf="opened" class="u-container-layout sopcard-section">      
        <span *ngIf="epe.version == 'v2'"><br><div [innerHtml]="epe.tell_us_more"></div> </span>
        <h6 class="u-text sopcard-section-header">CV</h6>      
      </div>        
      <div *ngIf="opened" style="border:none; text-align:right" class="u-container-layout">      
        <h6 style="margin:25px 0 25px 25px" class="u-text sopcard-section-header">      
          <span *ngIf="epe.cv" style="cursor:pointer" class="u-icon"><img src="/assets/icons/download.png" alt=""></span>      
          <a *ngIf="epe.cv" href="https://hsbooster.eu/{{epe.cv}}" target="_blank" style="cursor:pointer">Download documents</a>
          <span *ngIf="!epe.cv">N/A</span>
        </h6>      
      </div>
      <div class="u-layout-col" *ngIf="auth.isCallManager() && epe.sop_status != 'pending' && epe.sop_status != 'not_eligible'">        
        <div class="u-container-layout sopcard-title-section">          
          <h6 class="u-text u-text-default sopcard-title">Evaluation Summary</h6>
          <span (click)="accordionAction(true)" class="u-file-icon u-icon expand-icon">
            <img *ngIf="!votesOpened" src="/assets/icons/expand.png" alt="">
            <img *ngIf="votesOpened" src="/assets/icons/reduce.png" alt="">
          </span>         
        </div>
        <div *ngIf="votesOpened">
          <table style="width:100%; border-collapse: separate; border-spacing: 0 0.275rem;">
            <thead class="u-table-header vote-table-head">
                <tr>
                    <th class="u-table-cell">Voted by</th>
                    <th class="u-table-cell">Roles and Years (30%)</th>
                    <th class="u-table-cell">TWG Participation (30%)</th>
                    <th class="u-table-cell">Other Expertise (30%)</th>
                    <th class="u-table-cell">Gender (10%)</th>
                </tr>
            </thead>
            <br/>
            <tbody class="u-table-body vote-table-body">
                <tr *ngFor="let row of getVoteIterable()">
                    <td>{{row.voter}}</td>
                    <td>{{vote!.automatic}}<strong> /10 </strong></td>
                    <td>{{row.engagment}}<strong> /10 </strong></td>
                    <td>{{row.standardisation}}<strong> /10 </strong></td>
                    <td>{{vote!.gender}}<strong> /10 </strong></td>
                </tr>
                <tr>
                  <td>AVERAGE</td>
                  <td>{{!vote? 0 : vote!.automatic}}<strong> /10 </strong></td>
                  <td>{{getVoteAvg(false)}}<strong> /10 </strong></td>
                  <td>{{getVoteAvg(true)}}<strong> /10 </strong></td>
                  <td>{{!vote? 0 : vote!.gender}}<strong> /10 </strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>       
    </div> 
  </div>  
</div>