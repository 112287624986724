import { SessionStorage } from 'app/storage/session.storage';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'survey-admin',
  templateUrl: './survey-admin.component.html',
  styles: [
  ]
})
export class SurveyAdminComponent implements OnInit {

  dataEpe = {form: 'survey', user: 'epe'}
  dataProject = {form: 'survey', user: 'project'}

  constructor(
    private router: Router,
    private auth: SessionStorage
  ) { }

  ngOnInit(): void {
  }

}
