import { Association, AssociationMongo } from 'app/models/association';
import { Project, ProjectMongo } from 'app/models/project';
import { Injectable } from '@angular/core';
import { Epe, EpeMongo, Vote } from 'app/models/epe';
import { lastValueFrom } from 'rxjs';
import { SopService } from 'app/services/sop.service';


// This class is a Signleton, use it to cache SOP local variables  

@Injectable({
  providedIn: 'root'
})
export class SopStorage {
  
    epes : Epe[] | null = null
    epes_mongo : EpeMongo [] = []
    projects : Project[] | null = null
    projects_mongo : ProjectMongo [] = []
    associations : Association[] | null = null
    associations_mongo : AssociationMongo [] | null = null
    constructor(
        private sopService: SopService
    ) { }

    getAssociations() : Association[] | null{
        return this.associations
    }

    getAssociation(association_id:number) : Association | undefined{
        return this.associations?.find(x => x['id'] == association_id )
    }

    setAssociations(associations: Association[]) : void{
        this.associations = associations
    }

    setAssociation(association: Association) : void{
        if(this.associations)
            this.associations = this.associations.map(
                x => x.id == association.id ? association : x
            )
    }

    getProjects() : Project[] | null{
        return this.projects
    }

    getProject(project_id:number) : Project | undefined{
        return this.projects?.find(x => x['id'] == project_id )
    }

    setProjects(projects: Project[]) : void{
        this.projects = projects
    }

    setProject(project: Project) : void{
        if(this.projects)
            this.projects = this.projects.map(
                x => x.id == project.id ? project : x
            )
    }

    getEpes(): Epe[] | null{
        
        
        return this.epes
    }

    getEpe(epe_id:number) : Epe | undefined{
        return this.epes?.find(x => x['id'] == epe_id )
    }

    setEpes(epes: Epe[]) : void{
        this.epes = epes
    }

    setEpe(epe: Epe|undefined) : void{
        if(this.epes && epe)
            this.epes = this.epes.map(
                x => x.id == epe.id ? epe : x
            )
    }

    setSopStatus(id: number, sop_status: string) : void{
        if(this.epes)
            this.epes = this.epes.map(
                x => {x.sop_status = x.id == id ? sop_status : x.sop_status; return x}
            )
    }

    getAssociationsMongo() : AssociationMongo[] | null{
        return this.associations_mongo
    }

    getAssociationMongo(association_id:number) : AssociationMongo | undefined{
        return this.associations_mongo?.find(x => x['association_id'] == association_id)
    }

    setAssociationsMongo(associations: AssociationMongo[]) : void{
        this.associations_mongo = associations
    }

    setAssociationMongo(association: AssociationMongo) : void{
        if(!this.associations_mongo)
            return
        if(this.associations_mongo.indexOf(association) >= 0)
            this.associations_mongo = this.associations_mongo.map(
                x => x.id == association.id ? association : x
            )
        else
            this.associations_mongo.push(association)
    }

    getProjectsMongo() : ProjectMongo[]{
        return this.projects_mongo
    }

    getProjectMongo(project_id:number) : ProjectMongo | undefined{
        return this.projects_mongo.find(x => x['project_id'] == project_id)
    }

    setProjectsMongo(projects: ProjectMongo[]) : void{
        this.projects_mongo = projects
    }

    setProjectMongo(project: ProjectMongo) : void{
        if(this.projects_mongo.indexOf(project) >= 0)
            this.projects_mongo = this.projects_mongo.map(
                x => x.id == project.id ? project : x
            )
        else
            this.projects_mongo.push(project)
    }

    getEpesMongo() : EpeMongo[]{
        return this.epes_mongo
    }

    getEpeMongo(epe_id:number) : EpeMongo | undefined{
        return this.epes_mongo.find(x => x['epe_id'] == epe_id)
    }

    setEpesMongo(epes: EpeMongo[]) : void{
        this.epes_mongo = epes
    }

    setEpeMongo(epe: EpeMongo) : void{
        if(this.epes_mongo.indexOf(epe) >= 0)
            this.epes_mongo = this.epes_mongo.map(
                x => x.id == epe.id ? epe : x
            )
        else
            this.epes_mongo.push(epe)
    }

    setNote(epe_id: number, note: string): void{
        let epe = this.epes_mongo.find(x => x['epe_id'] == epe_id)
        if(epe)
            epe.note = note

    }
    
    setVote(epe_id: number, vote: Vote): void{
        let epe = this.epes_mongo.find(x => x['epe_id'] == epe_id)
        if(epe)
            epe.vote = vote

    }

    updateVote(epe_id:number, standardisation: number, engagment: number): void{

        let epe = this.epes_mongo.find(x => x['epe_id'] == epe_id)
        if(epe && typeof epe.vote.engagment !== 'number' && typeof epe.vote.standardisation !== 'number' ){
            epe.vote.engagment.push(engagment)
            epe.vote.standardisation.push(standardisation)
        }

    }

    clear() : void{
        this.epes = null
        this.projects = null
    }

    clearAll() : void{
        this.epes = null
        this.epes_mongo = []
        this.projects = null
        this.projects_mongo = []
    }
     

}