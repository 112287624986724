<div class="form-container">
    <div class="form-box">
        <h3>{{title}}</h3>
        <div *ngFor="let question of questions">
            <open-question *ngIf="isOpenQuestion(question)" [type]="'composer'" [question]="getQuestionKey(question)" [editor]="getQuestion(question)"></open-question>
            <tick-box *ngIf="!isOpenQuestion(question)" [fields]="question" [type]="'composer'"></tick-box>
        </div>
        <button (click)="confirm()" class="u-btn sop-btn">Save</button>
    </div>
</div>

<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>