import { SurveyAdminComponent } from './../survey-admin/survey-admin.component';
import { FormSummary } from './../form-steps/form-summary/form-summary';
import { SurveyFormComponent } from './../survey-form/survey-form.component';
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

const routes = [
    {
      path: '',
      redirectTo: 'survey-form',
      pathMatch: 'full'
    },
    {
        path: 'survey-form',
        component: SurveyFormComponent,
    },
    {
        path: 'summary',
        component: FormSummary,
        data: {form: 'survey'}
    },
    {
        path: 'admin',
        component: SurveyAdminComponent
    }

  ]
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes),
    ],
    exports: [
      RouterModule
    ]
  })
  export class SurveyRoutingModule {
  
  }