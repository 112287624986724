import { Question } from './../form-steps/form-composer/form-composer.component';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { lastValueFrom } from 'rxjs';
import { MeetingFormService } from '../meeting-form.service';
import { SessionStorage } from 'app/storage/session.storage';

@Component({
  selector: 'fourth-meeting',
  templateUrl: './fourth-meeting.component.html',
  styleUrls: ['./fourth-meeting.component.scss']
})
export class FourthMeetingComponent implements OnInit {

  question = "Advice"
  form: FormGroup
  loading: boolean = true

  constructor(
    private fb: FormBuilder,
    private formService: MeetingFormService,
    private service: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    public auth: SessionStorage,
  ) { 
    this.formService.clear() 
    this.form = this.fb.group({
      "Advice": ['', Validators.required]
    })
    this.formService.setForm(this.form)
  }

  async ngOnInit() {
    if(this.helper.getSelectedProject() == -1 || this.helper.getSelectedEpe() == -1){
      this.router.navigateByUrl(`assigned-services`)
    }
    
    let response = await lastValueFrom(this.service.getForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe(), 4), {defaultValue: null})
    console.log(response)
    if(!response){
      this.loading = false
      return
    }
    let form: { [key: string]: any; } = response['meeting_form_4']
    let step = this.retrieveStep(form)
    
    this.formService.updateForm(form)
    this.formService.setStep(step)

    this.form.valueChanges.subscribe(data => console.log('Form changes', data));


    this.loading = false

    if(this.helper.getMeetingForm() > 4)
      this.router.navigateByUrl(`form/(meeting-form:meeting_4/(meeting_4:summary))`)
    else if (step > 0)
      this.router.navigateByUrl(`form/(meeting-form:meeting_4/(meeting_4:${this.formService.getStep(4)}))`)
  }

  private retrieveStep(form: { [key: string]: any; }): number{
    let keys = Object.keys(form)
    if(keys.indexOf(this.question) >= 0 && form[this.question].length > 0)
      return 1
    return 0
  }

}
