import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { MeetingFormService } from '../../meeting-form.service';

@Component({
  selector: 'meeting-completed',
  templateUrl: './meeting-completed.component.html',
  styleUrls: ['../../meeting-form.component.scss']
})
export class MeetingCompletedComponent implements OnInit {

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  meetingsOrder = [
    'first', 'second', 'third', 'fourth', "final"
  ]

  MEETING_FORM = 0

  form : FormGroup



  constructor(
    private service: MeetingFormService,
    private helper: AssociationHelper,
    private mongoService: AssociationsService,
    private router: Router
  ) {
    this.form = service.getForm()
    this.MEETING_FORM = this.helper.getMeetingForm() - 1
    if (this.MEETING_FORM < 0)
      this.MEETING_FORM = 0
   }

  ngOnInit(): void {
    
  }

  accept(accept: boolean): void{
    this.form.patchValue({
        meetingCompleted: accept
    })
    let meeting = this.helper.getMeetingForm()
    if(meeting <= 0){
      meeting = 1
    }

    if(accept){
      let nextStep = this.service.getNextStep(meeting)
      let complete = false
      console.log(nextStep)
      if(nextStep == 'first' || nextStep == 'summary'){
        complete = true
      }

      this.mongoService.submitForm(
        this.helper.getSelectedProject(), 
        this.helper.getSelectedEpe(), 
        this.service.getSubmitMeetingValue(meeting), 
        this.service.getSubmit(),
        complete,
        false
        ).subscribe(
          then => {
            if(complete){
              meeting++
            }

            this.helper.setMeetingForm(meeting)
            if(meeting < 6){
              this.router.navigateByUrl(`form/(meeting-form:meeting_${meeting}/(meeting_${meeting}:${nextStep}))`)
            }
            else if(meeting == 6){
              this.router.navigateByUrl(`form/(meeting-form:survey-form)/(survey-form:survey-form)`)
            }
            else{
              this.router.navigate(['form'])
            }

          }
        )
    }
    else{
      this.addAlert(`Please come back when you have completed the ${this.meetingsOrder[this.MEETING_FORM]} meeting.`, Alert.ERROR)
    }
          

  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }


}
