import { SessionStorage } from 'app/storage/session.storage';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { lastValueFrom } from 'rxjs';
import { MeetingFormService } from '../meeting-form.service';

@Component({
  selector: 'third-meeting',
  templateUrl: './third-meeting.component.html',
  styleUrls: ['./third-meeting.component.scss']
})
export class ThirdMeetingComponent implements OnInit {
  
  form: FormGroup
  loading: boolean = true

  constructor(
    private fb: FormBuilder,
    private formService: MeetingFormService,
    private service: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    public auth: SessionStorage
  ) {
    this.formService.clear() 
    this.form = this.fb.group({
      dateSetted: [false, Validators.required],
      date: ['', Validators.required],
      meetingCompleted: [false, Validators.required]
    })
    this.formService.setForm(this.form)
   }

  async ngOnInit(): Promise<void> {
    if(this.helper.getSelectedProject() == -1 || this.helper.getSelectedEpe() == -1){
      this.router.navigateByUrl(`assigned-services`)
    }
    
    let response = await lastValueFrom(this.service.getForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe(), 3), {defaultValue: null})
    console.log(response)
    if(!response){
      this.loading = false
      return
    }
    let form: { [key: string]: any; } = response['meeting_form_3']
    let step = this.retrieveStep(form)

    console.log(step)
    
    this.formService.updateForm(form)
    this.formService.setStep(step)

    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false

    if(this.helper.getMeetingForm() > 3)
      this.router.navigateByUrl(`form/(meeting-form:meeting_3/(meeting_3:summary))`)
    else if (step > 0)
      this.router.navigateByUrl(`form/(meeting-form:meeting_3/(meeting_3:${this.formService.getStep(3)}))`)
  }

  private retrieveStep(form: { [key: string]: any; }): number{

    let keys = Object.keys(form)
    
    if(keys.indexOf('meetingCompleted') >= 0)
      return 2
    else if(keys.indexOf('date') >= 0)
      return 1
    return 0
  }

}
