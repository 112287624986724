<section class="u-clearfix u-palette-2-light-3 u-section-1">
    <div>
        <div class="u-clearfix u-expanded-width u-layout-wrap associations-layout">
            <div class="u-layout projects-card {{epe.association_status}} project_{{project_association_status}}" [ngClass]="{'not_approved' : canHide()}">
                <div class="u-layout-col">
                    <div>
                        <div class="u-layout-col" style="max-width: 100%;">
                        <div class="u-palette-1-base association-section">
                            <div class="u-container-layout association-section">
                            <div class="u-text epe-layout">
                                <span style="float:left; cursor: pointer;" (click)="expand(epe.id)" class="u-text">
                                    <span class="u-file-icon u-icon"><img src="/assets/icons/{{getExpandImg(epe.id)}}.png" alt=""></span>&nbsp;
                                </span>
                                <div class="pic-score-label">
                                    <p class="u-file-icon u-icon association-image">
                                        <img class="profile-icon" src="https://hsbooster.eu{{epe.picture}}" onerror="this.src='/assets/img/1177568.png'" alt="">
                                    </p>
                                    <div *ngIf="(!auth.isEpe() || (auth.isEpe() && (auth.isCallManager() || auth.isProjectBoard() || auth.isAdmin()))) && !auth.isProject()" class="content__block_number">
                                        <div class="numbers">
                                          <span class="num">{{epe.score}}</span>
                                          <span class="label">Score</span>
                                        </div>
                                        <div class="numbers">
                                          <span class="num">{{epe.nvotes}}</span>
                                          <span class="label">N.votes</span>
                                        </div>
                                    </div>
                                    <div *ngIf="project_association_status == 'approved' && (auth.isEpe() || (auth.isProject() && !canHide()))" class="content__block_number">
                                        <div class="numbers">
                                            <span class="num">{{meeting_form}} </span> 
                                            <span class="label">steps completed</span>
                                        </div>
                                    </div>
                                </div>
                                <div style="max-width: 50%;">
                                    <div>
                                        <p style="font-weight: 700">
                                            <span *ngIf="epe.version == 'v2'">{{epe.title}} </span>{{epe.name}} {{epe.surname}} &nbsp;<span class="u-file-icon u-icon"><a href="/epe/{{epe['id']}}" target="_blank"><img style="width: 15px" src="/assets/icons/external.png" alt=""></a></span>

                                        </p>
                                    </div>
                                    <p style="font-size: 0.725rem; color: #a8bed3; margin-bottom: 0;">{{epe.organisation_type}}</p>
                                    <p style="font-size: 0.725rem; color: #a8bed3;">{{epe.email}}</p>
                                    <p *ngIf="(!auth.isEpe() || (auth.isEpe() && (auth.isCallManager() || auth.isProjectBoard() || auth.isAdmin()))) && !auth.isProject()" style="font-size: 0.725rem; font-weight: 700;">
                                        <img style="margin-bottom: 2px;" src="/assets/img/{{epe['level']}}.png" alt="">
                                        {{epe['level']}} level matching
                                    </p>              
                                </div>
                                <div class="epe-input">
                                    <input (click)="change(epe.id)" *ngIf="!helper.isEpeSelected(epe.id) && auth.isCallManager()" class="form-check-input" type="checkbox">
                                    <input (click)="change(epe.id)" *ngIf="helper.isEpeSelected(epe.id) && auth.isCallManager()" class="form-check-input checked" type="checkbox">
                                </div>   
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selected == epe.id" class="u-layout-cell">
                    <div class="u-container-layout association-topics">            
                        <ul class="topic-ul-1" *ngFor="let topic of getTopicTags()">            
                            <li class="topic-li-1">                            
                                <p class="u-small-text u-text">                              
                                    <span style="font-size: 1rem; font-weight: 700;" class="u-text-palette-2-light-1">{{topic}}</span>                            
                                </p>                              
                                <ul *ngFor="let tag of getTopicTags(topic)">                              
                                    <li class="topic-li-2">                                  
                                        <span style="font-size: 0.925rem; font-weight: 700;" class="u-text-palette-2-light-1">{{tag}}</span>                                  
                                        <ul *ngFor="let t of getTopicTags(topic, tag)">              
                                            <li class="topic-li-3">              
                                                <p class="u-small-text u-text">{{t}}</p>              
                                            </li>              
                                        </ul>              
                                    </li>              
                                </ul>            
                            </li>            
                        </ul>                    
                    </div>              
                </div>
            </div>
        </div>
    </div>
</section>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert" ></simple-alert>