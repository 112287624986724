import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Epe, Vote } from 'app/models/epe';
import { EpesService } from 'app/services/epes.service';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';
import { Alert } from '../alerts/simple-message/simple-message.component';

@Component({
  selector: 'epe-sopcard',
  templateUrl: './epe-sopcard.component.html',
  styleUrls: ['./epe-sopcard.component.scss', './sopcard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EpeSopcardComponent implements OnInit, AfterViewInit {

  @Input() epe !: Epe
  opened = false
  votesOpened = false

  @Input() vote : Vote | null = null 

  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  constructor(
    private logger: Logger,
    public auth: SessionStorage,
    private service: EpesService,
    private cache: SopStorage
  ) { 
  }

  ngAfterViewInit(): void {

    if(this.auth.isCallManager() && this.epe.sop_status != 'pending' && this.epe.sop_status != 'not_eligible'){
      
      let vote = this.cache.getEpeMongo(this.epe.id)?.vote
      if(vote)
        this.vote = vote
      else
        this.service.getVote(this.epe.id).subscribe(
          then => {
            this.vote = then
            this.cache.setVote(this.epe.id, this.vote)
          }
        )
      
    }

  }

  ngOnInit(): void {
    this.logger.info(this.epe.topics)

  }

  getVoteIterable() : {voter:string, engagment:number, standardisation: number}[] {
    if(!this.vote || !this.vote.voters)
      return []

    if(typeof this.vote.engagment === 'number' || typeof this.vote.standardisation === 'number')
      return []

    let engagment : number[] = this.vote.engagment
    let standardisation : number[] = this.vote.standardisation

    if(engagment.length === 0 || standardisation.length === 0)
      return []

    return this.vote?.voters?.map(
      (value, index) => { 
        return {"voter": value, "engagment": engagment[index], "standardisation": standardisation[index]}
      }
    )
  }

  accordionAction(votes : boolean = false) {
    if(votes)
      this.votesOpened = !this.votesOpened
    else
      this.opened = !this.opened
  }

  getTopicTags(key:any = null, key2:any = null){

    if(key2){
      let tmp: any = this.epe.topics ? this.epe.topics[key] : null
      return this.epe.topics ? Object.values(tmp[key2]) : null
    }
    if(key)
      return this.epe.topics ? Object.keys(this.epe.topics[key]) : null
    
    return this.epe.topics ? Object.keys(this.epe.topics) : null
  }

  getTopicTag(key:any){
    let result:any = this.epe.topics ? this.epe.topics[key] : null
    return this.epe.topics ? result : null
  }

  getTopicName(topic: any, level: number = 0) {

    let highKey = Object.keys(topic)[0]
    let secondKey = Object.keys(topic[highKey])[0]
    let value = topic[highKey][secondKey]

    switch (level){
      case 0:
        return highKey
      case 1:
        return secondKey
      case 2: 
        return value
      default:
        return 'N/A'
    }
  }

  getVoteAvg(stand: boolean): number{

    if(!this.vote)
      return 0

    if(typeof this.vote.engagment === 'number' || typeof this.vote.standardisation === 'number')
      return 0

    let engagment : number[] = this.vote.engagment
    let standardisation : number[] = this.vote.standardisation

    if(engagment.length === 0 || standardisation.length === 0)
      return 0

    if(stand)
      return this.truncateFloat(standardisation.reduce(
        (previous, current) => previous + current) / standardisation.length)
    
    else
      return this.truncateFloat(engagment.reduce(
        (previous, current) => previous + current) / engagment.length)
        

  }

  truncateFloat(n : number) : number {
    return Math.floor(n * 100) / 100
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }


}
