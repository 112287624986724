<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
    <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
      <div style="margin-bottom: 30px;" class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div class="u-layout pb-2">
          <div class="u-layout-col">
            <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
              <div class="u-border-0 u-container-layout u-container-layout-1">
                <div class="meeting-form-header">
                  <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Survey Dashboard</h1>
                  <div class="u-text u-text-default u-text-1">
                    <div style="margin-bottom: 5px;">
                      <span style="font-weight: 500; font-size: 1.5rem">Project representative information:</span>
                    </div>
                    <div class="d-flex">
                      <p class="u-file-icon u-icon header-profile-icon">
                        <img class="profile-icon" src="https://hsbooster.eu{{project.picture}}" onerror="this.src='/assets/img/1177568.png'" alt="">
                      </p>&nbsp;
                      <p style="font-weight: 700;">
                        {{project.project_acronym}} ~ 
                        <span style="font-weight: 500;"><span *ngIf="project.version == 'v2'">{{project.title}} </span>{{project.name}} {{project.surname}}</span>
                        <br>
                        <span style="font-size: 0.725rem; color: #a8bed3;">{{project.organisation_type}}</span>
                        <br>
                        <span style="font-size: 0.725rem; color: #a8bed3;">{{project.email}}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <!--
                <p class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-2">
                  <span class="u-text-palette-4-base" style="font-weight: 400;">One</span>
                  <span style="font-weight: 400;">/</span>
                  <span class="u-text-palette-4-base" style="font-weight: 400;">Two</span>
                  <span style="font-weight: 400;">/</span>
                  <span class="u-text-palette-4-base" style="font-weight: 400;">Three</span>
                  <span style="font-weight: 400;">/</span> Current Page<span style="font-weight: 700;"></span>
                </p>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section>
    <survey-first-meeting></survey-first-meeting>
  </section>
  
  <!-- 
    <available-files></available-files>
  -->

  <simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>

