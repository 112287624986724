import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DatePipe, CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ErrorInterceptor, HeaderInterceptor } from './utilities/http.interceptors';
import { Logger } from './utilities/logger';
import { LoaderComponent } from './shared/loader/loader.component';
import { SharedModule } from './shared/shared.module';
import { NgApexchartsModule } from "ng-apexcharts";
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    HttpClientModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    NgApexchartsModule,
    HighchartsChartModule,
    NgxPaginationModule
  ],
  declarations: [
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    AppComponent,
    AdminLayoutComponent,
    LoaderComponent,
  ],
  providers: [
    DatePipe,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: HeaderInterceptor, 
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: ErrorInterceptor, 
      multi: true
    },
    // {
    //   provide: LocationStrategy, 
    //   useClass: HashLocationStrategy
    // },
    Logger
  ],
  bootstrap: [AppComponent],
  exports: [
    RouterModule,
    CommonModule,
    LoaderComponent,
    FormsModule
  ]
})
export class AppModule { }
