import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { catchError, EMPTY, fromEventPattern, lastValueFrom } from 'rxjs';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { MeetingFormService } from '../meeting-form.service';
import { SessionStorage } from 'app/storage/session.storage';
import { Epe } from 'app/models/epe';
import { SopStorage } from 'app/storage/sop.storage';
import { Project } from 'app/models/project';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['../meeting-form.component.scss']
})
export class ReportComponent implements OnInit {

  form_structure_name = "service_report"
  form: FormGroup
  loading: boolean = true

  project?: Project 
  topics: string = ''
  questions: Question[];
  title: string; 

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  toSubmit: boolean = true
  summary: {[index:string]:any} = {}
  read_only: boolean = false
  @ViewChild('summaryBox', {static: false}) summaryBox!: ElementRef

  constructor(
    private fb: FormBuilder,
    private formService: MeetingFormService,
    private mongoService: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    private meetingService: MeetingFormService,
    private auth: SessionStorage,
    private cache: SopStorage,
  ) { 
    //this.formService.clear() 
    this.form = this.fb.group({
      "1) Instructions to fill in this report": [''],
      "2) Description of first meetings": ['', Validators.required],
      //"3.1) Standardisation readiness": new FormArray([], [Validators.required]),
      "3.1) Standardisation readiness": new FormArray([]),
      "3.2) Standards and standardisation mapping landscape": new FormArray([]),
      "3.3) Access to standards": new FormArray([]),
      "3.4) Standardisation strategy and engagement": new FormArray([]),
      "3.5) Standards deliverables": new FormArray([]),
      "3.6) Training material": new FormArray([]),
      "3.6.1) Please specify any other training material suggested to the project in the box below": ['', Validators.required],
      "3.6.2) Do you recommend that the service recipient completes any of the following (tick what appropriate)?": new FormArray([]),
      "3.7) Please list the codes and names of the most relevant standards you have discussed with the project during the service and indicate if there is any plan for the project to contribute to these standards": ['', Validators.required],
      "4) Final Recommendations": ['', Validators.required],
    })
    this.formService.setReportForm(this.form)

    try{
      let data = require(`assets/meeting-form/service_report.json`)
      this.questions = data['questions']
      this.title = data['title']
    } catch (e){
      console.log(e)
      this.questions = []
      this.title = ''
    }
  }


  getTopicTags(key:any = null, key2:any = null){

    if(key2){
      let tmp: any = this.project!.topics ? this.project!.topics[key] : null
      return this.project!.topics ? Object.values(tmp[key2]) : null
    }
    if(key)
      return this.project!.topics ? Object.keys(this.project!.topics[key]) : null
    
    return this.project!.topics ? Object.keys(this.project!.topics) : null
  }

  getTopicTag(key:any){
    let result:any = this.project!.topics ? this.project!.topics[key] : null
    return this.project!.topics ? result : null
  }

  async ngOnInit() {
    this.project = this.cache.getProject(this.helper.getSelectedProject())!
    let response = await lastValueFrom(this.mongoService.getReportForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    console.log(response)
    if(!response){
      if(this.auth.isProject()){
        this.read_only = true
      }
      this.loading = false
      console.log("RETURN REPORT")
      return
    }
    let form: { [key: string]: any; } = response['report']
    this.formService.updateReportForm(form)
    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false
    
    if(this.router.url == "/form/(meeting-form:meeting_5/(meeting_5:summary))"){
      this.toSubmit = false
    }

    this.summary = this.meetingService.getReportSubmit()

    if(this.auth.isProject() || this.helper.getMeetingForm() > 5){
      this.read_only = true
    }
    else{
      this.read_only = false
    }
  }

  isOpenQuestion(question: Question) {
    return typeof question[this.getQuestionKey(question)] === 'string'
  }

  getQuestionKey(question: Question): string{
    return Object.keys(question)[0]
  }

  getQuestion(question: Question): string[] | string{
    return Object.values(question)[0]
  }

  getQuestionDescription(question: Question): string[] | string{
    return question["description"] ? question["description"] : ""
  }

  getKeys(): any[] {
    //console.log(this.summary)
    return Object.keys(this.summary)
  }

  confirm(): void{
    this.mongoService.submitReportForm(
      this.helper.getSelectedProject(), 
      this.helper.getSelectedEpe(), 
      this.formService.getReportSubmit()
      )
      .pipe(
        catchError(
          err => {
            this.addAlert("Activity Report update failed! Please contact the administrator", Alert.ERROR)
            return EMPTY
          }
        )
      )
      .subscribe(
        then => {
          console.log("SUBMIT REPORT_FORM")
          this.addAlert("Activity Report succesfully updated", Alert.SUCCESS)
        }
      )
  }


  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

  exportReportSummary() {
    const pdf = new jsPDF();
    var img = new Image();
    img.src = 'assets/img/hsbooster.png';
    pdf.addImage(img, 'PNG', 10, 10, 60, 20);
    pdf.setFont('helvetica', 'bold');
    pdf.setFontSize(20);
    pdf.text("Activity Report", 10, 45);
    pdf.setFontSize(10);
  
    let y = 60;
    let x = 10;
    let currentPage = 1;
    const pageHeight = pdf.internal.pageSize.getHeight();
  
    for (const key of Object.keys(this.summary)) {
      const textBoxWidth = 200;
      
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${key}`, x, y)
        y += 7;
  
      const lines = pdf.splitTextToSize(this.stripHtmlTags(this.summary[key]), textBoxWidth);

      for (const line of lines) {
        if (y > pageHeight - 20) {
          pdf.addPage();
          y = 20;
          currentPage++;
        }
        pdf.setFont('helvetica', 'normal');
        pdf.text(line, x, y);
        y += 7;
      }
      y += 3;
  
      if (currentPage !== 1 && y > pageHeight - 20) {
        pdf.addPage();
        y = 20;
        currentPage++;
      }
    }
  
    pdf.save(`activity_report.pdf`);
  }

  stripHtmlTags(content: string): string {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div.textContent || div.innerText || '';
  }
  


}

export interface Question {
  [key:string]: string[] | string
}
