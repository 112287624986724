import { Project } from 'app/models/project';
import { Association, AssociationMongo } from './../../models/association';
import { Component, OnInit } from '@angular/core';
import { Logger } from 'app/utilities/logger';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { AssociationsService } from 'app/services/associations.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { catchError, EMPTY, forkJoin, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { Epe } from 'app/models/epe';
import { EpesService } from 'app/services/epes.service';
import { clone } from 'app/utilities/helper';
import { AssociationHelper } from 'app/storage/association.storage';

enum AssociationStatusFilterEnum {
  all,
  open,
  identified,
  selected,
  approved,
  not_approved,
  completed,
  closed
}

const ALLOWED_STATUS = ['selected', 'approved', 'not_approved', "completed", "closed"]

@Component({
  selector: 'assigned-service',
  templateUrl: './assigned-service.component.html',
  styleUrls: ['./assigned-service.component.scss', '../sop.component.scss']
})
export class AssignedServiceComponent implements OnInit {
  loading: boolean = true
  associations:AssociationMongo[] | null = null
  projects:Project[] | null = []
  selected:number = -1
  AssociationStatusFilter = AssociationStatusFilterEnum
  filter: AssociationStatusFilterEnum = AssociationStatusFilterEnum.all
  epes: Epe[] | null = []

  epe?: Epe
  project?: Project
  
  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  search ?: string 

  searchProject(search:string){

    if(!this.projects)
      return

    if(search.length > 2){
      this.logger.info(this.projects.filter(x => x.username!.includes(search)), "Search Projects (Association)")
      /*setTimeout(()=>{
        this.scrollToRow(this.getPlayers().filter(x => x.name.toLowerCase().includes(search.toLowerCase()))[0].id)
      }, 100)*/
      
    }

  }
  
  constructor(    
    private sopService: SopService,
    private serviceMongo: AssociationsService,
    private epesService: EpesService, 
    private cache: SopStorage,
    public auth: SessionStorage,
    private logger: Logger,
    private drupalService: SopService,
    private helper: AssociationHelper
    ) { 

    }

  ngOnInit(): void {
    this.helper.clear()

    this.projects = this.cache.getProjects()
    this.epes = this.cache.getEpes()
    //this.associations = this.cache.getAssociationsMongo()
    //this.associations = this.helper.getAssociations()
    
    this.projects = this.projects ? this.projects.filter(project => project.sop_status == 'eligible') : null


    if(this.projects && this.epes && this.associations){
      
      this.loading = false
      return
    }


    forkJoin({
      projects: !this.projects ? this.sopService.getAll('project') : of(null),
      epes: !this.epes ? this.sopService.getAll('epe') : of(null),
      associations: !this.associations ? this.serviceMongo.getAll() : of(null)
    })
    .pipe(
      catchError(
        err => {
          this.addAlert("Association retriving error", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
          then.epes ? this.cache.setEpes(then.epes) : null
          this.projects = then.projects ? then.projects.filter(project => project.sop_status == 'eligible') : this.projects
          this.associations = then.associations ? then.associations : this.associations
          this.epes = then.epes ? clone(then.epes).filter(x => x.sop_status == 'voted') : clone(this.epes!).filter(x => x.sop_status == 'voted')
          this.epes?.forEach(
            x => this.updateEpeScore(x)
          )


          this.logger.info(then.projects, 'Get Projects Response')
          this.logger.info(then.associations, 'Get Associations Response')
          then.projects ? this.cache.setProjects(then.projects) : null
          then.associations ? this.helper.setAssociations(then.associations) : null

          if(this.auth.isEpe()){

            this.epe = this.epes.find( x => x.username == this.auth.getUsername())
            if(!this.epe){
              this.loading = false
              return
            }
            this.associations = this.associations!.filter( x => x.epe_id == this.epe!.id)
            this.projects = this.projects!.filter( x => this.isVisibleByEpe(x.id))
            this.helper.setActiveEpe(this.epe.id)
          }
          else if(this.auth.isProject()){

            this.project = this.projects ? this.projects.find( x => (x.username == this.auth.getUsername() && this.isVisibleByEpe(x.id))) : undefined
            if(!this.project){
              this.loading = false
              return
            }
            this.associations = this.associations!.filter( x => x.project_id == this.project!.id)
            //this.epes = this.epes!.filter( x => this.isVisibleByEpe(x.id))
            this.helper.setActiveProject(this.project.id)

          } 
          
          this.loading = false
      }
    )
  }

  isVisibleByEpe(project_id: number): boolean{
    return ALLOWED_STATUS.indexOf(this.getStatus(project_id)) >= 0
  }

  

  async updateEpeScore(epe: Epe){
    
    let scores = await lastValueFrom(this.epesService.getScore(epe.id))
    epe.score = scores.score
    epe.nvotes = scores.nvotes

  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }

  changeFilter(newStatus: AssociationStatusFilterEnum){
    this.filter = newStatus
  }

  toIter(n:number):Array<any> {
    return Array(n).fill(0).map((x,i)=>i)
  }

  getStatus(project_id: number): string{
    //var status = this.associations![project_id].association_status
    let projectAssociations = this.associations?.filter( x => x.project_id == project_id)

    if(!projectAssociations)
      return ''

    

    
      /*let epe_id = projectAssociations.find(x => this.isCurrentEpe(x.association_status))?.epe_id
      this.logger.info(epe_id, "FIND")
      if(epe_id)
        this.helper.setSelectedEpe(epe_id)*/
        if(projectAssociations.map(x => x.association_status).indexOf('closed') >= 0)
        return 'closed'
      else if(projectAssociations.map(x => x.association_status).indexOf('completed') >= 0)
        return 'completed'
      else if(projectAssociations.map(x => x.association_status).indexOf('approved') >= 0)
        return 'approved'
      else if(projectAssociations.map(x => x.association_status).indexOf('not_approved') >= 0)
        return 'not_approved'
      else if(projectAssociations.map(x => x.association_status).indexOf('selected') >= 0)
        return 'selected'
      else if(projectAssociations.map(x => x.association_status).indexOf('identified') >= 0)
        return 'identified'
      else if(projectAssociations.map(x => x.association_status).indexOf('open') >= 0)
        return 'open'
      
    return ''
  }

  isCurrentEpe(status: string | undefined): boolean{

    switch(status){
      case 'open':
      case 'waiting':
      case 'not_approved':
      case undefined:
        return false
    }
    return true

  }

  getBtnText(project_id: number){
    //console.log(this.associations)
    //var status = this.associations![project_id].association_status
    var status = this.getStatus(project_id)

    if (status.length <= 0){
      status = 'open'
    }

    switch(status){
      case 'open':
        return 'Association'
      case 'identified':
        return 'View Identified'
      case 'selected':
        return 'View Selected'
      case 'approved':
        return 'View Project Details'
      case 'not_approved':
      case 'closed':
      case 'completed':
        return 'View details'
      default:
        return 'error'
    }
  }

  getExpand(id:number = -1):string{

    if(id == this.selected)
      return 'Close details'
    return 'Open details'

  }

  getExpandImg(id:number = -1):string{

    if(id == this.selected)
      return 'reduce'
    return 'expand'

  }

  expand(id:number = -1){
    if(id == this.selected){
      this.selected = -1
      return 
    }
    
    let projectAssociations = this.associations?.filter( x => x.project_id == id)

    let epe_id = projectAssociations!.find(x => this.isCurrentEpe(x.association_status))?.epe_id
    
    if(epe_id)
      this.helper.setSelectedEpe(epe_id)
    else
      this.helper.setSelectedEpe(-1)

    let meeting = projectAssociations!.find( x => x.epe_id == epe_id && x.project_id == id)?.meeting

    if(meeting)
      this.helper.setMeetingForm(meeting)

    this.helper.setSelectedProject(id)

    this.selected = id
  }

  getProjects():Project[]{
    if (!this.projects) return []
    if(this.auth.isProject()) return this.project ? [this.project] : [] 
    if(this.search && this.search.length > 0)
      return this.projects.filter(x => x.username!.includes(this.search!))
    switch(this.filter){
      case AssociationStatusFilterEnum.all :
        return this.projects
      case AssociationStatusFilterEnum.open :
        return this.projects.filter( x => this.getStatus(x.id) == 'open')
      case AssociationStatusFilterEnum.identified :
        return this.projects.filter( x => this.getStatus(x.id) == 'identified')
      case AssociationStatusFilterEnum.selected :
        return this.projects.filter( x => this.getStatus(x.id) == 'selected')
      case AssociationStatusFilterEnum.approved :
        return this.projects.filter( x => this.getStatus(x.id) == 'approved')
      case AssociationStatusFilterEnum.not_approved :
        return this.projects.filter( x => this.getStatus(x.id) == 'not_approved')
      case AssociationStatusFilterEnum.completed :
          return this.projects.filter( x => this.getStatus(x.id) == 'completed')
      case AssociationStatusFilterEnum.closed :
          return this.projects.filter( x => this.getStatus(x.id) == 'closed')
      default:
        return this.projects
    }
  }

  getProjectEpes(project_id: number): Epe[]{

    if(!this.associations || !this.epes)
      return []


    let epes_id = this.associations.filter( x => x.project_id == project_id).map( x => x.epe_id)
    //let epes = clone(this.epes)
    let epes = [...this.epes]
    epes = epes.filter(x => epes_id.indexOf(x.id) >= 0).map( x => {
      x.topics = this.getTopics(x, x.id, project_id);
      x['association_status'] = this.associations!.find(y => y.epe_id === x.id && y.project_id === project_id)?.association_status 
      return x
    })
    
    var index = epes.findIndex(item => item["association_status"]  != "open" && item["association_status"] != "waiting")
    epes.unshift(epes.splice(index, 1)[0]);

    if(this.auth.isEpe() || this.auth.isProject()){
      epes.splice(1)
      return epes
    }
    epes.splice(5)
    return epes

  }

  
  getTopics(epe: Epe, epe_id: number, project_id:number): Object[] | undefined{

    if(!this.associations)
      return undefined
    let association: AssociationMongo | undefined = this.associations.find(x => x.epe_id == epe_id && x.project_id == project_id)
    epe['low'] = association?.low
    epe['middle'] = association?.middle
    epe['high'] = association?.high
    return association?.topics

  }

}

