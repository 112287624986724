<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
  <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
    <div class="u-clearfix u-expanded-width u-layout-wrap sop-header-layout">
      <div class="u-layout pb-2">
        <div class="u-layout-col">
          <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
            <div class="u-border-1 u-border-no-left u-border-no-right u-border-no-top u-border-palette-5-base u-container-layout sop-header">
              <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Evaluator</h1>
            </div>
          </div>
          <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="u-clearfix u-palette-1-base u-section-2" id="sec-5e41">
  <loader *ngIf="loading"></loader>
  <div class="card-container">
    <div class="u-clearfix u-sheet u-valign-middle u-sheet-1 card-layout">
      <div class="u-clearfix u-expanded-width u-layout-wrap">
        <div class="u-layout epes-card">
          <div class="u-layout-col">
            <div class="u-size-30">
              <div class="u-layout-col">
                <div class="u-container-style u-layout-cell u-palette-1-base u-shape-round u-size-60 u-top-left-radius-20 u-top-right-radius-20">
                  <div class="u-border-2 u-border-palette-2-base u-container-layout u-container-layout-1">
                    <div class="u-text u-text-1 d-flex">
                      <p class="u-file-icon u-icon header-profile-icon">
                        <img class="profile-icon" src="https://hsbooster.eu{{epe.picture}}" onerror="this.src='/assets/img/1177568.png'" alt="">
                      </p>&nbsp;
                      <p style="font-weight: 700;">
                        <span *ngIf="epe.version == 'v2'">{{epe.title}} </span>{{epe.name}} {{epe.surname}}
                        <br>
                        <span style="font-size: 0.725rem; color: #a8bed3;">{{epe.organisation_type}}</span>
                        <br>
                        <span style="font-size: 0.725rem; color: #a8bed3;">{{epe.email}}</span>
                      </p>                    
                      <br>
                    </div>
                    <div *ngIf="isVoted(epe)" class="content__block_number">
                      <div class="numbers">
                        <span class="num">{{epe.score}}</span>
                        <span class="label">Score</span>
                      </div>
                      <div class="numbers">
                        <span class="num">{{epe.nvotes}}</span>
                        <span class="label">N.votes</span>
                      </div>
                    </div>
                    
                    <a (click)="expand(epe.id)"
                      class="u-border-2 u-border-black u-border-hover-black u-btn u-btn-round u-button-style u-hover-palette-4-light-3 u-none u-radius-21 u-text-body-color u-text-hover-black action-btn u-clearfix">
                      <span *ngIf="isWarning(epe)"><img style="margin-bottom:3px" src="/assets/icons/warning.png"></span>
                      {{getBtnText(epe.sop_status)}}
                      &nbsp;&nbsp;
                      <span class="u-file-icon u-icon"><img src="/assets/img/271226.png" alt=""></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selected == epe.id" class="u-size-30">
              <div class="u-layout-row">
                <div class="u-container-style u-custom-color-1 u-layout-cell u-size-60 u-layout-cell-2">
                  <div class="u-container-layout sop-card-container">
                    <epe-sopcard [vote]="voteChangeDetectorValue" [epe]="epe"></epe-sopcard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="u-border-2 u-border-no-top u-border-palette-2-base u-bottom-left-radius-20 u-bottom-right-radius-20 u-container-style u-group u-palette-1-base u-shape-round u-group-1 epes-card">
        <div class="u-container-layout sop-card-footer">
          <img class="u-image u-image-default u-preserve-proportions sop-status-img" src="/assets/img/{{epe.sop_status}}.png" onerror="this.src='/assets/img/pending.png'" alt="" data-image-width="89" data-image-height="25">
          <p style="cursor: pointer;" (click)="expand(epe.id)" class="u-text u-text-default">
            <span class="u-file-icon u-icon"><img src="/assets/icons/{{getExpandImg(epe.id)}}.png" alt=""></span>&nbsp;
            {{getExpand(epe.id)}}​&nbsp;
          </p>
          <p class="u-text u-text-default u-text-variant">
            <span class="u-text-palette-2-dark-1">User ID:&nbsp;</span>
            <span style="font-weight: 700;">#{{epe.id}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert" ></simple-alert>
