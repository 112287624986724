import { Component, OnInit } from '@angular/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AssociationMongo } from 'app/models/association';
import { Epe } from 'app/models/epe';
import { Project } from 'app/models/project';
import { AssociationsService } from 'app/services/associations.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { catchError, EMPTY, forkJoin, map, of } from 'rxjs';
import * as _ from 'lodash';
import { AssociationHelper } from 'app/storage/association.storage';
import { Router } from '@angular/router';


@Component({
  selector: 'services',
  templateUrl: './services.component.html',
  styleUrls: ['../sop.component.scss']
})
export class ServicesComponent implements OnInit {
  loading: boolean = true
  sortSelected = 'Project'
  sortDirection = 'ASC'
  headerRow = [ 
    'ID',
    'Project', 
    'Submission date UTC',
    'EPE', 
    'Status', 
    'Approv date UTC',
    'Meeting 1 set/done',
    'Meeting 1 date',
    'Meeting 2 set/done',
    'Meeting 2 date',
    'Meeting 3 set/done',
    'Meeting 3 date',
    'Advice',
    'Final meeting set/done',
    'Final meeting date',
    'Report Submission',
    'Service complete'
  ]

  reportHeaderRow = [ 
    'ID',
    'Project', 
    'EPE', 
    'Topics',
    'Status', 
    '1) Instructions to fill in this report',
    '2) Description of first meetings',
    '3.1) Standardisation readiness',
    '3.2) Standards and standardisation mapping landscape',
    '3.3) Access to standards',
    '3.4) Standardisation strategy and engagement',
    '3.5) Standards deliverables',
    '3.6) Training material',
    '3.6.1) Please specify any other training material suggested to the project in the box below',
    '3.6.2) Do you recommend that the service recipient completes any of the following (tick what appropriate)?',
    '3.7) Please list the codes and names of the most relevant standards you have discussed with the project during the service and indicate if there is any plan for the project to contribute to these standards',
    '4) Final Recommendations'
  ]

  adviceHeaderRow = [ 
    'ID',
    'Project', 
    'EPE', 
    'Status', 
    'Advice'
  ]

  epes: Epe[] | null = []
  associations: AssociationMongo[] | null = []
  reports: any[] | null = []
  advice: any[] | null = []
  projects: Project[] | null = []

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS


  constructor(
    private sopService: SopService,
    private associationService: AssociationsService,
    private cache: SopStorage,
    private auth: SessionStorage,
    private helper: AssociationHelper,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.projects = this.cache.getProjects()
    this.epes = this.cache.getEpes()

    forkJoin({
      projects: !this.projects ? this.sopService.getAll('project') : of(null),
      epes: !this.epes ? this.sopService.getAll('epe') : of(null),
      associations: this.associationService.getAllServices()
    })
    .pipe(
      catchError(
        err => {
          this.addAlert("Association retriving error", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
        this.epes = then.epes ? then.epes : this.epes
        this.projects = then.projects ? then.projects : this.projects
        console.log(then.associations)
        this.associations = then.associations?.map(
          association => (
            {
              ...association,
              'ID': association.project_id+"_"+association.epe_id,
              'Project': this.projects?.find(project => project.id == association.project_id)?.project_acronym,
              'Submission date UTC': this.timestampToDate(this.projects?.find(project => project.id == association.project_id)?.created_at),
              'EPE': this.epes?.find(epe => epe.id == association.epe_id)?.name + " " + this.epes?.find(epe => epe.id == association.epe_id)?.surname,
              'Status': association.association_status,
              'Approv date UTC': association.approvation_date ? association.approvation_date : '',
              'Meeting 1 set/done': this.getMeetingStatus(association.meeting_form_1),
              'Meeting 1 date': this.getMeetingDate(association.meeting_form_1),
              'Meeting 2 set/done': this.getMeetingStatus(association.meeting_form_2),
              'Meeting 2 date': this.getMeetingDate(association.meeting_form_2),
              'Meeting 3 set/done': this.getMeetingStatus(association.meeting_form_3),
              'Meeting 3 date': this.getMeetingDate(association.meeting_form_3),
              'Advice':  this.getMeetingComplete(association.meeting_form_4),
              'Final meeting set/done': this.getMeetingStatus(association.meeting_form_5),
              'Final meeting date': this.getMeetingDate(association.meeting_form_5),
              'Report Submission': this.getSubmissionDate(association.report_submission_date, association.meeting_form_5),
              'Service complete': this.getMeetingComplete(association.meeting_form_5),
            }
          )
        )
        
        this.reports = then.associations?.map(
          association => ({
            'ID': association.project_id+"_"+association.epe_id,
            'Project': this.projects?.find(project => project.id == association.project_id)?.project_acronym,
            'EPE': this.epes?.find(epe => epe.id == association.epe_id)?.name + " " + this.epes?.find(epe => epe.id == association.epe_id)?.surname,
            'Topics': this.generateTopics(this.projects?.find(project => project.id == association.project_id)),
            'Status': association.association_status,
            '1) Instructions to fill in this report' : association.report && association.report['1) Instructions to fill in this report']? association.report['1) Instructions to fill in this report'] : "",
            '2) Description of first meetings' : association.report && association.report['2) Description of first meetings'] ? association.report['2) Description of first meetings'] : "",
            '3.1) Standardisation readiness' : association.report && association.report['3.1) Standardisation readiness'] ? association.report['3.1) Standardisation readiness']?.join("\n ") : "",
            '3.2) Standards and standardisation mapping landscape' : association.report && association.report['3.2) Standards and standardisation mapping landscape']? association.report['3.2) Standards and standardisation mapping landscape']?.join("\n "): "",
            '3.3) Access to standards' : association.report && association.report['3.3) Access to standards'] ? association.report['3.3) Access to standards']?.join("\n ") : "",
            '3.4) Standardisation strategy and engagement' : association.report && association.report['3.4) Standardisation strategy and engagement'] ? association.report['3.4) Standardisation strategy and engagement']?.join("\n "): "",
            '3.5) Standards deliverables' : association.report && association.report['3.5) Standards deliverables'] ? association.report['3.5) Standards deliverables']?.join("\n ") : "",
            '3.6) Training material' : association.report && association.report['3.6) Training material'] ? association.report['3.6) Training material']?.join("\n ") : "",
            '3.6.1) Please specify any other training material suggested to the project in the box below' : association.report && association.report['3.6.1) Please specify any other training material suggested to the project in the box below'] ? association.report['3.6.1) Please specify any other training material suggested to the project in the box below']: "",
            '3.6.2) Do you recommend that the service recipient completes any of the following (tick what appropriate)?' : association.report && association.report['3.6.2) Do you recommend that the service recipient completes any of the following (tick what appropriate)?'] ? association.report['3.6.2) Do you recommend that the service recipient completes any of the following (tick what appropriate)?']?.join("\n "): "",
            '3.7) Please list the codes and names of the most relevant standards you have discussed with the project during the service and indicate if there is any plan for the project to contribute to these standards' : association.report && association.report['3.7) Please list the codes and names of the most relevant standards you have discussed with the project during the service and indicate if there is any plan for the project to contribute to these standards'] ? association.report['3.7) Please list the codes and names of the most relevant standards you have discussed with the project during the service and indicate if there is any plan for the project to contribute to these standards']: "",
            '4) Final Recommendations' : association.report && association.report['4) Final Recommendations'] ? association.report['4) Final Recommendations'] : ""
          })
        )
        this.advice = then.associations?.map(
          association => ({
            'ID': association.project_id+"_"+association.epe_id,
            'Project': this.projects?.find(project => project.id == association.project_id)?.project_acronym,
            'EPE': this.epes?.find(epe => epe.id == association.epe_id)?.name + " " + this.epes?.find(epe => epe.id == association.epe_id)?.surname,
            'Status': association.association_status,
            'Advice' : association.meeting_form_4 && association.meeting_form_4['Advice']? association.meeting_form_4['Advice'] : ""
          })
        )
        this.cache.setEpes(this.epes!)
        this.cache.setProjects(this.projects!)
        this.associations?.sort(this.statusSort(this.sortDirection, this.sortSelected))
        this.loading = false
      }
    )

  }

  generateTopics(project: Project | undefined){
    console.log("-----generateTopics: "+project)
    if(!project || !project['topics']){
      return ''
    }
    var topics  = '';
    for (const [firstLevelKey, firstLevelValue] of Object.entries(project!['topics'])) {
      topics += firstLevelKey + '\n';
      for (const [secondLevelKey, secondLevelValue] of Object.entries(firstLevelValue)) {
        topics += '\t' + secondLevelKey + '\n';
        secondLevelValue.forEach((thirdLevelKey: string) => {
          topics += '\t\t' + thirdLevelKey + '\n';
        });
      }
    }
    return topics
  }

  statusSort(sortDirection: string, sortSelected: string){
    return function(a: AssociationMongo, b: AssociationMongo) {
      if(a && b){
        if(sortDirection == 'ASC'){
          return a[sortSelected].localeCompare(b[sortSelected])
        }
        else{
          return a[sortSelected].localeCompare(b[sortSelected])*-1
        }
      }
      return 0;
    }
  }

  sortCell(cell: string){
    if(this.sortDirection == 'ASC'){
      this.sortDirection = 'DESC'
    }
    else{
      this.sortDirection = 'ASC'
    }
    this.sortSelected = cell
    console.log(this.sortDirection)
    console.log(this.sortSelected)
    this.associations?.sort(this.statusSort(this.sortDirection, this.sortSelected))
    console.log(this.associations)
  }

  exportAuthorized(){
    return this.auth.isAdmin() || this.auth.isCallManager() || this.auth.isProjectBoard()
  }

  timestampToDate(timestamp: string | undefined){
    if(!timestamp)
      return ''
    
    const date = new Date(parseInt(timestamp) * 1000);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }

  stripHtmlTags(content: string): string {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div.textContent || div.innerText || '';
  }
  
  exportReportsCSV() {
    const reports = [...this.reports!];
    const reports_picked = reports.map(rep => this.pickReportFields(rep, this.reportHeaderRow));
    const csvOptions = this.getCsvOptions('SOP Services Reports', this.reportHeaderRow);
  
    new AngularCsv(reports_picked, 'SOP-Services-Reports-HSBooster', csvOptions);
  }
  
  exportAdvicesCSV() {
    const advice = [...this.advice!];
    const advice_picked = advice.map(rep => this.pickReportFields(rep, this.adviceHeaderRow));
    const csvOptions = this.getCsvOptions('SOP Services Advice', this.adviceHeaderRow);
  
    new AngularCsv(advice_picked, 'SOP-Services-Advice-HSBooster', csvOptions);
  }
  
  exportCSV() {
    const associations = [...this.associations!];
    const associations_picked = associations.map(ass => _.pick(ass, this.headerRow));
    const csvOptions = this.getCsvOptions('SOP Services', this.headerRow);
  
    new AngularCsv(associations_picked, 'SOP-Services-HSBooster', csvOptions);
  }
  
  pickReportFields(report: any, headerRow: string[]) {
    const pickedReport = _.pick(report, headerRow);
  
    Object.keys(pickedReport).forEach(field => {
      pickedReport[field] = this.stripHtmlTags(pickedReport[field]);
    });
  
    return pickedReport;
  }
  
  getCsvOptions(title: string, headerRow: string[]) {
    return {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: title,
      useBom: true,
      noDownload: false,
      headers: headerRow
    };
  }
  

  openNewTab(url: any){
    console.log(url, "provaURL")
    window.open(url)
  }

  getSubmissionDate(report_submission_date: string, meeting_form_5: any){
    console.log(report_submission_date, meeting_form_5)
    if(!meeting_form_5){
      return ''
    }
    else if(report_submission_date){
      return report_submission_date
    }
    else if(meeting_form_5['complete']){
      return meeting_form_5['date']
    }
    else{
      return ''
    }
  }

  getMeetingComplete(meetingForm: any){
    if(!meetingForm){
      return ''
    }
    if(meetingForm['complete']){
      return 'DONE'
    }

    return ''
  }

  getMeetingStatus(meetingForm: any){
    if(!meetingForm){
      return ''
    }
    if(meetingForm['dateSetted']){
      if(meetingForm['meetingCompleted']){
        return 'DONE'
      }
      else{
        return 'SET'
      }
    }
    return ''
  }

  getMeetingDate(meetingForm: any){
    if(!meetingForm){
      return ''
    }
    if(meetingForm['dateSetted'] && meetingForm['date']){
      return meetingForm['date']
    }
    return ''
  }

  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

  isCurrentEpe(status: string | undefined): boolean{

    switch(status){
      case 'open':
      case 'waiting':
      case 'not_approved':
      case undefined:
        return false
    }
    return true

  }

  goToService(project: number | undefined, epe: number| undefined){
    if(!project || !epe)
      return
    
    let projectAssociations = this.associations?.filter( x => x.project_id == project)

    let epe_id = projectAssociations!.find(x => this.isCurrentEpe(x.association_status))?.epe_id
    
    if(epe_id)
      this.helper.setSelectedEpe(epe_id)
    else
      this.helper.setSelectedEpe(-1)

    let meeting = projectAssociations!.find( x => x.epe_id == epe_id && x.project_id == project)?.meeting

    if(meeting)
      this.helper.setMeetingForm(meeting)

    this.helper.setSelectedProject(project)

    let form = this.helper.getMeetingForm()
    if(this.auth.isProject() && form <= 1){
      this.addAlert("Please wait until the Epe complete at least one form", Alert.WARNING)
      return
    }
    if(form === 0 || !form)
      form = 1
    form = this.auth.isProject() && form < 7 ? form - 1 : form
    console.log(form)
    let route = this.auth.isProject() ? 'summary' : 'first'
    if(form < 6){
      console.log("There")
      this.router.navigateByUrl(`form/(meeting-form:meeting_${form}/(meeting_${form}:${route}))`)
    }
    else if(this.auth.isProjectBoard() || this.auth.isCallManager() || this.auth.isAdmin()){
      this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:admin))`)
    }
    else if(form == 6){
      this.router.navigateByUrl(`form/(meeting-form:survey-form)`)
    }
    else{
      console.log("here")
      this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:summary))`)
    }

  }

}
