import { FifthMeetingThirdStepComponent } from './../form-steps/fifth-meeting-third-step/fifth-meeting-third-step.component';
import { FifthMeetingSecondStepComponent } from './../form-steps/fifth-meeting-second-step/fifth-meeting-second-step.component';
import { FifthMeetingFirstStepComponent } from './../form-steps/fifth-meeting-first-step/fifth-meeting-first-step.component';
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormSummary } from "../form-steps/form-summary/form-summary";

const routes = [

  {
    path: '',
    redirectTo: 'summary',
    pathMatch: 'full'
  },

  {
      path: 'first',
      component: FifthMeetingFirstStepComponent, // to define
      /*children: [{
        path: '',
        loadChildren:
          () => import('./form/meeting-form/meeting-form.module')
          .then(m => m.MeetingFormModule),
        outlet: 'formstep'
      }]*/
  },
  {
      path: 'second',
      component: FifthMeetingSecondStepComponent
  }, 
  {
      path: 'third',
      component: FifthMeetingThirdStepComponent
  },
  {
      path: 'summary',
      component: FormSummary,
      data: {form: 'composer', report: true}
  }
  
]


@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class FifthMeetingRoutingModule { }
