import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, Observable, of } from 'rxjs';
import { Logger } from 'app/utilities/logger';

  
@Injectable({
  providedIn: 'root'
})
export class UserService {
  
   
  constructor(
    private httpClient: HttpClient,
    private logger: Logger
    ) { }


  getToken(): Observable<string>{
    const headers : HttpHeaders = new HttpHeaders({'Access-Control-Allow-Origin' : '*'});
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<string>('drupal/jwt/token', {headers})
          .pipe(
            catchError(
              (err, caught) => {
                this.logger.error(err)
                window.location.href = environment.apiHost+"/user/login";
                return of('')
              }
            )
          );
  }

  getRoles(): Observable<{roles: string[], username: string, picture: string}>{
    const headers : HttpHeaders = new HttpHeaders({'Access-Control-Allow-Origin' : '*'});
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<{roles: string[], username: string, picture: string}>('drupal/role', {headers});
  }
  
  
}