import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssociationMongo } from 'app/models/association';
import { Project } from 'app/models/project';
import { Epe } from 'app/models/epe';
import { stringify } from 'querystring';

  
@Injectable({
  providedIn: 'root'
})
export class AssociationsService {
  private url =  '/api/v1/associations/';
   
  constructor(private httpClient: HttpClient) { }
  
  getAll(): Observable<any []>{
    return this.httpClient.get<any []>( this.url); 
  }
  
  getAllServices(): Observable<any []>{
    return this.httpClient.get<any []>( this.url+"services/"); 
  }
  
  createAssociation(projects: Project[], epes: Epe[]): Observable<AssociationMongo>{
    
    return this.httpClient.post<AssociationMongo>( this.url, {projects, epes});
  }

  submitAssociation(project_id: number, epe_id: number, role: string): Observable<any>{
    return this.httpClient.patch<any>(this.url + 'submit/' + role, {project_id, epe_id})
  }

  submitAssociationEpe(project_id: number, epe_id: number, answer: boolean): Observable<any>{
    var role: string
    if(answer)
      role = 'approved'
    else
      role = 'not_approved'
    return this.httpClient.patch<any>(this.url + 'submit/epe/' + role, {project_id, epe_id})
  }

  submitReportForm(project_id: number, epe_id: number, data: any): Observable<any>{
    return this.httpClient.patch<any>(this.url + `report-form/${project_id}/${epe_id}`, data )
  }

  submitEpeSurveyForm(project_id: number, epe_id: number, data: any): Observable<any>{
    return this.httpClient.patch<any>(this.url + `epe-survey-form/${project_id}/${epe_id}`, data )
  }

  submitProjectSurveyForm(project_id: number, epe_id: number, data: any): Observable<any>{
    return this.httpClient.patch<any>(this.url + `project-survey-form/${project_id}/${epe_id}`, data )
  }

  closeAssociation(project_id: number, epe_id: number): Observable<any>{
    return this.httpClient.patch<any>(this.url + `close/${project_id}/${epe_id}`, {project_id, epe_id})
  }

  submitForm(project_id: number, epe_id: number, form_number: number, data: any, complete:boolean, skip:boolean): Observable<any>{
    data['complete'] = complete
    data['skip'] = skip
    return this.httpClient.patch<any>(this.url + `form/${project_id}/${epe_id}/${form_number}`, data )
  }

  submitProjectAdviceForm(project_id: number, epe_id: number, data: any): Observable<any>{
    return this.httpClient.patch<any>(this.url + `advice-form/${project_id}/${epe_id}`, data )
  }

  submitProjectForm(project_id: number, epe_id: number, form_number: number, data: any): Observable<any>{
    return this.httpClient.patch<any>(this.url + `project-form/${project_id}/${epe_id}/${form_number}`, data )
  }

  getEpeSurveyForm(project_id: number, epe_id: number): Observable<{[key:string]:{[key:string]:any}}>{
    return this.httpClient.get<{[key:string]:{[key:string]:any}}>(this.url + `epe-survey-form/${project_id}/${epe_id}` )
  }

  getProjectSurveyForm(project_id: number, epe_id: number): Observable<{[key:string]:{[key:string]:any}}>{
    return this.httpClient.get<{[key:string]:{[key:string]:any}}>(this.url + `project-survey-form/${project_id}/${epe_id}` )
  }

  getReportForm(project_id: number, epe_id: number): Observable<{[key:string]:{[key:string]:any}}>{
    return this.httpClient.get<{[key:string]:{[key:string]:any}}>(this.url + `report-form/${project_id}/${epe_id}` )
  }

  getProjectMeetingForm(project_id: number, epe_id: number, form_number: number): Observable<{[key:string]:{[key:string]:any}}>{
    return this.httpClient.get<{[key:string]:{[key:string]:any}}>(this.url + `project-form/${project_id}/${epe_id}/${form_number}` )
  }

  getProjectAdviceForm(project_id: number, epe_id: number): Observable<{[key:string]:{[key:string]:any}}>{
    return this.httpClient.get<{[key:string]:{[key:string]:any}}>(this.url + `advice-form/${project_id}/${epe_id}` )
  }

  getForm(project_id: number, epe_id: number, form_number: number): Observable<{[key:string]:{[key:string]:any}}>{
    return this.httpClient.get<{[key:string]:{[key:string]:any}}>(this.url + `form/${project_id}/${epe_id}/${form_number}` )
  }

  getFormStatus(project_id: number, epe_id: number): Observable<{meeting:number}>{
    return this.httpClient.get<{meeting: number}>(this.url + `form/${project_id}/${epe_id}` )
  }
 
}