export interface Model {
    [index:string]: any
    id?: number
}

export class MongoModel {
    [index: string]:any
    protected _id ?: { $oid: string; }

    public getId(): string | null{
        return this._id?.$oid || null
    }

}