<report></report>
<div class="form-container">
    <div class="form-box">
        <h3>Do you need another intermediate meeting with the project or are you ready to provide your advice on the activities to perform?</h3>
        <div class="btn-layout">
            <button (click)="skip(false)" class="u-btn sop-btn">Go to meeting {{MEETING_FORM+2}}</button>
            <button (click)="skip(true)" class="u-btn sop-btn">No, go directly to ADVICE</button>
        </div>
    </div>
</div>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>