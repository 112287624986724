import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Epe} from "../../models/epe";
import {SopStorage} from "../../storage/sop.storage";
import {SopService} from "../../services/sop.service";
import {Logger} from "../../utilities/logger";
import {DeepDive} from "../../models/deep_dive";
import {DeepDivesService} from "../../services/deep_dives.service";
import Swal from "sweetalert2";
import {SessionStorage} from "../../storage/session.storage";
import {Alert} from "../../shared/alerts/simple-message/simple-message.component";

@Component({
    selector: 'deep-dive',
    templateUrl: './deep-dive.component.html',
    styleUrls: ['./deep-dive.component.scss', '../sop.component.scss']
})
export class DeepDiveComponent implements OnInit {
    loading: boolean = true;
    epes: Epe[] | null = null;
    orginalEpes: any[] | null = null;

    deepDives: any[] = [];

    @ViewChild('epeSearchInput') epeInput: ElementRef | undefined;

    selectedEpes: Epe[] = [];
    showSearches: boolean = false;
    searchedEpes: Epe[] = [];

    selectedId: any = null;
    selectedServiceType: string = 'DD';
    serviceName: string = '';
    description: string = '';
    effort: string = '';
    isEdit: boolean = false;
    editDays: boolean = false;
    row = null;

    // alert variables

    showAlert: boolean = false
    alertMessage: string = ''
    alertType: Alert = Alert.SUCCESS

    constructor(
        private service: SopService,
        private cache: SopStorage,
        private session: SessionStorage,
        private logger: Logger,
        private deepDivesService: DeepDivesService
    ) {
    }

    ngOnInit(): void {

        this.deepDivesService.getAll().subscribe(
            then => {
                this.deepDives = then.filter((deepDive: DeepDive) => {
                    return deepDive.type === 'DD';
                })
            }
        )

        this.epes = this.cache.getEpes()
        this.orginalEpes = this.cache.getEpes()
        if (!this.epes) {
            this.service.getAll('epe').subscribe(
                then => {
                    this.epes = then
                    this.orginalEpes = then
                    this.loading = false

                    this.cache.setEpes(this.epes)
                    this.addListener()
                }
            )
        } else {
            this.loading = false
            this.addListener()
        }

    }

    addListener() {
        this.epeInput?.nativeElement.addEventListener('keyup', () => {
            this.showSearches = true;
            // @ts-ignore
            const term = this.epeInput.nativeElement.value;
            if (term === '') {
                this.showSearches = false;
            } else {
                this.searchedEpes = this.filterEpes(term);
            }
        });

        this.epeInput?.nativeElement.addEventListener('focusin', () => {
            this.showSearches = true;
            // @ts-ignore
            const term = this.epeInput.nativeElement.value;
            if (term === '') {
                this.showSearches = false;
            } else {
                this.searchedEpes = this.filterEpes(term);
            }
        });

        this.epeInput?.nativeElement.addEventListener('focusout', () => {
            // set timeout of 0.3 seconds to allow click event to fire
            setTimeout(() => {
                this.showSearches = false;
            }, 300);
        });
    }

    filterEpes(text: string) {
        // first 5 results by name and surname or email
        // @ts-ignore
        return this.epes.filter(epe => {
            const term = text.toLowerCase();
            // @ts-ignore
            return (epe.name.toLowerCase() + ' ' + epe.surname.toLowerCase() + ' ' + epe.email.toLowerCase()).includes(term);
        }).slice(0, 5);
    }

    setEpe(epe: Epe) {
        this.selectedEpes.push(epe);
        // @ts-ignore
        this.epes = this.epes?.filter(e => e.id !== epe.id);
        // @ts-ignore
        this.epeInput?.nativeElement.value = '';
        this.showSearches = false;
    }

    getEpes(epes: any) {
        return epes.map((epe: any) => {
            let tmpEpe = this.orginalEpes?.find(e => e.id === epe.id)
            tmpEpe.status = epe.status
            return tmpEpe
        });
    }

    getEpeString(epe: any): string {
        let str = epe.name + ' ' + epe.surname + ' - ' + epe.email;
        if (epe.status === 'PENDING') {
            str += ' (PENDING)'
        } else if (epe.status === 'APPROVED') {
            str += ' (APPROVED)'
        } else {
            str += ' (NOT APPROVED)'
        }
        return str;
    }

    removeEpe(epe: Epe) {
        this.selectedEpes = this.selectedEpes.filter(e => e.id !== epe.id);
        // @ts-ignore
        this.epes.push(epe);
    }

    onSubmit(status: String) {
        console.log('Form submitted:', this.selectedEpes, this.selectedServiceType, this.serviceName, this.description, this.effort);
        const deepDive: any = {
            name: this.serviceName,
            description: this.description,
            authorUsername: this.session.getUsername(),
            type: this.selectedServiceType,
            status: status,
            effort: this.effort,
        }

        if (!this.editDays){
            deepDive['epes'] = this.selectedEpes.map(epe => {
                return {
                    id: epe.id,
                    status: 'PENDING',
                }
            })
            deepDive['report'] = this.selectedEpes.map(epe => {
                return {
                    epe_id: epe.id,
                    file: '',
                }
            })
        } else {
            if (this.row) {
                deepDive['epes'] = this.row['epes']
                deepDive['report'] = this.row['report']
            }
        }

        if (this.isEdit) {
            deepDive._id = this.selectedId;
            this.deepDivesService.updateDeepDive(deepDive).subscribe(
                then => {
                    if (then['message'] === 'Deep dive updated!') {
                        this.addAlert('Deep Dive updated successfully', Alert.SUCCESS)
                        this.deepDives = this.deepDives.map(it => {
                            if (it._id['$oid'] === deepDive._id['$oid']) {
                                return deepDive
                            }
                            return it
                        })
                    } else {
                        this.addAlert('Deep Dive update failed', Alert.ERROR)
                    }
                }
            )
            this.isEdit = false;
        } else {
            this.deepDivesService.createDeepDive(deepDive).subscribe(
                then => {
                    console.log(then)
                    if (then['message'] === 'Create failed!') {
                        this.addAlert('Deep Dive creation failed', Alert.ERROR)
                    } else {
                        this.deepDives.push(then)
                        this.addAlert('Deep Dive created successfully', Alert.SUCCESS)
                    }
                }
            )
        }
        this.epes = this.orginalEpes;
    }

    clearSelected() {
        this.isEdit = false;
        this.editDays = false;
        this.selectedId = null;
        this.selectedEpes = [];
        this.serviceName = '';
        this.description = '';
        this.effort = '';
        this.row = null;
    }

    openEditModal(row: any, editDays= false) {
        console.log(row)
        this.row = row
        this.isEdit = true;
        this.editDays = editDays;
        this.selectedId = row._id;
        this.selectedEpes = this.getEpes(row.epes);
        this.serviceName = row.name;
        this.description = row.description;
        this.effort = row.effort;
    }
    alertConfirmation(row: any) {
        const id = row._id['$oid']
        Swal.fire({
            title: "Are you sure you want to delete this service?",
            text: "This process is irreversible.",
            showDenyButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            denyButtonText: "Yes, delete it!",
            cancelButtonText: "No, let me think",
        }).then((result) => {
            if (result.isDenied) {
                this.deepDivesService.deleteDeepDive(id).subscribe((message) => {
                    if (message['message'] == 'Deepdive deleted!') {
                        this.deepDives = this.deepDives?.filter(deepDive => deepDive._id['$oid'] !== id) || []
                        Swal.fire({
                            icon: "success",
                            title: "Your application has been deleted",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Something went wrong",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                });
            }
        });
    }

    // getReport(row: any) {
    //     report =
    // }
    getReportString(report: any) {
        const reportName = report.file === '' ? 'Report not uploaded yet' : report.file
        const epeReport = this.epes?.find(epe => epe.id === report.epe_id)
        return epeReport?.name + ' ' + epeReport?.surname + ' - ' + epeReport?.email + ' - ' + reportName
    }

    downloadReport(report: any, row: any) {
        const reportName = report.file === '' ? 'Report not uploaded yet' : report.file
        const epeReport = this.epes?.find(epe => epe.id === report.epe_id)
        if (epeReport && report.file !== '') {
            return this.deepDivesService.downloadReport(row._id.$oid, report.epe_id)
        } else {
            return ''
        }
    }

    addAlert(message: string, type: Alert){

        this.alertMessage = message
        this.alertType = type
        this.showAlert = true

    }
}


