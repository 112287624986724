<report></report>
<div class="form-container">
    <div class="form-box">
        <h3>Congratulations for completing the service</h3>
        <p><strong>Remember to check the report (you can find it above) and submit it once it is completed</strong></p>
        <p>Going ahead we should assume the report submitted is in the final version</p>
        <div class="btn-layout">
            <button (click)="accept(true)" class="u-btn sop-btn">Complete the service</button>
        </div>
    </div>
</div>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>