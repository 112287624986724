import { Component, HostListener, OnInit } from '@angular/core';
import { Project } from 'app/models/project';
import { ProjectsService } from 'app/services/projects.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';

enum ProjectStatusFilterEnum {
  all,
  pending,
  eligible,
  not_eligible,
  proactive
} 
interface exportProject {
  [key: string]: string;
}
@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss', '../sop.component.scss']

})
export class ProjectsComponent implements OnInit{
  loading: boolean = true
  projects:Project[] | null = []
  selected:number = -1
  ProjectStatusFilter = ProjectStatusFilterEnum
  filter: ProjectStatusFilterEnum = ProjectStatusFilterEnum.all

  screenWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.screenWidth = window.innerWidth;
  }

   // Sorting properties
   sortBy: string = ""; 
   sortDirection: number = 1; 
 
   
   changeSort(property: string) {
     if (this.sortBy === property) {
       this.sortDirection = this.sortDirection * -1;
     } else {
       this.sortBy = property;
       this.sortDirection = 1; 
     }
     this.projects = this.sortProjects();
   }

  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  search ?: string 

  searchProject(search:string){

    if(!this.projects)
      return

    if(search.length > 2){
      this.logger.info(this.projects.filter(x => x.username!.includes(search)), "Search Projects")
      /*setTimeout(()=>{
        this.scrollToRow(this.getPlayers().filter(x => x.name.toLowerCase().includes(search.toLowerCase()))[0].id)
      }, 100)*/
      
    }

  }

  constructor(
    private service: SopService,
    private serviceMongo: ProjectsService, 
    private cache: SopStorage,
    private auth: SessionStorage,
    private logger: Logger
    ) { 

    }

  ngOnInit(): void {

    this.projects = this.cache.getProjects()
    if(!this.projects){
      this.service.getAll('project').subscribe(
        then => {
          this.projects = then
          console.log(this.projects)
          this.logger.info(this.projects)
          this.cache.setProjects(this.projects)
          this.loading = false
          
        }
      )
    } else this.loading = false
  }

  exportAuthorized(){
    return this.auth.isAdmin() || this.auth.isCallManager() || this.auth.isProjectBoard()
  }

  stripHtmlTags(content: string): string {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div.textContent || div.innerText || '';
  }

  exportCSV() {
    console.log(this.projects);
    let data = [];
    for (let project of this.projects!) {
      let newProject: exportProject = {};
      newProject['Version'] = project['version']!;
      newProject['ID'] = project['id']!;
      newProject['Applyed at'] = moment.unix(+project['created_at']!).format("MM/DD/YYYY");
      newProject['Username'] = project['username']!;
      newProject['Title'] = project['title'] ? this.stripHtmlTags(project['title']) : '';
      newProject['Name(s)'] = project['name']!;
      newProject['Surname'] = project['surname']!;
      newProject['Email'] = project['email']!;
      newProject['Website Publication'] = project['website_publication'] ? 'true' : 'false';
      newProject['Gender'] = project['gender'] ? project['gender'] : '';
      newProject['Country'] = project['country'] ? project['country'] : '';
      newProject['Organisation'] = project['organisation'] ? project['organisation'] : '';
      newProject['Organisation type'] = project['organisation_type']!;
      newProject['Phone Number'] = project['phone_number']!;
      newProject['Grant Agreement'] = project['grant_agreement'] ? project['grant_agreement'] : '';
      newProject['Project Acronym'] = project['project_acronym'] ? project['project_acronym'] : '';
      newProject['Full Project name'] = project['full_project_name'] ? project['full_project_name'] : '';
      newProject['Funding Programme'] = project['funding_programme'] ? project['funding_programme'] : '';
      newProject['Other Funding Programme'] = project['other_funding_programme'] ? project['other_funding_programme'] : '';
      newProject['Funding Programme Detail'] = project['funding_programme_detail'] ? project['funding_programme_detail'] : '';
      newProject['Project Call Topic ID'] = project['project_call_topic_id'] ? project['project_call_topic_id'] : '';
      newProject['Project Website'] = project['project_website'] ? project['project_website'] : '';
      newProject['Project Officer name'] = project['project_officer_name'] ? project['project_officer_name'] : '';
      newProject['Project short description'] = project['project_short_description'] ? this.stripHtmlTags(project['project_short_description']) : '';
  
      newProject['Objectives Standardisation'] = project['objectives_standardisation'] ? project['objectives_standardisation'] : '';
  
      newProject['Information or activity that you and your project would like to attain from the booster services'] = '';
      project['information_to_attain']!.forEach(info => {
        newProject['Information or activity that you and your project would like to attain from the booster services'] += this.stripHtmlTags(info) + '\n';
      });
  
      newProject['Technical Committee(s), Sub-committee(s) or Working Group(s) your project would like to contribute'] = project['which_group_contribute_to'] ? project['which_group_contribute_to'] : '';
  
      newProject['SOP Status'] = project['sop_status']!;
  
      newProject['Topics'] = '';
      for (const [firstLevelKey, firstLevelValue] of Object.entries(project['topics']!)) {
        newProject['Topics'] += firstLevelKey + '\n';
        for (const [secondLevelKey, secondLevelValue] of Object.entries(firstLevelValue)) {
          newProject['Topics'] += '\t' + secondLevelKey + '\n';
          secondLevelValue.forEach((thirdLevelKey: string) => {
            newProject['Topics'] += '\t\t' + thirdLevelKey + '\n';
          });
        }
      }
      newProject['Standards Knowledge'] = project['standard_knowledge'] ? 'true' : 'false';
  
      var index = 1;
      for (var index = 0; index < 5; index++) {
        let element = project['standard_reference']?  project['standard_reference']![index] : undefined
        newProject['Standard reference ' + index+1 + ' - SDO/NSB/NC'] = element? element['represent'] ? element['represent'] : '': '';
        newProject['Standard reference ' + index+1 + ' - Title of TC/SC/WG'] = element? element['title_sdo'] ? element['title_sdo'] : '': '';
        newProject['Standard reference ' + index+1 + ' - Number of TC/SC/WG'] = element? element['number_sdo'] ? element['number_sdo'] : '': '';
        newProject['Standard reference ' + index+1 + ' - Other options'] = element? element['other_options'] ? element['other_options'] : '': '';
        newProject['Standard reference ' + index+1 + ' - Wants to CONTRIBUTE'] = element? element['is_contribute'] ? 'true' : 'false': '';
        newProject['Standard reference ' + index+1 + ' - Wants to USE'] = element? element['is_use'] ? 'true' : 'false': '';
      };
  
      data.push(newProject);
    }
  
    var keys = [
      'Version',
      'ID',
      'Applyed at',
      'Username',
      'Title',
      'Name(s)',
      'Surname',
      'Email',
      'Website Publication',
      'Gender',
      'Country',
      'Organisation',
      'Organisation type',
      'Phone Number',
      'Grant Agreement',
      'Project Acronym',
      'Full Project name',
      'Funding Programme',
      'Project Call Topic ID',
      'Project Website',
      'Project Officer name',
      'Project short description',
      'Objectives Standardisation',
      'Information or activity that you and your project would like to attain from the booster services',
      'Technical Committee(s), Sub-committee(s) or Working Group(s) your project would like to contribute',
      'SOP Status',
      'Topics',
      'Standards Knowledge',
    ];
  
    for (var j = 1; j <= 5; j++) {
      keys.push('Standard reference ' + j + ' - SDO/NSB/NC');
      keys.push('Standard reference ' + j + ' - Title of TC/SC/WG');
      keys.push('Standard reference ' + j + ' - Number of TC/SC/WG');
      keys.push('Standard reference ' + j + ' - Other options');
      keys.push('Standard reference ' + j + ' - Wants to CONTRIBUTE');
      keys.push('Standard reference ' + j + ' - Wants to USE');
    }
  
    let csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Applications',
      useBom: true,
      noDownload: false,
      headers: keys,
    };
  
    new AngularCsv(data, 'Project-HSBooster', csvOptions);
  }

  changeFilter(newStatus: ProjectStatusFilterEnum){
    this.filter = newStatus
  }

  toIter(n:number):Array<any> {
    return Array(n).fill(0).map((x,i)=>i)
  }

  getBtnText(status:string = ''){

    switch(status){
      case 'pending':
        return 'Eligibility Check'
      case 'eligible':
        return 'View details'
      case 'not_eligible':
        return 'View details'
      case 'proactive':
        return 'View details'
      default:
        return 'error'
    }

  }

  getProjects():Project[]{
    if (!this.projects) return []
    if(this.search && this.search.length > 0)
      return this.projects.filter(x => x.username!.toLowerCase().includes(this.search!.toLowerCase()!) ||
      x.project_acronym!.toLowerCase().includes(this.search!.toLowerCase()!) ||
       x.name!.concat(' ', x.surname!).toLowerCase().includes(this.search!.toLowerCase()!))
    switch(this.filter){
      case ProjectStatusFilterEnum.all :
        return this.projects
      case ProjectStatusFilterEnum.pending :
        return this.projects.filter( x => x.sop_status == 'pending')
      case ProjectStatusFilterEnum.eligible :
        return this.projects.filter( x => x.sop_status == 'eligible')
      case ProjectStatusFilterEnum.not_eligible :
        return this.projects.filter( x => x.sop_status == 'not_eligible')
      case ProjectStatusFilterEnum.proactive :
        return this.projects.filter( x => x.sop_status == 'proactive')
      default:
        return this.projects
    }
  }

  getExpand(id:number = -1):string{

    if(id == this.selected)
      return 'Close details'
    return 'Open details'

  }

  getExpandImg(id:number = -1):string{

    if(id == this.selected)
      return 'reduce'
    return 'expand'

  }

  expand(id:number = -1){
    if(id == this.selected){
      this.selected = -1
      return 
    }
    this.selected = id
  }

  sortProjects(): Project[]{
    if (!this.projects) return []

    switch(this.sortBy){
      case 'name' :
        if(this.sortDirection == 1)
          return this.projects.sort((a,b) => a.name!.localeCompare(b.name!))
        else
          return this.projects.sort((a,b) => b.name!.localeCompare(a.name!))
      case 'surname' :
        if(this.sortDirection == 1)
          return this.projects.sort((a,b) => a.surname!.localeCompare(b.surname!))
        else
          return this.projects.sort((a,b) => b.surname!.localeCompare(a.surname!))
      case 'email' :
        if(this.sortDirection == 1)
          return this.projects.sort((a,b) => a.username!.localeCompare(b.username!))
        else
          return this.projects.sort((a,b) => b.username!.localeCompare(a.username!))
      case 'projectName' :
        if(this.sortDirection == 1)
          return this.projects.sort((a,b) => a.full_project_name!.trimStart().localeCompare(b.full_project_name!.trimStart()))
        else
          return this.projects.sort((a,b) => b.full_project_name!.trimStart().localeCompare(a.full_project_name!.trimStart()))
      case 'acronym' :
        if(this.sortDirection == 1)
          return this.projects.sort((a,b) => a.project_acronym!.trimStart().localeCompare(b.project_acronym!.trimStart()))
        else
          return this.projects.sort((a,b) => b.project_acronym!.trimStart().localeCompare(a.project_acronym!.trimStart()))
      
    }
    return this.projects
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }

  

  truncateFloat(n : number) : number {
    return Math.floor(n * 100) / 100
  }
  
}
