import { Component } from '@angular/core';
import {Router} from "@angular/router";

declare var $:any;

@Component({
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    constructor(private router: Router){}

    ngOnInit(){
        this.router.events.subscribe((event) => {
            if (this.router.url === '/'){
                $('.footer').css('position', 'absolute');
            } else {
                $('.footer').css('position', 'relative');
            }
        });
    }
}
