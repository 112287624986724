<div class="u-section-2 u-clearfix u-palette-1-base card-border">    
    <div class="u-layout sopcard"> 
      <div class="u-layout-col">        
        <div class="u-container-layout sopcard-title-section">            
          <h6 class="u-text sopcard-title">This is your personal space on the HSbooster.eu platform. You will need to enter this space to track your activities and report on the projects&rsquo; progress. Using the platform is important to monitor the outputs of the services and is requested for the correct completion of the assignments:</h6>
          <span (click)="accordionAction()" class="u-file-icon u-icon expand-icon">
            <img *ngIf="!opened" src="/assets/icons/expand.png" alt="">
            <img *ngIf="opened" src="/assets/icons/reduce.png" alt="">
          </span>          
        </div>        
        <div *ngIf="opened" class="u-container-layout sopcard-section">
          <div class="row">      
            <div class="col-md-12">
                <p>The service consists of minimum 2 and up to 4 meetings with your assigned project, to be carried out over a period of maximum 3 months.</p>
                <p>The two compulsory meetings are:</p>
                <ul>
                <li>First Meeting at the start of the service to discuss needs and expectations</li>
                <li>Final Meeting at the end of the service to comment on the activities carried out and suggest a future strategy</li>
                </ul>
                <p>The optional meetings are:</p>
                <ul>
                <li>Meetings between those two that you may need to formulate better your advice, gather additional information or investigate particular aspects that may be helpful for your reporting</li>
                </ul>
                <p>At the end of the first meeting you will be asked to confirm if you need one or more additional meetings before providing your advice on the activities to perform</p>
                <p>Please Remember that, as&nbsp; necessary steps to progress to one meeting to the next, you need to</p>
                <p>(1) schedule the calls with the project and insert the scheduled dates</p>
                <p>(2) fill in the <strong>activity report</strong> in all its sections by the end of the service</p>
                <p><strong>Filling in the report in all its sections is mandatory for the service to be considered completed and paid to you</strong>.</p>
                <p><strong>Please have a look at such report before starting!</strong></p>
                <p><strong><u>How to fill in the activity report?</u></strong></p>
                <p>The report is always available for you to fill in at any moment. It requires a set of information that you can typically collect before or during the first meeting with the project that help set the scene and the expectations for the service.</p>
                <p>The report also presents a list of activities among which you should select the most appropriate and suggest them to the project, also ticking those that are actually carried out during the service</p>
                <p>During the last meeting you are finally expected to guide the project towards the next steps and suggest a strategy to move forward after the service.</p>
                <p><strong><u>Useful materials and information</u></strong></p>
                <p>There is a set of useful materials and information you can refer to, beyond the activity report:</p>
                <ul>
                <li>Projects contacts to get in touch with a representative of the projects assigned to you</li>
                <li>PPT templates that you can use, if you like, for any presentations you would like to prepare for your meetings with the project</li>
                <li>Suggested e-mail templates for your communications with the project</li>
                </ul>
                <p>You can find these templates in the pages dedicated to each of your assigned projects</p>
                <p>You can now start! Please click on one of your projects below for further details </p>
                <p>&nbsp;</p>
            </div>      
            
          </div>            
        </div>        
      </div> 
    </div>  
  </div>

