import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyProjectMeetingComponent } from './survey-project-meeting.component';
import { OpenQuestionComponent } from '../form-steps/form-composer/components/open-question.component';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
  declarations: [
    SurveyProjectMeetingComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    SurveyProjectMeetingComponent
  ]
})
export class SurveyProjectMeetingModule { }
