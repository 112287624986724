import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectMongo } from 'app/models/project';

  
@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private url =  '/api/v1/projects/';
   
  constructor(private httpClient: HttpClient) { }
  
  getAll(): Observable<any []>{
    return this.httpClient.get<any []>( this.url); 
  }
  
  createProject(project: ProjectMongo): Observable<ProjectMongo>{
    
    return this.httpClient.post<ProjectMongo>( this.url, {project});
  }

  updateNote(id: string | null = null, note: string): Observable<string>{
    return this.httpClient.patch<string>(this.url + 'note', {id, note})
  }

  getNote(id: string | null = null): Observable<string>{
    return this.httpClient.get<string>( this.url + 'note/' + id );
  }

}