<div class="u-section-2 u-clearfix u-layout-wrap vote-container">
    <div *ngIf="this.project.sop_status == 'pending'" class="u-layout sopcard">
        <div class="u-layout-col">
            <div class="u-container-style u-layout-cell u-layout-cell-small-card">
                <h6 class="u-text u-text-small">Eligible</h6>
                <input (click)="change()" *ngIf="!checked && auth.isCallManager()" class="form-check-input" type="checkbox">
                <input (click)="change()" *ngIf="checked && auth.isCallManager()" class="form-check-input checked" type="checkbox">
            </div>
            <div>
                <label>This Project is in pending state, he will stay in this status until a Call Manager evaluates it.</label>
            </div>
            <div *ngIf="auth.isCallManager()">
                <button (click)="submitEligibility()" class="u-btn sop-btn">Submit</button>
            </div>
        </div>
    </div>
    <div *ngIf="this.project.sop_status == 'not_eligible'" class="u-layout" style="margin: 0 20px 0 20px;">
        <div class="u-layout-col">
            <div>
                <label>This Project has been evaluated Not Eligible.</label>
            </div>
        </div>
    </div>
    <div *ngIf="this.project.sop_status == 'eligible'" class="u-layout" style="margin: 0 20px 0 20px;">
        <div class="u-layout-col">
            <div>
                <label>This Project has been evaluated Eligible.</label>
            </div>
            <button (click)="moveProjectToProActive()" class="u-btn sop-btn">Move project to ProActive service</button>
        </div>
    </div>
    <div *ngIf="this.project.sop_status == 'proactive'" class="u-layout" style="margin: 0 20px 0 20px;">
        <div class="u-layout-col">
            <div>
                <label>This Project has been moved to ProActive.</label>
            </div>
            <button (click)="moveProjectToEligible()" class="u-btn sop-btn">Move project to Eligible</button>
        </div>
    </div>
    <div class="u-layout sopcard" *ngIf="this.project.sop_status != 'pending' && (auth.isCallManager() || auth.isProjectBoard())">
        <div>
            <div>
                <h6 class="u-text u-text-small">Take a note about the Project</h6>
            </div>
            <div>
                <form (ngSubmit)="submitNote()" #noteForm="ngForm">
                <div class="form-group">
                    <!-- <label for="note">Note for the EPE</label> -->
                    <textarea class="form-control" id="note"
                        [(ngModel)]="note" name="note" rows="5" cols="50">
                    </textarea>
                </div>
                <button type="submit" class="u-btn sop-btn">Submit note</button>
                </form>
            </div>
        </div>
    </div>
</div>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert" ></simple-alert>