import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { SessionStorage } from 'app/storage/session.storage';

@Component({
    // moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit{
    private listTitles: any[] = [];
    location?: Location;
    private toggleButton?: any;
    private sidebarVisible?: boolean;

    username:string = ''

    constructor(location: Location,  private element: ElementRef, 
        public cache: SessionStorage
        ) {
      this.location = location;
          this.sidebarVisible = false;
    }

    ngOnInit(){
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
      this.username = this.cache.getUsername()
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle(){
        
        if(this.location){            
            var titlee = this.location.prepareExternalUrl(this.location.path());        
            if(titlee.charAt(0) === '#'){            
                titlee = titlee.slice( 1 );        
            }        
            for(var item = 0; item < this.listTitles.length; item++){            
                if(this.listTitles[item].path === titlee){                
                    return this.listTitles[item].title;            
                }        
            }    
        }
      return 'Dashboard';
    }
}
