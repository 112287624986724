import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Epe, EpeMongo, Vote } from 'app/models/epe';

  
@Injectable({
  providedIn: 'root'
})
export class EpesService {
  private url =  '/api/v1/epes/';
  //private url = '/api/v1'
  
   
  constructor(private httpClient: HttpClient) { }
  
  getAll(): Observable<EpeMongo[]>{
    return this.httpClient.get<EpeMongo[]>( this.url); 
  }
  
  createEpe(epe: EpeMongo, evaluation: {roles:any, years:any}): Observable<EpeMongo>{
    
    return this.httpClient.post<EpeMongo>( this.url, {epe, evaluation});
  }

  updateNote(id: string | null = null, note: string): Observable<string>{
    return this.httpClient.patch<string>(this.url + 'note', {id, note})
  }

  getNote(id: string | null = null): Observable<string>{
    return this.httpClient.get<string>( this.url + 'note/' + id );
  }

  updateVote(standardisation: number, engagment: number, id: number, voter: string): Observable<string> {
    
    return this.httpClient.patch<string>( this.url + 'vote', {id, standardisation, engagment, voter });
  }

  getVote(id: number, username: string | null = null):Observable<Vote> {
    
    if (username)
      return this.httpClient.get<Vote>( this.url + 'vote/' + id + '/' + username);
    
    return this.httpClient.get<Vote>( this.url + 'vote/' + id );
  }

  getScore(id: number): Observable<{score:number, nvotes:number, engVariance: number, standVariance: number}> {
    
    return this.httpClient.get<{score:number, nvotes:number, engVariance: number, standVariance: number}>(this.url + 'score/' + id)
  }

}