import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FifthMeetingComponent } from "./fifth-meeting/fifth-meeting.component";
import { FirstMeetingComponent } from "./first-meeting/first-meeting.component";
import { FourthMeetingComponent } from "./fourth-meeting/fourth-meeting.component";
import { SecondMeetingComponent } from "./second-meeting/second-meeting.component";
import { SurveyComponent } from "./survey/survey.component";
import { ThirdMeetingComponent } from "./third-meeting/third-meeting.component";

const routes = [

    {
      path: '',
      redirectTo: 'meeting_4',
      pathMatch: 'full'
    },
  
    {
        path: 'meeting_1',
        component: FirstMeetingComponent,
        children: [{
          path: '',
          loadChildren:
            () => import('./first-meeting/first-meeting.routing.module')
            .then(m => m.FirstMeetingRoutingModule),
          outlet: 'meeting_1'
        }]
    },
  
    {
        path: 'meeting_2',
        component: SecondMeetingComponent,
        children: [{
          path: '',
          loadChildren:
            () => import('./second-meeting/second-meeting.routing.module')
            .then(m => m.SecondMeetingRoutingModule),
          outlet: 'meeting_2'
        }]
    },
  
    {
        path: 'meeting_3',
        component: ThirdMeetingComponent,
        children: [{
          path: '',
          loadChildren:
            () => import('./third-meeting/third-meeting.routing.module')
            .then(m => m.ThirdMeetingRoutingModule),
          outlet: 'meeting_3'
        }]
    },
  
    {
        path: 'meeting_4',
        component: FourthMeetingComponent,
        children: [{
          path: '',
          loadChildren:
            () => import('./fourth-meeting/fourth-meeting.routing.module')
            .then(m => m.FourthMeetingRoutingModule),
          outlet: 'meeting_4'
        }]
    },
    {
      path: 'meeting_5',
      component: FifthMeetingComponent,
      children: [{
        path: '',
        loadChildren:
          () => import('./fifth-meeting/fifth-meeting.routing.module')
          .then(m => m.FifthMeetingRoutingModule),
        outlet: 'meeting_5'
      }]
    },
    {
        path: 'survey-form',
        component: SurveyComponent,
        children: [{
          path: '',
          loadChildren:
            () => import('./survey/survey.routing.module')
            .then(m => m.SurveyRoutingModule),
          outlet: 'survey-form'
        }]
    }
    
  ]
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes),
    ],
    exports: [
      RouterModule
    ]
  })
  export class MeetingFormRoutingModule {
  
  }