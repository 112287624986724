import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Association } from 'app/models/association';
import { Epe } from 'app/models/epe';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { SessionStorage } from 'app/storage/session.storage';
import { Logger } from 'app/utilities/logger';
import { catchError, EMPTY } from 'rxjs';

@Component({
  selector: 'association-sopcard-small',
  templateUrl: './association-sopcard-small.component.html',
  styleUrls: ['./association-sopcard-small.component.scss', '../sopcard.component.scss']
})
export class AssociationSopcardSmallComponent implements OnInit, AfterViewInit {

  @Input() epes: Epe[] | null = null
  @Input() association_status: string = 'open'
  @Input() project_id: string = ''

  loading: boolean = true

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  //expanded: boolean = false

  private _expanded = false;
  get expanded(): boolean {
    return this._expanded;
  }
  set expanded(value: boolean) {
    if (!value) {
      setTimeout(() => {
        this.setFocusOnShowMoreButton();
      });
    }
    this._expanded = value;
  }


  constructor(
    private logger: Logger,
    public auth: SessionStorage,
    private helper: AssociationHelper,
    private service: AssociationsService,
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    this.logger.info(this.epes, "Associated epes")
  }

  ngOnInit(): void { 
  }

  setFocusOnShowMoreButton(): void {
    const showMoreButton = document.getElementById('showMoreButton'+this.project_id);
    if (showMoreButton) {
      showMoreButton.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  getEpes(): Epe[]{
    if(!this.epes)
      return []
    return this.epes.map(x =>{ x['level']=this.getLevel(x); return x }).sort(compareEpes)
  }

  getLevel(epe: Epe): string{

    if(epe['high'] > 0)
      return '3rd'
    else if(epe['middle'] > 0)
      return '2nd'
    else if(epe['low'] > 0)
      return '1st'
    
    return 'no-matching'
  }

  submitEligibility(): void{
    
      this.logger.info(`Epe id: ${this.helper.getSelectedEpe()}, project id: ${this.helper.getSelectedProject()}`, "Submit Eligibility")
      let role = 'project_board'
      let project_id = this.helper.getSelectedProject()
      let epe_id = this.helper.getSelectedEpe() 
      if(this.auth.isCallManager())
        role = 'call_manager'
      this.service.submitAssociation(project_id, epe_id, role)
      .pipe(
        catchError(
          err => {
            this.addAlert("Association creation failed! Please contact the administrator", Alert.ERROR)
            return EMPTY
          }
        )
      )
      .subscribe(
        then => {
          this.addAlert("Successfully created Association", Alert.SUCCESS)
          if(this.auth.isProjectBoard())
            this.association_status = 'identified'
          if(this.auth.isCallManager())
            this.association_status = 'selected'
          this.helper.setAssociation(project_id, epe_id, this.association_status)
        }
      )
     
  }

  submitEpeResponse(accept: boolean): void{

    let project_id = this.helper.getSelectedProject()
    let epe_id = this.helper.getActiveEpe() 
    this.service.submitAssociationEpe(project_id, epe_id, accept).pipe(
      catchError(
        err => {this.addAlert("Something went wrong. Please contact the administrator", Alert.ERROR); return EMPTY}
    )
    ).subscribe(
      then => {
        this.addAlert("Answer submitted correctly", Alert.SUCCESS)
        if(accept)
          this.association_status = 'approved'
        else
          this.association_status = 'not_approved'
        this.helper.setAssociation(project_id, epe_id, this.association_status)
      }
    )
    
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }

  closeService(): void {
    this.service.closeAssociation(this.helper.getSelectedProject(),this.helper.getSelectedEpe()).pipe(
      catchError(
        err => {this.addAlert("Something went wrong. Please contact the administrator", Alert.ERROR); return EMPTY}
    )
    ).subscribe(
      then => {
        this.addAlert("Association closed correctly", Alert.SUCCESS)
      }
    )
  }

  goToMeetingForm(): void {
    
    console.log(this.helper.getMeetingForm())
    let form = this.helper.getMeetingForm()
    if(this.auth.isProject() && form <= 1){
      this.addAlert("Please wait until the Epe complete at least one form", Alert.WARNING)
      return
    }
    if(form === 0 || !form)
      form = 1
    form = this.auth.isProject() && form < 7 ? form - 1 : form
    console.log(form)
    let route = this.auth.isProject() ? 'summary' : 'first'
    if(form < 6){
      console.log("There")
      this.router.navigateByUrl(`form/(meeting-form:meeting_${form}/(meeting_${form}:${route}))`)
    }
    else if(this.auth.isProjectBoard() || this.auth.isCallManager() || this.auth.isAdmin()){
      this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:admin))`)
    }
    else if(form == 6){
      this.router.navigateByUrl(`form/(meeting-form:survey-form)`)
    }
    else{
      console.log("here")
      this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:summary))`)
    }
  }
  
  

}

function compareEpes(a: Epe, b: Epe): number{
  if(a.association_status != "open" && a.association_status != "waiting"){
    return -1
  }
  if(b.association_status != "open" && b.association_status != "waiting"){
    return 1
  }

  // if(a.score && b.score && b.score - a.score > 2)
  //   return 1

  let high_a = a['high']
  let middle_a = a['middle']
  let low_a = a['low']
  
  let high_b = b['high']
  let middle_b = b['middle']
  let low_b = b['low']

  if(high_a != 0 && high_b == 0)
    return -1
  else if(high_a == 0 && high_b != 0)
    return 1
  else if(middle_a != 0 && middle_b == 0)
    return -1
  else if(middle_a == 0 && middle_b != 0)
    return 1
  else if(low_a != 0 && low_b == 0)
    return -1
  else if(low_a == 0 && low_b != 0)
    return 1

  // if(high_a != high_b)
  //   return high_b - high_a
  // else if(middle_a != middle_b)
  //   return middle_b - middle_a
  // else if(low_a != low_b)
  //   return low_b - low_a
  /**
   * else if(voteComparasion)
   *  return true
   */
  if(a.score && b.score && a.score != b.score)
    return b.score - a.score
  if(a.nvotes && b.nvotes && a.nvotes != b.nvotes)
    return b.nvotes - a.nvotes

  
  return 0
}
