<div class="form-container">
    <div class="form-box">
        <div *ngFor="let question of questions; let i = index">
            <h3 *ngIf="auth.isProject() && i == 0">Expert</h3>
            <h3 *ngIf="auth.isProject() && i == 4">Expert recommendations</h3>
            <h3 *ngIf="auth.isProject() && i == 10">HSbooster Support</h3>
            <h3 *ngIf="auth.isProject() && i == 12">Referral</h3>
            <open-question *ngIf="isOpenQuestion(question)" [type]="'survey'" [question]="getQuestionKey(question)" [editor]="getQuestion(question)"></open-question>
            <tick-box *ngIf="!isOpenQuestion(question) && !isDropdown(question)" [fields]="question" [type]="'survey'"></tick-box>

            <div *ngIf="isDropdown(question)">
                <h3>Tell us which modules you have accessed:</h3>
                    <div *ngFor="let level of levels; let i = index" class="panel-group" id="accordion_{{ level }}st">
                        <div class="panel panel-default">
                            <div class="panel-heading" (click)="expand(level)"> 
                                <h6 class="panel-title" style="display: flex; align-items: baseline; cursor: pointer;">
                                    {{ level }}:
                                </h6>
                            </div>
                            <div [id]="'collapse_' + level" class="panel-collapse collapse {{ expandedStatus(level) }}">
                                <div class="panel-body">
                                    <tick-box [fields]="getQuestionsByLevel(level)" [type]="'survey'"></tick-box>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <button *ngIf="toSubmit" (click)="confirm()" class="u-btn sop-btn">Save</button>
    </div>
</div>



<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>