import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

/**
 * Logger Helper class to print formatted info
 * usage:   
 *       constructor( ..., private logger: Logger){
 *          ...
 *          this.logger.info(value, title) // title param is optional
 *       }
 * Note: only import in constructor is needed!
 */

@Injectable()
export class Logger {

    constructor(private datePipe: DatePipe){

    }

    private isIterable(value: any): boolean {
        return typeof value === 'object' || typeof value === 'function'
    }

    private getCurrentDateTime(): string | null {

        return this.datePipe.transform(Date.now(),"MM-dd-yy h:mm:ss a")
    }

    info(message: any, title: string | null = null) : void {

        if(this.isIterable(message)){
            console.log(`[${this.getCurrentDateTime()}] INFO: ${title ? title : '' }`)
            console.log(message)
        }
        else
            console.log(`[${this.getCurrentDateTime()}] INFO: ${title ? title + ' ' : '' }${message}`)
 
    }
    warn(message: any, title: string | null = null) : void {

        if(this.isIterable(message)){
            console.warn(`[${this.getCurrentDateTime()}] WARNING: ${title ? title : '' }`)
            console.warn(message)
        }
        else
            console.warn(`[${this.getCurrentDateTime()}] WARNING: ${message}`)

    }
    error(message: any, title: string | null = null) : void {

        if(this.isIterable(message)){
            console.error(`[${this.getCurrentDateTime()}] ERROR: ${title ? title : '' }`)
            console.error(message)
        }
        else
            console.error(`[${this.getCurrentDateTime()}] ERROR: ${title ? title + ' ' : '' }${message}`)

    }
    
}