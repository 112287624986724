import {Component, OnInit} from '@angular/core';
import {DeepDivesService} from "../../services/deep_dives.service";
import {DeepDive} from "../../models/deep_dive";
import {SessionStorage} from "../../storage/session.storage";
import {SopService} from "../../services/sop.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Alert} from "../../shared/alerts/simple-message/simple-message.component";

@Component({
    selector: 'assigned-deep-dive',
    templateUrl: './assigned-deep-dive.component.html',
    styleUrls: ['./assigned-deep-dive.component.scss', '../sop.component.scss']
})
export class AssignedDeepDiveComponent implements OnInit {
    assignedDeepDives: any[] = [];
    epes: any[] = [];
    loading: boolean = true;
    selectedDeepDive: DeepDive = new DeepDive();
    report: File | undefined = undefined;

    // alert variables

    showAlert: boolean = false
    alertMessage: string = ''
    alertType: Alert = Alert.SUCCESS

    constructor(
        private deepDivesService: DeepDivesService,
        private sopService: SopService,
        public auth: SessionStorage,
    ) {
    }

    ngOnInit(): void {
        this.sopService.getAll('epe').subscribe((epes: any[]) => {
            this.epes = epes;
            this.deepDivesService.getAll().subscribe((data: DeepDive[]) => {
                this.assignedDeepDives = data.filter((deepDive: DeepDive) => {
                    return deepDive.status != "DRAFT" && deepDive.type == "DD" && deepDive.epes?.find((epe: any) => {
                        return epe.id === this.epes.find((epe: any) => epe.username === this.auth.username)?.id;
                    });
                });
                this.loading = false;
            });
        });
    }

    getEpes(epes: any) {

        return epes.map((epe: any) => {
            let tmpEpe = this.epes?.find(e => e.id === epe.id)
            tmpEpe.status = epe.status
            return tmpEpe
        });
    }

    loadModalData(element: any) {
        this.selectedDeepDive = element;
    }

    approve(selectedDeepDive: any) {
        const newDeepDive = {
            ...selectedDeepDive,
            // @ts-ignore
            epes: selectedDeepDive.epes.map((epe: any) => {
                if (epe.id === this.epes.find((epe: any) => epe.username === this.auth.username)?.id) {
                    return {
                        ...epe,
                        status: 'APPROVED'
                    }
                } else {
                    return epe;
                }
            })
        }

        this.deepDivesService.updateDeepDive(newDeepDive).subscribe((data: any) => {
            console.log(data);
            if (data.message === "Deep dive updated!") {
                this.addAlert('Deep Dive Approved', Alert.SUCCESS)
            } else {
                this.addAlert('Error approving deep dive', Alert.ERROR)
            }
        });

        this.assignedDeepDives = this.assignedDeepDives.map((deepDive: any) => {
            if (deepDive._id.$oid === newDeepDive._id.$oid) {
                return newDeepDive;
            } else {
                return deepDive;
            }
        });

    }

    reject(selectedDeepDive: any) {
        const newDeepDive = {
            ...selectedDeepDive,
            // @ts-ignore
            epes: selectedDeepDive.epes.map((epe: any) => {
                if (epe.id === this.epes.find((epe: any) => epe.username === this.auth.username)?.id) {
                    return {
                        ...epe,
                        status: 'NOT_APPROVED'
                    }
                } else {
                    return epe;
                }
            })
        }

        this.deepDivesService.updateDeepDive(newDeepDive).subscribe((data: any) => {
            console.log(data);
            if (data.message === "Deep dive updated!") {
                this.addAlert('Deep Dive Rejected', Alert.SUCCESS)
            } else {
                this.addAlert('Error rejecting deep dive', Alert.ERROR)
            }
        });

        this.assignedDeepDives = this.assignedDeepDives.map((deepDive: any) => {
            if (deepDive._id.$oid === newDeepDive._id.$oid) {
                return newDeepDive;
            } else {
                return deepDive;
            }
        });

    }

    getEpeString(epe: any): string {
        let str = epe.name + ' ' + epe.surname + ' - ' + epe.email;
        if (epe.status === 'PENDING') {
            str += ' (PENDING)'
        } else if (epe.status === 'APPROVED') {
            str += ' (APPROVED)'
        } else {
            str += ' (NOT APPROVED)'
        }
        return str;
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];
        if (file) {
            // You can perform actions with the selected file here
            console.log('Selected file:', file);
            this.report = file;
        }
    }

    uploadReport(row: any) {
        const formData = new FormData();
        formData.append('file', this.report!!, this.report!!.name);
        console.log(this.report);
        console.log(formData);
        this.deepDivesService.uploadReport(row._id.$oid, this.epes.find((epe: any) => epe.username === this.auth.username)?.id, formData).subscribe((data: any) => {
            console.log(data);
            if(data.message === "File uploaded!") {
                this.addAlert('Report uploaded', Alert.SUCCESS)
                this.assignedDeepDives = this.assignedDeepDives.map((deepDive: any) => {
                    if (deepDive._id.$oid === row._id.$oid) {
                        deepDive.report.find((report: any) => report.epe_id === this.epes.find((epe: any) => epe.username === this.auth.username)?.id).file = this.report?.name;
                        return deepDive;
                    } else {
                        return deepDive;
                    }
                });
            } else {
                this.addAlert('Error uploading report', Alert.ERROR)
            }
        });
    }

    getReport(row: any) {
        const report = row.report.find((report: any) => report.epe_id === this.epes.find((epe: any) => epe.username === this.auth.username)?.id);
        return report?.file;
    }

    downloadReport(row: any) {
        return this.deepDivesService.downloadReport(row._id.$oid, this.epes.find((epe: any) => epe.username === this.auth.username)?.id)
    }

    deleteReport(row: any) {
        this.deepDivesService.deleteReport(row._id.$oid, this.epes.find((epe: any) => epe.username === this.auth.username)?.id).subscribe((data: any) => {
            console.log(data);
            if (data.message === "File deleted!") {
                this.addAlert('File deleted', Alert.SUCCESS)
                this.assignedDeepDives = this.assignedDeepDives.map((deepDive: any) => {
                    if (deepDive._id.$oid === row._id.$oid) {
                        deepDive.report.find((report: any) => report.epe_id === this.epes.find((epe: any) => epe.username === this.auth.username)?.id).file = '';
                        return deepDive;
                    } else {
                        return deepDive;
                    }
                });
            } else {
                this.addAlert('Error deleting file', Alert.ERROR)
            }
        });
    }

    addAlert(message: string, type: Alert){

        this.alertMessage = message
        this.alertType = type
        this.showAlert = true

    }

    getSelfStatus(epes: any) {
        if (epes.find((epe: any) => epe.username === this.auth.username).status === 'APPROVED') {
            return 'APPROVED';
        } else if (epes.find((epe: any) => epe.username === this.auth.username).status === 'NOT_APPROVED') {
            return 'NOT APPROVED';
        } else {
            return 'PENDING';
        }
    }
}
