import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Logger } from 'app/utilities/logger';
import { Observable } from 'rxjs';
import { SessionStorage } from '../storage/session.storage'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: SessionStorage,
    private logger: Logger
    ){

    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.logger.info(`Navigate to '${state.url}'`, "Auth Guard")
    return this.auth.canNavigate(state.url)
  }
  
}
