import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'first-meeting-first-step',
  templateUrl: './first-meeting-first-step.component.html',
  styleUrls: ['../../meeting-form.component.scss']
})
export class FirstMeetingFirstStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
