import {MongoModel} from "./db.model";


export class DeepDive extends MongoModel {
    [index: string] :any
    name?: string
    description?: string
    authorUsername?: string
    type?: Type
    epes?: [
        {
            id: string,
            status: string,
        }
    ]
    status?: string
    effort?: string
    report?: [
        {
            epe_id: string,
            file: string,
        }
    ]

    constructor(){
        super()
    }

}

enum Type {
    DD = 'DD',
    CWA = 'CWA',
}