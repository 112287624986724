import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { catchError, EMPTY, fromEventPattern, lastValueFrom } from 'rxjs';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { MeetingFormService } from '../meeting-form.service';
import { SessionStorage } from 'app/storage/session.storage';
@Component({
  selector: 'survey-project-advice',
  templateUrl: './survey-project-advice.component.html',
  styleUrls: ['../meeting-form.component.scss']
})
export class SurveyProjectAdviceComponent implements OnInit {

  form: FormGroup
  loading: boolean = true

  questions: Question[];
  title: string; 

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  toSubmit: boolean = true
  summary: {[index:string]:any} = {}
  read_only: boolean = false

  constructor(
    private fb: FormBuilder,
    private formService: MeetingFormService,
    private mongoService: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    private meetingService: MeetingFormService,
    private auth: SessionStorage,
  ) { 
    //this.formService.clear() 
    this.form = this.fb.group({
      "Do you agree with the received advice?": ['', Validators.required],
      "Do you think you will be able to implement all the activities?": ['', Validators.required]
    })
    this.formService.setProjectAdviceForm(this.form)

    try{
      let data = require(`assets/meeting-form/survey_project_advice.json`)
      this.questions = data['questions']
      this.title = data['title']
    } catch (e){
      console.log(e)
      this.questions = []
      this.title = ''
    }
  }

  async ngOnInit() {
    let response = await lastValueFrom(this.mongoService.getProjectAdviceForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    console.log(response)
    if(!response){
      this.loading = false
      return
    }
    let form: { [key: string]: any; } = response['project_survey_advice']
    this.formService.updateProjectAdviceForm(form)
    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false

    this.summary = this.meetingService.getProjectAdviceFormSubmit()

    if(this.summary){
      this.read_only = true
    }
    else{
      this.read_only = false
    }
  }

  isOpenQuestion(question: Question) {
    return typeof question[this.getQuestionKey(question)] === 'string'
  }

  getQuestionKey(question: Question): string{
    return Object.keys(question)[0]
  }

  getQuestion(question: Question): string[] | string{
    return Object.values(question)[0]
  }

  getQuestionDescription(question: Question): string[] | string{
    return question["description"] ? question["description"] : ""
  }

  getKeys(): any[] {
    //console.log(this.summary)
    return Object.keys(this.summary)
  }

  confirm(): void{
    this.mongoService.submitProjectAdviceForm(
      this.helper.getSelectedProject(), 
      this.helper.getSelectedEpe(), 
      this.formService.getProjectAdviceFormSubmit()
      )
      .pipe(
        catchError(
          err => {
            this.addAlert("Advice meeting survey update failed! Please contact the administrator", Alert.ERROR)
            return EMPTY
          }
        )
      )
      .subscribe(
        then => {
          console.log("SUBMIT Advice meeting survey")
          this.addAlert("Advice meeting survey succesfully updated", Alert.SUCCESS)
          this.read_only = true
          this.summary = this.formService.getProjectAdviceFormSubmit()
        }
      )
  }


  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

}

export interface Question {
  [key:string]: string[] | string
}
