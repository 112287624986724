import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'third-meeting-second-step',
  templateUrl: './third-meeting-second-step.component.html',
  styleUrls: ['../../meeting-form.component.scss']
})
export class ThirdMeetingSecondStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
