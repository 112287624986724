import { Injectable } from "@angular/core";
import { AssociationMongo } from "app/models/association";

@Injectable({
    providedIn: 'root'
  })
export class AssociationHelper {
    selectedEpe: number = -1
    selectedProject: number = -1

    activeEpe: number = -1
    activeProject: number = -1

    MEETING_FORM?: number 

    associations: AssociationMongo[] | null = null

    constructor() {

    }

    clear(){
        this.selectedEpe = -1
        this.selectedProject = -1
        this.activeEpe = -1
        this.MEETING_FORM = 0
    }

    setMeetingForm(meeting: number): void{

        this.MEETING_FORM = meeting

    }

    getMeetingForm(): number{
        return this.MEETING_FORM || 0
    }

    setAssociations(associations: AssociationMongo[]): void{
        this.associations = associations
    }

    getAssociations(): AssociationMongo[] | null{
        return this.associations
    }

    setAssociation(project_id: number, epe_id: number, role: string): void{
        if(!this.associations)
            return
        let index = this.associations.findIndex( x => x.project_id == project_id && x.epe_id == epe_id)
        this.associations[index].association_status = role
    }

    getAssociation(project_id: number, epe_id: number): AssociationMongo | undefined {
        if(!this.associations)
            return undefined
        return this.associations.find( x => x.project_id == project_id && x.epe_id == epe_id)
    }

    getActiveEpe(): number {
        return this.activeEpe
    }

    setActiveEpe(epe_id: number): void {
        console.log("££££££££££££££££££££££££££££")
        this.activeEpe = epe_id
    }

    getActiveProject(): number {
        return this.activeProject
    }

    setActiveProject(project_id: number): void {
        this.activeProject = project_id
    }

    getSelectedEpe(): number {
        return this.selectedEpe
    }

    isEpeSelected(epe_id: number): boolean {
        return epe_id === this.selectedEpe
    }

    setSelectedEpe(epe_id: number): void {
        
            this.selectedEpe = epe_id
    }

    getSelectedProject(): number {
        return this.selectedProject
    }

    isProjectSelected(project_id: number): boolean {
        return project_id === this.selectedProject
    }

    setSelectedProject(project_id: number): void {
        if(!this.isProjectSelected(project_id))
            this.selectedProject = project_id
    }
}