import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Epe, Vote } from 'app/models/epe';
import { EpesService } from 'app/services/epes.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';
import { catchError, lastValueFrom, of } from 'rxjs';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

enum EpeStatusFilterEnum {
  all,
  pending,
  eligible,
  not_eligible,
  voted
} 
interface exportEpe {
  [key: string]: string;
}
@Component({
  selector: 'app-epes',
  templateUrl: './epes.component.html',
  styleUrls: ['./epes.component.scss', '../sop.component.scss']

})
export class EpesComponent implements OnInit{
  loading: boolean = true
  epes:Epe[] | null = []
  selected:number = -1
  EpeStatusFilter = EpeStatusFilterEnum
  filter: EpeStatusFilterEnum = EpeStatusFilterEnum.all

  screenWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.screenWidth = window.innerWidth
  }

   // Sorting properties
   sortBy: string = ""; 
   sortDirection: number = 1; 
 
   
   changeSort(property: string) {
     if (this.sortBy === property) {
       this.sortDirection = this.sortDirection * -1;
     } else {
       this.sortBy = property;
       this.sortDirection = 1; 
     }
     this.epes = this.sortEpes();
   }
  
  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  avg : number = 0

  voteChangeDetectorValue : Vote | null = null

  search ?: string 
  constructor(
    private service: SopService,
    private serviceMongo: EpesService, 
    private cache: SopStorage,
    private auth: SessionStorage,
    private logger: Logger,
    private router: Router
    ) { 

    }

  ngOnInit(): void {

      if(this.auth.isEpe() && !this.auth.isAdmin() && !this.auth.isCallManager() && !this.auth.isProjectBoard())
        this.router.navigate(['associations'])
        
  
      this.epes = this.cache.getEpes()
      if(!this.epes){
        this.service.getAll('epe').subscribe(
          then => {
            this.epes = then
            
            this.loading = false
            this.epes.forEach(
              epe => epe.sop_status == 'voted' || epe.sop_status == 'eligible' ? this.updateEpeScore(epe) : null
            )
  
            this.logger.info(this.epes)
            this.cache.setEpes(this.epes)
            console.log(this.epes)
          }
        )
      } else {
        this.epes.forEach(
          epe => epe.sop_status == 'voted' || epe.sop_status == 'eligible' ? this.updateEpeScore(epe) : null
        )
        this.loading = false
      }
    }

  exportAuthorized(){
    return this.auth.isAdmin() || this.auth.isCallManager() || this.auth.isProjectBoard()
  }

  stripHtmlTags(content: string): string {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div.textContent || div.innerText || '';
  }
 
  exportCSV() {
    console.log(this.epes);
    let data = [];
    for (let epe of this.epes!) {
      let newEpe: exportEpe = {};
      newEpe['Version'] = epe['version']!;
      newEpe['ID'] = epe['id']!;
      newEpe['Username'] = epe['username']!;
      newEpe['Title'] = epe['title'] ? this.stripHtmlTags(epe['title']) : '';
      newEpe['Name(s)'] = epe['name']!;
      newEpe['Surname'] = epe['surname']!;
      newEpe['Email'] = epe['email']!;
      newEpe['Website Publication'] = epe['website_publication'] ? 'true' : 'false';
      newEpe['Gender'] = epe['gender'] ? epe['gender'] : '';
      newEpe['Country'] = epe['country'] ? epe['country'] : '';
      newEpe['Organisation'] = epe['organisation'] ? epe['organisation'] : '';
      newEpe['Organisation type'] = epe['organisation_type']!;
      newEpe['Expertise in Standardisation'] = epe['more_details'] ? this.stripHtmlTags(epe['more_details']) : '';
      newEpe['CV'] = epe['cv'] ? 'https://hsbooster.eu' + epe['cv']! : '';
      newEpe['LinkedIN address'] = epe['linkedin'] ? epe['linkedin'] : '';
      newEpe['SOP Status'] = epe['sop_status']!;
      newEpe['N Votes'] = epe['nvotes'] ? epe['nvotes']!.toString() : '';
      newEpe['Score'] = epe['score'] ? epe['score']!.toString() : '';

      newEpe['Topics'] = '';
      for (const [firstLevelKey, firstLevelValue] of Object.entries(epe['topics']!)) {
        newEpe['Topics'] += firstLevelKey + '\n';
        for (const [secondLevelKey, secondLevelValue] of Object.entries(firstLevelValue)) {
          newEpe['Topics'] += '\t' + secondLevelKey + '\n';
          secondLevelValue.forEach((thirdLevelKey: string) => {
            newEpe['Topics'] += '\t\t' + thirdLevelKey + '\n';
          });
        }
      }

      newEpe['Has Expertise in standardisation?'] = epe['has_experience'] ? 'true' : 'false';

      // epe['standardisation_roles']!.forEach(element => {

      for (var index = 0; index < 10; index++) {
        let element = epe['standardisation_roles']![index]
        newEpe['Expertise ' + index+1 + ' - Role'] = element? element['role'] ? element['role'] : '' : '';
        newEpe['Expertise ' + index+1 + ' - Years'] = element? element['years'] ? element['years'] : '' : '';
        newEpe['Expertise ' + index+1 + ' - SDO/NSB/NC'] = element? element['represent'] ? element['represent'] : '' : '';
        newEpe['Expertise ' + index+1 + ' - Other Represention'] = element? element['other_represent'] ? element['other_represent'] : '' : '';
        newEpe['Expertise ' + index+1 + ' - Activities involved'] = element? element['details'] ? element['details'] : '' : '';
        newEpe['Expertise ' + index+1 + ' - Title of TC/SC/WG'] = element? element['title_sdo'] ? element['title_sdo'] : '' : '';
        newEpe['Expertise ' + index+1 + ' - Number of TC/SC/WG'] = element? element['number_sdo'] ? element['number_sdo'] : '' : '';
      };

      newEpe['Current Position'] = epe['position'] ? epe['position'] : '';
      newEpe['Current Position - Start date'] = epe['position_dates'] ? epe['position_dates'] : '';

      var index = 0;
      for (var index = 0; index < 5; index++) {
        let element =  epe['previous_positions']? epe['previous_positions'][index] : undefined
        newEpe['Previous Position ' + index+1 + ' - From'] = element? element['from'] ? element['from'] : '' : '';
        newEpe['Previous Position ' + index+1 + ' - To'] = element? element['to'] ? element['to'] : '' : '';
        newEpe['Previous Position ' + index+1 + ' - Employer'] = element? element['employer'] ? element['employer'] : '' : '';
        newEpe['Previous Position ' + index+1 + ' - Position held'] = element? element['position_held'] ? element['position_held'] : '' : '';
      };

      var index = 0;
      console.log(epe['academic_qualifications'])
      for (var index = 0; index < 5; index++) {
        let element =  epe['academic_qualifications']? epe['academic_qualifications'][index] : undefined
        newEpe['Academic qualification ' + index+1 + ' - From'] = element? element['from'] ? element['from'] : '' : '';
        newEpe['Academic qualification ' + index+1 + ' - To'] = element? element['to'] ? element['to'] : '' : '';
        newEpe['Academic qualification ' + index+1 + ' - Higher education institution'] = element? element['higher_education'] ? element['higher_education'] : '' : '';
        newEpe['Academic qualification ' + index+1 + ' - Academic qualification'] = element? element['academic_qualification'] ? element['academic_qualification'] : '' : '';
      };

      newEpe['Tell us more about yourself'] = epe['tell_us_more'] ? this.stripHtmlTags(epe['tell_us_more']) : '';

      data.push(newEpe);
    }

    var keys = [
      'Version',
      'ID',
      'Username',
      'Title',
      'Name(s)',
      'Surname',
      'Email',
      'Website Publication',
      'Gender',
      'Country',
      'Organisation',
      'Organisation type',
      'Expertise in Standardisation',
      'CV',
      'LinkedIN address',
      'SOP Status',
      'N Votes',
      'Score',
      'Topics',
      'Has Expertise in standardisation?',
    ];

    for (var j = 1; j <= 10; j++) {
      keys.push('Expertise ' + j + ' - Role');
      keys.push('Expertise ' + j + ' - Years');
      keys.push('Expertise ' + j + ' - SDO/NSB/NC');
      keys.push('Expertise ' + j + ' - Other Represention');
      keys.push('Expertise ' + j + ' - Activities involved');
      keys.push('Expertise ' + j + ' - Title of TC/SC/WG');
      keys.push('Expertise ' + j + ' - Number of TC/SC/WG');
    }

    keys.push('Current Position');
    keys.push('Current Position - Start date');

    for (var j = 1; j <= 5; j++) {
      keys.push('Previous Position ' + j + ' - From');
      keys.push('Previous Position ' + j + ' - To');
      keys.push('Previous Position ' + j + ' - Employer');
      keys.push('Previous Position ' + j + ' - Position held');
    }

    for (var j = 1; j <= 5; j++) {
      keys.push('Academic qualification ' + j + ' - From');
      keys.push('Academic qualification ' + j + ' - To');
      keys.push('Academic qualification ' + j + ' - Higher education institution');
      keys.push('Academic qualification ' + j + ' - Academic qualification');
    }

    keys.push('Tell us more about yourself');

    let csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Applications',
      useBom: true,
      noDownload: false,
      headers: keys,
    };

    new AngularCsv(data, 'EPE-HSBooster', csvOptions);
  }

  searchEpe(search:string){

    if(!this.epes)
      return

    if(search.length > 2){
      this.logger.info(this.epes.filter(x => x.username!.includes(search)), "Search Epes")
      /*setTimeout(()=>{
        this.scrollToRow(this.getPlayers().filter(x => x.name.toLowerCase().includes(search.toLowerCase()))[0].id)
      }, 100)*/
      
    }

  }

  voteChangeDetector(vote: Vote){

    this.logger.info(vote, "Vote change detector:")
    this.voteChangeDetectorValue = vote


  }
  
  changeFilter(newStatus: EpeStatusFilterEnum){
    this.filter = newStatus
  }

  toIter(n:number):Array<any> {
    return Array(n).fill(0).map((x,i)=>i)
  }

  isVoted(epe: Epe): boolean{
    if(!epe.score)
      return false
    return true

  }

  async updateEpeScore(epe: Epe){
    
    let scores = await lastValueFrom(this.serviceMongo.getScore(epe.id), {defaultValue: null})
    if(!scores)
      return
    epe.score = scores.score
    epe.nvotes = scores.nvotes
    epe.engVariance = scores.engVariance
    epe.standVariance = scores.standVariance

  }

  getBtnText(status:string = ''){

    switch(status){
      case 'pending':
        return 'Eligibility Check'
      case 'eligible':
        return 'Vote'
      case 'not_eligible':
        return 'View details'
      case 'voted':
        return 'View details'
      default:
        return 'error'

    }

  }

  getEpes():Epe[]{
    if (!this.epes) return []
    if(this.search && this.search.length > 0)
      return this.epes.filter(x => x.username!.toLowerCase().includes(this.search!.toLowerCase()) ||
       x.name!.concat(' ', x.surname!).toLowerCase().includes(this.search!.toLowerCase()))
    switch(this.filter){
      case EpeStatusFilterEnum.all :
        return this.epes
      case EpeStatusFilterEnum.pending :
        return this.epes.filter( x => x.sop_status == 'pending')
      case EpeStatusFilterEnum.eligible :
        return this.epes.filter( x => x.sop_status == 'eligible')
      case EpeStatusFilterEnum.not_eligible :
        return this.epes.filter( x => x.sop_status == 'not_eligible')
      case EpeStatusFilterEnum.voted :
        return this.epes.filter( x => x.sop_status == 'voted')
      default:
        return this.epes
    }
  }

  getExpand(id:number = -1):string{

    if(id == this.selected)
      return 'Close details'
    return 'Open details'

  }

  getExpandImg(id:number = -1):string{

    if(id == this.selected)
      return 'reduce'
    return 'expand'

  }

  expand(id:number = -1){
    if(id == this.selected){
      this.selected = -1
      return 
    }
    this.selected = id
  }

  sortEpes(): Epe[] {
    if (!this.epes) return []

    switch(this.sortBy){
      case 'name':
        if (this.sortDirection == 1)
          return this.epes.sort((a, b) => a.name!.localeCompare(b.name!))
        else
          return this.epes.sort((a, b) => b.name!.localeCompare(a.name!))
      case 'surname':
        if (this.sortDirection == 1)
          return this.epes.sort((a, b) => a.surname!.localeCompare(b.surname!))
        else
          return this.epes.sort((a, b) => b.surname!.localeCompare(a.surname!))
      case 'email':
        if (this.sortDirection == 1)
          return this.epes.sort((a, b) => a.username!.localeCompare(b.username!))
        else
          return this.epes.sort((a, b) => b.username!.localeCompare(a.username!))
      case 'score':
        if (this.sortDirection == 1){
          let epesVoted = this.epes.filter(x => x.score)
          console.log('voted',epesVoted)
          let epesNotVoted = this.epes.filter(x => !x.score)
          console.log('notVOted',epesNotVoted)
          return epesVoted.sort((a, b) => a.score! - b.score!).concat(epesNotVoted)
        }else{
          let epesVoted = this.epes.filter(x => x.score)
          let epesNotVoted = this.epes.filter(x => !x.score)
          return epesVoted.sort((a, b) => b.score! - a.score!).concat(epesNotVoted)
        }
    }

    return this.epes

  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }

  

  truncateFloat(n : number) : number {
    return Math.floor(n * 100) / 100
  }

  isWarning(epe: Epe): boolean{
    
    if(!epe.engVariance || !epe.standVariance)
      return false
    
    if(epe.engVariance >=2 || epe.standVariance >= 2)
      return true

    return false
  }
  
}
