<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
    <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
      <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div class="u-layout pb-2">
          <div class="u-layout-col">
            <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
              <div class="u-border-1 u-border-no-left u-border-no-right u-border-no-top u-border-palette-5-base u-container-layout u-container-layout-1">
                <div class="meeting-form-header">
                  <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Meeting Form</h1>


                  <div style="display: flex;justify-content: space-between;width: 100%;">
                    <div class="u-text u-text-default u-text-1">
                      <div style="margin-bottom: 5px;">
                        <span style="font-weight: 500; font-size: 1.1rem">Project representative information:</span>
                      </div>
                        <div class="d-flex">
                          <p class="u-file-icon u-icon header-profile-icon">
                            <img class="profile-icon" src="https://hsbooster.eu{{project.picture}}" onerror="this.src='/assets/img/1177568.png'" alt="">
                          </p>&nbsp;
                          <p style="font-weight: 700;">
                            {{project.project_acronym}} ~ 
                            <span style="font-weight: 500;"><span *ngIf="project.version == 'v2'">{{project.title}} </span>{{project.name}} {{project.surname}}</span>
                            <br>
                            <span style="font-size: 0.725rem; color: #a8bed3;">{{project.organisation_type}}</span>
                            <br>
                            <span style="font-size: 0.725rem; color: #a8bed3;">{{project.email}}</span>
                          </p>
                        </div>  
                    </div>


                    <div>
                      <!-- <div (click)="goToMeetingStep(5, 'survey')" class="circle {{getCircleState(6)}}">Visit HSbooster.eu Training Academy</div> -->
                      <a href="https://www.hsbooster.eu/training-academy" target="_blank"
                          class="u-border-2 u-border-black u-border-hover-black u-btn u-btn-round u-button-style u-hover-palette-4-light-3 u-none u-radius-21 u-text-body-color u-text-hover-black action-btn u-clearfix">
                          Visit HSbooster.eu Training Academy
                          &nbsp;&nbsp;
                          <span class="u-file-icon u-icon"><img src="/assets/img/271226.png" alt=""></span>
                        </a>
                      </div>
                  </div>
                </div>
                <!--
                <p class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-2">
                  <span class="u-text-palette-4-base" style="font-weight: 400;">One</span>
                  <span style="font-weight: 400;">/</span>
                  <span class="u-text-palette-4-base" style="font-weight: 400;">Two</span>
                  <span style="font-weight: 400;">/</span>
                  <span class="u-text-palette-4-base" style="font-weight: 400;">Three</span>
                  <span style="font-weight: 400;">/</span> Current Page<span style="font-weight: 700;"></span>
                </p>
                -->
              </div>
            </div>
            <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
              <div class="u-container-layout u-container-layout-2">
                <div class="container form-progress-container">
                  <div class="progress-container">
                    <div [ngStyle]="{'width':getProgressBar()}" class="progress" id="progress"> </div>
                    <div (click)="goToMeetingStep(0, 'first')" class="circle {{getCircleState(1)}}">1st</div>
                    <div (click)="goToMeetingStep(1, 'first')" class="circle {{getCircleState(2)}}">2nd</div>
                    <div (click)="goToMeetingStep(2, 'first')" class="circle {{getCircleState(3)}}">3rd</div>
                    <div (click)="goToMeetingStep(3, 'first')" class="circle {{getCircleState(4)}}">ADVICE</div>
                    <div (click)="goToMeetingStep(4, 'first')" class="circle {{getCircleState(5)}}">Final</div>
                  </div>
                  <div>
                    <div (click)="goToMeetingStep(5, 'survey')" class="circle {{getCircleState(6)}}">Survey</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <router-outlet name="meeting-form"></router-outlet>
  
  <!-- 
    <available-files></available-files>
  -->

  <simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>

