<div *ngFor="let epe of getEpes(); let i = index">
    <association-epe *ngIf="expanded || i<20" style="max-width: fit-content;" [project_association_status]="association_status" [epe]="epe"></association-epe>
</div>
<div class="container-fluid" style="display: flex; justify-content: space-around; ">
    <div *ngIf="association_status!=='selected' && association_status!=='approved' && association_status!=='completed' && association_status!=='closed' && (!auth.isEpe() || (auth.isEpe() && (auth.isCallManager() || auth.isProjectBoard() || auth.isAdmin()))) && !auth.isProject()" class="btn-layout">
        <button (click)="submitEligibility()" class="u-btn sop-btn">Submit Association</button>
    </div>
    <div *ngIf="association_status!=='selected' && association_status!=='approved' && association_status!=='completed' && association_status!=='closed' && (!auth.isEpe() || (auth.isEpe() && (auth.isCallManager() || auth.isProjectBoard() || auth.isAdmin()))) && !auth.isProject()" class="btn-layout">
        <button id="showMoreButton{{ project_id }}" (click)="expanded = !expanded" class="u-btn sop-btn">{{ expanded ? 'Show less' : 'Show more' }}</button>
    </div>
</div>
<div *ngIf="auth.isEpe() && association_status==='selected'" class="btn-layout">
    <button (click)="submitEpeResponse(true)" class="u-btn sop-btn">Approve Association</button>
    <button (click)="submitEpeResponse(false)" class="u-btn sop-btn">Not Approve Association</button>
</div>
<!-- <div *ngIf="(auth.isEpe() || auth.isProject()) && (association_status==='approved' || association_status==='completed' || association_status==='closed')" class="btn-layout"> -->
<div *ngIf="association_status==='approved' || association_status==='completed' || association_status==='closed'" class="btn-layout">
    <button (click)="goToMeetingForm()" class="u-btn sop-btn">Go to the Service</button>
    <button *ngIf="!auth.isProject() && !auth.isEpe() && association_status==='completed'" (click)="closeService()" class="u-border-2 u-border-black u-border-hover-black u-btn u-btn-round u-button-style u-hover-palette-4-light-3 u-none u-radius-21 u-text-body-color u-text-hover-black action-btn u-clearfix">Close the Service</button>
</div>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>