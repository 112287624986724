import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'association-instructions',
  templateUrl: './association-instructions.component.html',
  styleUrls: ['./association-instructions.component.scss', '../../../shared/sopcard/sopcard.component.scss']
})
export class AssociationInstructionsComponent implements OnInit {

  opened = false
  votesOpened = false

  constructor() { }

  ngOnInit(): void {
  }

  accordionAction(votes : boolean = false) {
    if(votes)
      this.votesOpened = !this.votesOpened
    else
      this.opened = !this.opened
  }

}
