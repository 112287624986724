import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';


@Component({
  selector: 'simple-alert',
  templateUrl: './simple-message.component.html',
  styleUrls: ['./simple-message.component.scss']
})
export class SimpleMessageComponent implements OnInit, OnChanges {

  ALERT = Alert
  timeout:any

  @Input() show: boolean = false
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() alert: Alert = Alert.SUCCESS
  @Input() message: string = ''

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {this.show = false; this.showChange.emit(this.show)}, 5000)
  }

}

export enum Alert {
  SUCCESS,
  WARNING,
  ERROR
}
