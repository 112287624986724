<div class="form-container">
    <div class="form-box template-email">
        <p>In order to start your service please contact your project and set a date for the first meeting</p>
        <p>The contact details for this project is can be found under the useful material and information section of the welcome page</p>
        <p>Please send this contact an e-mail using <strong>this suggested template</strong></p>
        <p>&nbsp;</p>


        <div class="panel-group" id="accordion">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h5 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">First Meeting email contact template</a>
                </h5>
              </div>
              <div id="collapse1" class="panel-collapse collapse">
                <div class="panel-body">
                  <p><strong>Subject: HSBooster.eu &ndash; First meeting with Expert</strong></p>
                  <p>Dear [Project Applicant Name],</p>
                  <p>I am the Expert assigned from HSbooster.eu to assist you in the standardisation activities of your project [project name]</p>
                  <p>I would like to schedule a call with you to start this service. Here are some options that you can choose from:</p>
                  <p>Insert option 1</p>
                  <p>Insert option 2</p>
                  <p>Insert option 3</p>
                  <p>This will be an introductory meeting which will help us know each other better and understand your needs. During the meeting we&rsquo;ll discuss the following points:</p>
                  <ul>
                  <li>What is the project status? This includes: topic, consortium members, timeline, aim of project, project maturity, finance, and project PI</li>
                  <li>Expected results for the service. This includes: What is your starting point and what do you expect to gain through the HSbooster services and receive from me as an expert?</li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>Looking forward to your feedback</p>
                  <p>Best regards</p>
                  <p>Expert signature</p>
                </div>
              </div>
            </div>
        
          </div> 

    </div>
</div>