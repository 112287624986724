import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { catchError, EMPTY, fromEventPattern, lastValueFrom } from 'rxjs';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { MeetingFormService } from '../meeting-form.service';
import { SessionStorage } from 'app/storage/session.storage';
@Component({
  selector: 'survey-project-meeting',
  templateUrl: './survey-project-meeting.component.html',
  styleUrls: ['../meeting-form.component.scss']
})
export class SurveyProjectMeetingComponent implements OnInit {

  @Input() step:number  = 0

  form: FormGroup
  loading: boolean = true

  questions: Question[];
  title: string; 

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  toSubmit: boolean = true
  summary: {[index:string]:any} = {}
  read_only: boolean = false
  collapsedString : string = "collapse_project_survey_meeting"

  constructor(
    private fb: FormBuilder,
    private formService: MeetingFormService,
    private mongoService: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    private meetingService: MeetingFormService,
    private auth: SessionStorage,
  ) { 
    this.formService.clearProjectMeeting() 
    this.form = this.fb.group({
      "How helpful was the call with the expert? 1 (poor) – 5 (excellent)": new FormArray([]),
      "Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)": new FormArray([]),
      "Free comment box": ['', Validators.required],
    })
    this.formService.setProjectMeetingForm(this.form)

    try{
      let data = require(`assets/meeting-form/survey_project_meeting.json`)
      this.questions = data['questions']
      this.title = data['title']
    } catch (e){
      console.log(e)
      this.questions = []
      this.title = ''
    }
  }

  async ngOnInit() {
    this.collapsedString = this.collapsedString + this.step
    console.log(this.collapsedString)
    let response = await lastValueFrom(this.mongoService.getProjectMeetingForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe(), this.step), {defaultValue: null})
    console.log(response)
    if(!response){
      this.loading = false
      return
    }
    let form: { [key: string]: any; } = response['project_survey_form_'+this.step]
    this.formService.updateProjectMeetingForm(form)
    this.formService.setProjectSurveyStep(this.step)

    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false
    this.summary = this.meetingService.getProjectMeetingFormSubmit()

    if(this.summary){
      this.read_only = true
    }
    else{
      this.read_only = false
    }
  }

  isOpenQuestion(question: Question) {
    return typeof question[this.getQuestionKey(question)] === 'string'
  }

  getQuestionKey(question: Question): string{
    return Object.keys(question)[0]
  }

  getQuestion(question: Question): string[] | string{
    return Object.values(question)[0]
  }

  getQuestionDescription(question: Question): string[] | string{
    return question["description"] ? question["description"] : ""
  }

  getKeys(): any[] {
    //console.log(this.summary)
    return Object.keys(this.summary)
  }

  confirm(): void{
    this.mongoService.submitProjectForm(
      this.helper.getSelectedProject(), 
      this.helper.getSelectedEpe(), 
      this.step,
      this.formService.getProjectMeetingFormSubmit()
      )
      .pipe(
        catchError(
          err => {
            this.addAlert("Project meeting survey update failed! Please contact the administrator", Alert.ERROR)
            return EMPTY
          }
        )
      )
      .subscribe(
        then => {
          console.log("Project meeting survey")
          this.addAlert("Project meeting survey succesfully updated", Alert.SUCCESS)
          this.read_only = true
          this.summary = this.formService.getProjectMeetingFormSubmit()
        }
      )
  }


  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

}

export interface Question {
  [key:string]: string[] | string
}
