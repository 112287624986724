import { SessionStorage } from 'app/storage/session.storage';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MeetingFormService } from 'app/form/meeting-form/meeting-form.service';

@Component({
  selector: 'open-question',
  template: `
  <div class="box__openq">
  <label>{{question}}</label>
  <div [innerHTML]="description"></div>
  <form [formGroup]="form">
    <input [formControlName]="question" *ngIf="editor == 'area'" type="textarea" class="textarea">
    <div class="ck-content">
      <ckeditor *ngIf="editor === 'editor'" [config]="{ toolbar: [ 'bold', 'italic', '|', 'undo', 'redo', 'numberedList', 'bulletedList', '|', 'link' ]}" 
      [editor]="Editor" [formControlName]="question" [data]="question" ></ckeditor>
    </div>
  </form>
  </div>
  `,
  styleUrls: ['./styles/ckeditor.scss'],
  styles: [`
    .textarea{
      border: 3px solid #E0E0E0;
      border-radius: 6px;
      width: 80%;
      height: 300px;
    }
    .box__openq{
      margin-bottom: 20px;
    }
  `]
})
export class OpenQuestionComponent implements OnInit {

  @Input()
    editor: string[] | string = ''

  @Input()
    question: string  = 'Question'

  @Input()
    type: string = ""

  @Input()
    description: string[] |string = ""

  public Editor = ClassicEditor

  meetingsOrder = [
    'first', 'second', 'third', 'fourth', "final"
  ]

  MEETING_FORM = 0

  form : FormGroup = this.fb.group({})

  constructor(
    private formService: MeetingFormService,
    private fb: FormBuilder,
    private auth: SessionStorage
  ) {
    
   }

  ngOnInit(): void {
    switch(this.type){
      case "survey":
          if(this.auth.isProject()){
            this.form = this.formService.getProjectSurveyForm()
          }
          else{
            this.form = this.formService.getEpeSurveyForm()
          }
          break
      case "report":
          this.form = this.formService.getReportForm()
          break
        case "advice_project_survey":
          this.form = this.formService.getProjectAdviceForm()
          console.log(this.form)
          break
        case "project_survey_meeting":
          this.form = this.formService.getProjectSurveyMeetingForm()
          break
      default:
          this.form = this.formService.getForm()
  }
  }

  


}
