import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Epe, Vote } from 'app/models/epe';
import { EpesService } from 'app/services/epes.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';
import { catchError, lastValueFrom, of } from 'rxjs';

@Component({
  selector: 'epe',
  templateUrl: './epe.component.html',
  styleUrls: ['./epe.component.scss', '../sop.component.scss']
})
export class EpeComponent implements OnInit {
  loading: boolean = true
  epe:Epe = new Epe()
  epe_id: number = -1
  
  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  avg : number = 0
  selected:number = -1

  voteChangeDetectorValue : Vote | null = null

  voteChangeDetector(vote: Vote){
    this.logger.info(vote, "Vote change detector:")
    this.voteChangeDetectorValue = vote
  }

  constructor(
    private service: SopService,
    private cache: SopStorage,
    private auth: SessionStorage,
    private logger: Logger,
    private router: Router,
    private route: ActivatedRoute
    ) { 

    }

  ngOnInit(): void {
    if((this.auth.isEpe() || this.auth.isProject()) && !this.auth.isAdmin() && !this.auth.isCallManager() && !this.auth.isProjectBoard())
      this.router.navigate([''])

    var epe_id = this.route.snapshot.paramMap.get('id')
    this.epe_id = epe_id ? +epe_id : -1
    this.epe = this.cache.getEpe(this.epe_id)!


    if(!this.epe){
      this.service.getByID('epe', this.epe_id).subscribe(
        then => {
          if(then.length >0){
            this.epe = then[0]
            this.loading = false
            
            this.logger.info(this.epe)
            this.cache.setEpe(this.epe)
          }
        }
      )
    }
  }


  toIter(n:number):Array<any> {
    return Array(n).fill(0).map((x,i)=>i)
  }

  isVoted(epe: Epe): boolean{
    if(!epe.score)
      return false
    return true
  }

  getExpand(id:number = -1):string{
    if(id == this.selected)
      return 'Close details'
    return 'Open details'
  }

  getExpandImg(id:number = -1):string{
    if(id == this.selected)
      return 'reduce'
    return 'expand'
  }

  expand(id:number = -1){
    if(id == this.selected){
      this.selected = -1
      return 
    }
    this.selected = id
  }

  getBtnText(status:string = ''){

    switch(status){
      case 'pending':
        return 'Eligibility Check'
      case 'eligible':
        return 'Vote'
      case 'not_eligible':
        return 'View details'
      case 'voted':
        return 'View details'
      default:
        return 'error'
    }
  }
  

  truncateFloat(n : number) : number {
    return Math.floor(n * 100) / 100
  }

  isWarning(epe: Epe): boolean{
    if(!epe.engVariance || !epe.standVariance)
      return false
    
    if(epe.engVariance >=2 || epe.standVariance >= 2)
      return true

    return false
  }
  
}
