import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'app/models/project';
import { ProjectsService } from 'app/services/projects.service';
import { SessionStorage } from 'app/storage/session.storage';
import { Logger } from 'app/utilities/logger';
import { Alert } from '../alerts/simple-message/simple-message.component';


@Component({
  selector: 'project-sopcard',
  templateUrl: './project-sopcard.component.html',
  styleUrls: ['./project-sopcard.component.scss', './sopcard.component.scss']
})
export class ProjectSopcardComponent implements OnInit {

  @Input() project !: Project
  opened = false

  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  constructor(
    private logger: Logger,
    public auth: SessionStorage,
    private service: ProjectsService
  ) { 
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.logger.info(this.project.topics)

  }

  accordionAction() {
    this.opened = !this.opened
  }

  getTopicTags(key:any = null, key2:any = null){

    if(key2){
      let tmp: any = this.project.topics ? this.project.topics[key] : null
      return this.project.topics ? Object.values(tmp[key2]) : null
    }
    if(key)
      return this.project.topics ? Object.keys(this.project.topics[key]) : null
    
    return this.project.topics ? Object.keys(this.project.topics) : null
  }

  getTopicTag(key:any){
    let result:any = this.project.topics ? this.project.topics[key] : null
    return this.project.topics ? result : null
  }

  getTopicName(topic: any, level: number = 0) {

    let highKey = Object.keys(topic)[0]
    let secondKey = Object.keys(topic[highKey])[0]
    let value = topic[highKey][secondKey]

    switch (level){
      case 0:
        return highKey
      case 1:
        return secondKey
      case 2: 
        return value
      default:
        return 'N/A'
    }
  }

  timestampToDate(timestamp: string | undefined){
    if(!timestamp)
      return ''
    
    const date = new Date(parseInt(timestamp) * 1000);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }


}
