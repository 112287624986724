import { Epe } from 'app/models/epe';
import { Project } from 'app/models/project';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { SessionStorage } from 'app/storage/session.storage';
import { lastValueFrom } from 'rxjs';
import { SopStorage } from 'app/storage/sop.storage';
import { SopService } from 'app/services/sop.service';

@Component({
  selector: 'meeting-form',
  templateUrl: './meeting-form.component.html',
  styleUrls: ['./meeting-form.component.scss']
})
export class MeetingFormComponent implements OnInit {

    MEETING: number = 0

    project: Project = new Project()
    epe: Epe = new Epe()

    // alert variables

    showAlert: boolean = false
    alertMessage: string = ''
    alertType: Alert = Alert.SUCCESS

  constructor(
    private router: Router,
    private service: AssociationsService,
    private helper: AssociationHelper,
    public auth: SessionStorage,
    private cache: SopStorage,
    private drupalService: SopService,
  ) { 
    

  }

  async ngOnInit(){
    
    let project_id = this.helper.getSelectedProject()
    let epe_id = this.helper.getSelectedEpe() 

    let projects = this.cache.getProjects()
    let epes = this.cache.getEpes()

    if(!epes){
      epes = await lastValueFrom(this.drupalService.getAll('epe'))
      this.cache.setEpes(epes)
    }


    projects = projects ? projects.filter(project => project.id == project_id) : null
    if(!projects){
      projects = await lastValueFrom(this.drupalService.getAll('project'))
      this.cache.setProjects(projects)
    }
    console.log("PROJECT")
    console.log(projects)
    this.project = projects ? projects[0] : new Project()

    epes = epes ? epes.filter(epe => epe.id == epe_id) : null
    console.log("EPE")
    console.log(epes)
    this.epe = epes ? epes[0] : new Epe()

    if(this.helper.getSelectedEpe() < 0){
      this.router.navigate(['/associations'])
    }
    this.MEETING = (await lastValueFrom(this.service.getFormStatus(this.helper.getSelectedProject(), this.helper.getSelectedEpe()))).meeting
    this.helper.setMeetingForm(this.MEETING)
    console.log(this.MEETING)

  }

  goToMeetingStep(outlet: number, route: string): void{

    console.log("Outlet: "+outlet)
    console.log("Route: "+route)
  
    this.MEETING = this.helper.getMeetingForm()
    console.log(this.MEETING)

    if(outlet >= this.MEETING || (route != "survey" && this.auth.isProject() && outlet + 1 >= this.MEETING)){

      this.addAlert("Please complete previous form to access this", Alert.WARNING)
      return

    }

    if(route == "survey"){
      if(this.auth.isProjectBoard() || this.auth.isCallManager() || this.auth.isAdmin()){
        this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:admin))`)
      }
      else if(outlet + 1 < this.MEETING){
        this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:summary))`)
      }
      else{
        this.router.navigateByUrl(`form/(meeting-form:survey-form)/(survey-form:survey-form)`)
      }
      return
    }

    if(outlet + 1 < this.MEETING)
      this.router.navigateByUrl(`form/(meeting-form:meeting_${outlet+1}/(meeting_${outlet+1}:summary))`)
    else
      this.router.navigateByUrl(`form/(meeting-form:meeting_${outlet+1}/(meeting_${outlet+1}:first))`)
  }

  getCircleState(circle: number): string{
    if(circle < this.helper.getMeetingForm())
      return 'active'
    return ''
  }

  getProgressBar(): string{
    switch(this.helper.getMeetingForm()-1){
      case 0:
        return '0';
      case 1:
        return '30%';
      case 2:
        return '60%';
      case 3:
        return '90%';
      case 4:
        return '100%';
      default:
        return '0';
    }
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }
  

}
