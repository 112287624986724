import { Project } from 'app/models/project';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EpesService } from 'app/services/epes.service';
import { SopService } from 'app/services/sop.service';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss', '../sop.component.scss']
})
export class ProjectComponent implements OnInit {
  loading: boolean = true
  project:Project = new Project()
  project_id: number = -1

  selected:number = -1

  constructor(
    private service: SopService,
    private serviceMongo: EpesService, 
    private cache: SopStorage,
    private auth: SessionStorage,
    private logger: Logger,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if((this.auth.isEpe() || this.auth.isProject()) && !this.auth.isAdmin() && !this.auth.isCallManager() && !this.auth.isProjectBoard())
      this.router.navigate([''])
    
    var project_id = this.route.snapshot.paramMap.get('id')
    this.project_id = project_id ? +project_id : -1
    this.project = this.cache.getProject(this.project_id)!

    if(!this.project){
      this.service.getByID('project', this.project_id).subscribe(
        then => {
          if(then.length >0){
            this.project = then[0]
            this.loading = false
            
            this.logger.info(this.project)
            this.cache.setProject(this.project)
          }
        }
      )
    }
  }

  getBtnText(status:string = ''){

    switch(status){
      case 'pending':
        return 'Eligibility Check'
      case 'eligible':
        return 'View details'
      case 'not_eligible':
        return 'View details'
      case 'proactive':
        return 'View details'
      default:
        return 'error'
    }

  }

  getExpand(id:number = -1):string{
    if(id == this.selected)
      return 'Close details'
    return 'Open details'
  }

  getExpandImg(id:number = -1):string{
    if(id == this.selected)
      return 'reduce'
    return 'expand'
  }

  expand(id:number = -1){
    if(id == this.selected){
      this.selected = -1
      return 
    }
    this.selected = id
  }

}
