import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { SessionStorage } from 'app/storage/session.storage';
import { lastValueFrom } from 'rxjs';
import { MeetingFormService } from '../meeting-form.service';

@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss', '../meeting-form.component.scss']
})
export class SurveyComponent implements OnInit {
  loading: boolean = true
  form: FormGroup

  constructor(
    private helper: AssociationHelper,
    private router: Router,
    private service: AssociationsService,
    private formService: MeetingFormService,
    private fb: FormBuilder,
    public auth: SessionStorage,
  ) { 
    if(this.auth.isProject()){
      this.form = this.fb.group({
        "Was the expert knowledgeable in the subject matter? 1 (strongly disagree) – 5 (strongly agree)": new FormArray([
            new FormControl('', Validators.required)
        ]),          // expert
        "How was your communication with the expert?1 (difficult to understand) – 5 (easy to understand)": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "Did the expert provide useful knowledge or recommendations? 1 (strongly disagree) – 5 (strongly agree)": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "How would you rank the overall experience with the expert? 1 (poor) – 5 (excellent)": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "How relevant were the expert's suggestions to your project's needs? 1 (not relevant) – 5 (highly relevant)": new FormArray([
            new FormControl('', Validators.required)
        ]), // expert reccomendations
        "How likely are you to implement the expert's recommendations? 1 (not likely) – 5 (highly likely)": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "What are your planned next steps for the project's future strategy?": ['', Validators.required],
        "Has your project’s understanding of standards and standardisation improved thanks to the HRB service? - 1 not at all - 5 Very much so": new FormArray([
            new FormControl('', Validators.required)
        ]), // hrb service
        "Please tell us more…": ['', Validators.required],
        "Would you consider involving standards consultations like HSBooster in future proposals at:": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "Were your queries or issues resolved effectively  in a timely manner? 1 (strongly disagree) – 5 (strongly agree)": new FormArray([
            new FormControl('', Validators.required)
        ]), // hsbooster support
        "How would you rank the overall HSbooster support? 1 (poor) – 5 (excellent)": new FormArray([
            new FormControl('', Validators.required)
        ]),

        "How likely are you to recommend HSbooster to a colleague? 1 (not likely) – 5 (very likely)": new FormArray([
            new FormControl('', Validators.required)
        ]),                // Referral
        "What was the most valuable aspect of your experience with HSbooster?": ['', Validators.required],
        "What was the most challenging aspect of your experience with HSbooster?": ['', Validators.required],

        "Please add any further comments or suggestions in the box below": ['', Validators.required],
        "Have you used the HSbooster training academy material?": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "Tell us which modules you have accessed": new FormArray([]),
        "Will you apply for follow up support from the Booster in the future?": new FormArray([
            new FormControl('', Validators.required)
        ]),
        'Please add a quote on your experience with HSbooster.eu that we may use and publish in a book of "testimonials" to share with our community. Approximate lenght of the quote should not exceed 4-5 lines. Focus on describing your needs and what you achieved after the consultancy.': ['', Validators.required],
      })
      this.formService.setProjectSurveyForm(this.form)
    }
    else{
      this.form = this.fb.group({
        "How would you rank the experience with platform? 1 (poor) – 5 (excellent)": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "How would you rank the interaction and communication with the project? 1 (poor) – 5 (excellent)": new FormArray([
            new FormControl('', Validators.required)
        ]),
        "Please add any further comments or suggestions in the box below": ['', Validators.required],
        'Please add a quote on your service delivery activities that we may use and publish in a book of "testimonials" to share with our community. Approximate lenght of the quote should not exceed 4-5 lines. Focus on describing your support to address project needs.': ['', Validators.required],
      })
      this.formService.setEpeSurveyForm(this.form)
    }
  }

  async ngOnInit() {
    if(this.helper.getSelectedProject() == -1 || this.helper.getSelectedEpe() == -1){
      this.router.navigateByUrl(`assigned-services`)
    }

    if(this.auth.isProject()){
      var response = await lastValueFrom(this.service.getProjectSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    }
    else{
      var response = await lastValueFrom(this.service.getEpeSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    }
    console.log(response)
    if(!response){
      this.loading = false
      return
    }
    let form: { [key: string]: any; } = response['survey']
    
    if(form){
      if(this.auth.isProject()){
        this.formService.updateEpeSurveyForm(form)
      }
      else{
        this.formService.updateProjectSurveyForm(form)
      }
    }
    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false
    
  }


}