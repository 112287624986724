import { AcademicQualification } from "./academic_qualification";
import { Model, MongoModel } from "./db.model";
import { PreviousPosition } from "./previous_position";
import { Role } from "./role";

export class Epe implements Model {
    [index: string] :any
    username?:string
    email?:string
    sop_status?:string
    name?:string
    surname?:string
    picture?:string
    phone_number?:string
    organisation?:string
    organisation_type?:string
    gender?:string
    country?:string
    topics?:Object[] = []
    standardisation_roles?:Role[]=[]
    cv?:string
    more_details?:string
    linkedin?:string
    score?:number
    nvotes?:number
    engVariance?:number
    standVariance?:number
    version?:string

    //V2
    academic_qualifications?: AcademicQualification[] = []
    website_publication?:boolean
    has_experience?:boolean
    position?:string
    position_dates?:string
    previous_positions?: PreviousPosition[] = []
    tell_us_more?:string
    title?:string

    /* EPE VERSION 1 */
    /*
          'id'
          'username'
          'email'
          'sop_status'
          'name'
          'surname'
          'picture'
          'phone_number'
          'organisation'
          'organisation_type'
          'gender'
          'country'
          'topics'
          'standardisation_roles'
          'cv'
          'more_details'
          'linkedin'
          'version'
    */

    /* EPE VERSION 2 */
        /*
          'id'
          'username'
          'email'
          'sop_status'
          'name'
          'surname'
          'picture'
          'phone_number'
          'organisation'
          'organisation_type'
          'gender' 
          'country'
          'topics'
          'cv'
          'linkedin'
          'has_experience'
          'standardisation_roles'
          'position'
          'position_dates'
          'tell_us_more'
          'website_publication'
          'previous_positions'
          'academic_qualifications'
          'title'
          'version'
        */
}

export class EpeMongo extends MongoModel {
    [index: string] :any
    epe_id?: number
    sop_status?: string
    vote: Vote = new Vote(false)
    

    constructor(id: number, sop_status: string, female: boolean = false){
        
        super()
        this.epe_id = id
        this.sop_status = sop_status
        this.vote = new Vote(female)
        
    }

}

export class Vote {
    [index: string] :any
    automatic?: number = 7.3
    standardisation: number[] | number = []
    engagment: number[] | number = []
    voters?: string[] = []
    gender?: number = 0

    constructor(female:boolean){

        if(female)
            this.gender = 10
    }
}