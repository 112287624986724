<div class="form-container">
    <div class="form-box template-email">
        <p>When you are ready Please send the project an e-mail using this suggested template</p>
        <div class="panel-group" id="accordion">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h5 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Next meeting email template</a>
                </h5>
              </div>
              <div id="collapse1" class="panel-collapse collapse">
                <div class="panel-body">
                    <p><strong>Subject: HSBooster.eu &ndash; Next meeting with Expert</strong></p>
                    <p><strong>&nbsp;</strong></p>
                    <p>Dear [Project Applicant Name],</p>
                    <p>Following our previous meeting, I am writing to schedule the next call with you</p>
                    <p>Here are some options that you can choose from:</p>
                    <p>Insert option 1</p>
                    <p>Insert option 2</p>
                    <p>Insert option 3</p>
                    <p>Looking forward to your feedback</p>
                    <p>Best regards</p>
                    <p>Expert signature</p>
                </div>
            </div>
          </div>
      
        </div> 
  </div>
</div>