import { ThirdMeetingSecondStepComponent } from './../form-steps/third-meeting-second-step/third-meeting-second-step.component';
import { ThirdMeetingFirstStepComponent } from './../form-steps/third-meeting-first-step/third-meeting-first-step.component';
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormSummary } from "../form-steps/form-summary/form-summary";
const routes = [

    {
      path: '',
      redirectTo: 'first',
      pathMatch: 'full'
    },
  
    {
        path: 'first',
        component: ThirdMeetingFirstStepComponent, // to define
        /*children: [{
          path: '',
          loadChildren:
            () => import('./form/meeting-form/meeting-form.module')
            .then(m => m.MeetingFormModule),
          outlet: 'formstep'
        }]*/
    },
    {
        path: 'second',
        component: ThirdMeetingSecondStepComponent
    },
    {
        path: 'summary',
        component: FormSummary,
        data: {form: 'composer'}
    }
    
  ]
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes),
    ],
    exports: [
      RouterModule
    ]
  })
  export class ThirdMeetingRoutingModule {
  
  }