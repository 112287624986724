<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
  <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
    <div class="u-clearfix u-expanded-width u-layout-wrap sop-header-layout">
      <div class="u-layout pb-2">
        <div class="u-layout-col">
          <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
            <div class="u-border-1 u-border-no-left u-border-no-right u-border-no-top u-border-palette-5-base u-container-layout u-container-layout-1 sop-header">
              <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Statistics</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<loader *ngIf="loading"></loader>

<section *ngIf="!loading" class="u-clearfix u-palette-1-base u-section-2" style="padding-top: 20px;" id="sec-5e41">

  <div class="u-clearfix u-sheet u-sheet-1">
    <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div class="u-layout">
        <div class="u-layout-row" style="justify-content: space-around;">
          <div class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
            <div class="u-container-layout u-container-layout-1">
              <div class="u-border-1 u-container-style u-group u-radius-10 u-shape-round u-white u-group-1 statistics-card" style="height: fit-content;">
                <div class="u-container-layout u-container-layout-2" style="display: flex; ">
                  <div style="margin-left: 10px; display: flex; flex-basis: min-content;">
                    <h4 class="u-text u-text-default u-text-1">Submitted Applications</h4>
                  </div>
                  <h1 class="u-text u-text-default u-text-2" style="margin-left: 20px; margin-right: 10px;" >{{ applications }}</h1>
                </div>
              </div>
            </div>
          </div>

          <div class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
              <apx-chart class="chart-container" [series]="fundingProgrammeChart.series" [chart]="fundingProgrammeChart.chart"
              [labels]="fundingProgrammeChart.labels" [title]="fundingProgrammeChart.chartTitle"></apx-chart>
          </div>
        </div>
        <div class="u-layout-row" style="justify-content: space-around;">
          <div class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
            <apx-chart class="chart-container" [series]="callTopicsChart.series" [chart]="callTopicsChart.chart"
             [labels]="callTopicsChart.labels" [title]="callTopicsChart.chartTitle"></apx-chart>
          </div>
          <div class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
            <apx-chart class="chart-container" [series]="serviceDeliveryStatusChart.series" [chart]="serviceDeliveryStatusChart.chart"
             [labels]="serviceDeliveryStatusChart.labels" [title]="serviceDeliveryStatusChart.chartTitle"></apx-chart>
          </div>
        </div>
        <div class="u-layout-row" style="justify-content: space-around;">
          <div>
            <highcharts-chart
              id="container" 
              [Highcharts]="Highcharts"
              [constructorType]="chartConstructor"
              [options]="chartOptions"
              [update]="updateFlag"
              style="width: 100%; height: 400px; display: block;">
            </highcharts-chart>
          </div>
          <div class="main-content">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="header">
                    <h4 class="title">Project completed</h4>
                    <p class="category">Premium Service</p>
                  </div>
                  <div class="content table-responsive">
                    <table class="table table-hover table-striped">
                      <thead>
                        <tr>
                          <th>
                          </th>
                          <th>
                            Project
                          </th>
                          <th>
                            <select class="form-control">
                              <option value="0">Actual Duration</option>
                            </select>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of associationsArray | paginate: { itemsPerPage: itemsPerPage, currentPage: p }; let i = index">
                          <td class="d-none d-md-table-cell">{{ calculateRowIndex(i) }}</td>
                          <td>
                            <span style="color: #ec8b00; cursor: pointer;" (click)="openNewTab('/#/project/'+row['project_id'])" >{{ row['Project'] }}</span>
                          </td>
                          <td class="d-none d-md-table-cell text-right">{{ row['ActualDuration'] }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <pagination-controls class="custom-hover" (pageChange)="p = $event"></pagination-controls>
                  </div>
                </div>
              
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</section>


