import { Injectable } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { Observable, throwError } from 'rxjs';
import { Logger } from 'app/utilities/logger';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';


// This class is a Signleton, use it to cache general local variables  

@Injectable({
  providedIn: 'root'
})
export class SessionStorage {

  
    roles:string[] = []
    username:string = ''

    url: string | null = null

    public auth$: Observable<{roles: string[], username: string, picture: string}>


    constructor(
        private userService : UserService,
        private logger: Logger,
        private router: Router
    ) {
        this.url = null
        this.auth$ = this.userService.getRoles()
        this.auth$.subscribe(
            then => {
                if (!(then.roles.indexOf("call_manager") >= 0 || then.roles.indexOf("project_board") >= 0 || then.roles.indexOf("epe") >= 0 || then.roles.indexOf("project_v2") >= 0 || then.roles.indexOf("epe_v2") >= 0 || then.roles.indexOf("project") >= 0 ))
                    window.location.href = environment.apiHost+"/user/login";
                  
                this.roles = then.roles
                this.username = then.username
                if(this.url){
                    
                    this.router.navigate([this.url])
                }
                this.logger.info(this.roles)
            }
        )
     }

    canNavigate(url: string): boolean{

        if(this.roles.length == 0)
            this.url = url

        if(url === '/associations')
            return this.isCallManager() || this.isProjectBoard()
        else if(url === '/form')
            return this.isEpe() || this.isProject()
        else if(url === '/assigned-services')
            return this.isEpe() || this.isProject()
        else if(url === '/statistics')
            return this.isEpe() || this.isProject() || this.isAdmin() || this.isCallManager() || this.isProjectBoard()
        else if(url === '/assigned-deep-dive')
            return this.isEpe()
        else if(url === '/assigned-cwa')
            return this.isEpe()
        else 
            return this.isCallManager() || this.isProjectBoard()
    }

    setUsername(username: string):void {
        this.username = username
    }

    getUsername(): string {
        return this.username
    }

    isAuthenticated(): boolean{
        return this.roles.indexOf('authenticated') >= 0
    }

    isAuthorized(acceptedRoles: string[]): boolean {
        return this.roles.some( x => acceptedRoles.indexOf(x) >= 0)
    }

    setRoles(roles: string[]): void {
        this.roles = roles
    }

    isAdmin(): boolean{
        return this.roles.indexOf('administrator') >= 0
    }

    isProjectBoard(): boolean{
        return this.roles.indexOf('project_board') >= 0
    }

    isCallManager(): boolean{
        return this.roles.indexOf('call_manager') >= 0
    }

    isEpe(): boolean{
        return this.roles.indexOf('epe') >= 0 || this.roles.indexOf('epe_v2') >= 0
    }

    isProject(): boolean{
        return this.roles.indexOf('project') >= 0 || this.roles.indexOf('project_v2') >= 0
    }
}