import { NextOrSkipMeetingsComponent } from './../form-steps/next-or-skip-meetings/next-or-skip-meetings.component';
import { FirstMeetingSecondStepComponent } from './../form-steps/first-meeting-second-step/first-meeting-second-step.component';
import { FirstMeetingFirstStepComponent } from './../form-steps/first-meeting-first-step/first-meeting-first-step.component';
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormSummary } from "../form-steps/form-summary/form-summary";

const routes = [

    {
      path: '',
      redirectTo: 'first',
      pathMatch: 'full'
    },
  
    {
        path: 'first',
        component: FirstMeetingFirstStepComponent,
        /*children: [{
          path: '',
          loadChildren:
            () => import('./form/meeting-form/meeting-form.module')
            .then(m => m.MeetingFormModule),
          outlet: 'formstep'
        }]*/
    },
    {
        path: 'second',
        component: FirstMeetingSecondStepComponent
    },
    {
      path: 'third',
      component: NextOrSkipMeetingsComponent
    },
    {
        path: 'summary',
        component: FormSummary,
        data: {form: 'composer'}
    }
    
  ]
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes),
    ],
    exports: [
      RouterModule
    ]
  })
  export class FirstMeetingRoutingModule {
  
  }