import { Epe } from 'app/models/epe';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { Logger } from 'app/utilities/logger';
import { SessionStorage } from 'app/storage/session.storage';
import { AssociationHelper } from 'app/storage/association.storage';
import { lastValueFrom } from 'rxjs';
import { AssociationsService } from 'app/services/associations.service';

@Component({
  selector: 'association-epe',
  templateUrl: './association-epe.component.html',
  styleUrls: ['./association-epe.component.scss', '../../sopcard.component.scss']
})
export class AssociationEpeComponent implements OnInit, AfterViewInit {


  // only if is epe variable

  meeting_form: number = 0

  // end

  loading: boolean = false
  selected:number = -1

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS


  @Input() epe: Epe = new Epe()
  @Input() project_association_status: string = 'open'


  constructor(
    private logger: Logger,
    public auth: SessionStorage,
    public helper: AssociationHelper,
    private service: AssociationsService
  ) { }
  async ngAfterViewInit() {
    if(this.auth.isEpe() || (this.auth.isProject() && this.epe.id == this.helper.getSelectedEpe())){
      this.meeting_form = (await lastValueFrom(this.service.getFormStatus(this.helper.getSelectedProject(), this.helper.getSelectedEpe()))).meeting - 1
      if(this.meeting_form < 0)
        this.meeting_form = 0
      this.helper.setMeetingForm(this.meeting_form + 1)
    }
  }

  ngOnInit() {
   
  }

  canHide(){
    return (this.epe.id != this.helper.getSelectedEpe() && this.auth.isProject()) || this.epe.association_status == 'not_approved'
  }

  getExpandImg(id:number = -1):string{

    if(id == this.selected)
      return 'reduce'
    return 'expand'

  }

  expand(id:number = -1){
    if(id == this.selected){
      this.selected = -1
      return 
    }
    this.selected = id
  }

  change(epe_id: number):void {

    if(this.project_association_status == 'selected')
      this.addAlert("An epe has already been selected, please wait until epe's response.", Alert.WARNING)
    else if(this.project_association_status == 'approved')
      this.addAlert("An epe has already been associated with this project.", Alert.WARNING)
    else if(this.epe['association_status'] !== 'not_approved')
      this.helper.setSelectedEpe(epe_id)
    else
      this.addAlert("This epe has already been selected and has not accepted the association!", Alert.WARNING)

  }

  getTopicTags(key:any = null, key2:any = null){

    if(key2){
      let tmp: any = this.epe.topics ? this.epe.topics[key] : null
      return this.epe.topics ? Object.values(tmp[key2]) : null
    }
    if(key)
      return this.epe.topics ? Object.keys(this.epe.topics[key]) : null
    
    return this.epe.topics ? Object.keys(this.epe.topics) : null
  }

  getTopicTag(key:any){
    let result:any = this.epe.topics ? this.epe.topics[key] : null
    return this.epe.topics ? result : null
  }

  getTopicName(topic: any, level: number = 0) {

    let highKey = Object.keys(topic)[0]
    let secondKey = Object.keys(topic[highKey])[0]
    let value = topic[highKey][secondKey]

    switch (level){
      case 0:
        return highKey
      case 1:
        return secondKey
      case 2: 
        return value
      default:
        return 'N/A'
    }
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }


}
