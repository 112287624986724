import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Epe, EpeMongo, Vote } from 'app/models/epe';
import { AssociationsService } from 'app/services/associations.service';
import { EpesService } from 'app/services/epes.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';
import { catchError, EMPTY, forkJoin, lastValueFrom, Observable, switchMap } from 'rxjs';
@Component({
  selector: 'epe-sopcard-small',
  templateUrl: './epe-sopcard-small.component.html',
  styleUrls: ['./epe-sopcard-small.component.scss', '../sopcard.component.scss']
})
export class EpeSopcardSmallComponent implements OnInit, AfterViewInit {

  @Input() epe !: Epe

  @Output() voteUpdated : EventEmitter<Vote> = new EventEmitter<Vote>()

  vote: Vote = new Vote(false)
  votedByMe: boolean = false
  votedBySomeone: boolean = false
  vote$: Observable<Vote> | null = null

  standardisation?: number 
  engagment?: number 
  
  checked:boolean = false

  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  note = ''

  constructor(
    private drupalService: SopService,
    private mongoService: EpesService,
    private cache: SopStorage,
    public auth: SessionStorage,
    private logger: Logger,
    private associationService: AssociationsService
  ) { }

  ngAfterViewInit(): void {
    this.epe.sop_status = !this.epe.sop_status ? 'pending' : this.epe.sop_status
    if(this.epe.sop_status != 'pending'){
      let cachedEpe = this.cache.getEpeMongo(this.epe.id)
      let vote = cachedEpe?.vote
      let note = cachedEpe?.note
      
      if(vote && note){
        this.vote = vote
        this.note = note
        return
      }

      this.getVotes();
      if(this.auth.isCallManager() || this.auth.isProjectBoard()){
        this.getNote();
      }
    }
  }


  private getNote(){
    this.mongoService.getNote(this.epe.id).subscribe(
        then =>{
          this.note = then
          this.cache.setNote(this.epe.id, this.note)
        }
    );
  }

  private getVotes() {
    this.mongoService.getVote(this.epe.id).subscribe(
      then => {
        this.logger.info(then, 'Vote:');
        this.vote = then;
        this.voteUpdated.emit(this.vote)
        this.cache.setVote(this.epe.id, this.vote)


        if (this.auth.isCallManager()) {

          //if (typeof then.engagment === 'number' || typeof then.standardisation === 'number')
          //  return;

          //let engagment: number[] = then.engagment;
          //let standardisation: number[] = then.standardisation;

          //if (engagment.length === 0 || standardisation.length === 0)
          //  return;

          if (then.voters?.indexOf(this.auth.getUsername()) != -1) {
            this.votedByMe = true;
          }

          if (then.voters) {
            this.votedBySomeone = true;
          }

          /*var eng = this.truncateFloat(engagment.reduce(
            (previous: number, current: number) => previous + current
          ) / engagment.length);

          var stand = this.truncateFloat(standardisation.reduce(
            (previous: number, current: number) => previous + current
          ) / standardisation.length);
          */
          
          if(this.votedByMe){
            this.getVoteByMe()
          }
          
          //this.epe.score = this.truncateFloat((eng * 3 + stand * 3 + then.automatic! * 3 + then.gender! * 1) / 10);
          //this.epe.nvotes = then.voters?.length;
          this.updateEpeScore(this.epe)


        }
        else {
          this.getVoteByMe();
        }
      }
    );
  }

  async updateEpeScore(epe: Epe){
    
    let scores = await lastValueFrom(this.mongoService.getScore(epe.id), {defaultValue: null})
    if(!scores)
      return
    epe.score = scores.score
    epe.nvotes = scores.nvotes
    epe.engVariance = scores.engVariance
    epe.standVariance = scores.standVariance


  }

  private getVoteByMe() {
    this.mongoService.getVote(this.epe.id, this.auth.getUsername())
    .subscribe(
      then => {

        if (then.standardisation && then.engagment) {
          this.logger.info(then, 'Vote by Username:');
          this.votedByMe = true;
          this.standardisation = this.getNumber(then.standardisation);
          this.engagment = this.getNumber(then.engagment);
        }
      }
    );
  }

  truncateFloat(n : number) : number {
    return Math.floor(n * 10) / 10
  }

  getNumber(x : number | number[]): number{

    if (typeof x == 'number')
      return x
    return -1

  }

  ngOnInit(): void {
  }

  change():void {
    this.checked = !this.checked
  }

  submitNote(){
    this.mongoService.updateNote(this.epe.id, this.note)
      .pipe(    
        catchError(
          err => {
            this.addAlert("Note submit failed! Please contact the administrator", Alert.ERROR)
            return EMPTY
        }
        )
      )
      .subscribe(
        then =>{
          this.logger.info(then)
          this.getNote()
          this.addAlert("Note submitted!", Alert.SUCCESS)
        }
      )
  }

  submitEligibility(){
    
    let sop_status = this.checked ? 'eligible' : 'not_eligible'
    let epe = this.cache.getEpe(this.epe.id)

    if(!epe || !epe.standardisation_roles)
      return

    let roles = epe.standardisation_roles.map(x => x.role)
    let years = epe.standardisation_roles.map(x => x.years)


    forkJoin({
      drupal: this.drupalService.setSopStatus("epe", this.epe.id, sop_status, this.epe.email, this.epe.name +" "+ this.epe.surname),
      mongo: this.mongoService.createEpe(new EpeMongo(this.epe.id, sop_status, this.epe.gender == 'Female'), {roles, years})
    })
    .pipe(
      catchError(
        err => {
          this.addAlert("Eligibility check failed! Please contact the administrator", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
        
          this.logger.info(then.drupal, 'Set Sop Status Response')
          this.logger.info(then.mongo, 'Create Epe Response')
          this.cache.setSopStatus(this.epe.id, sop_status)
          this.cache.setEpeMongo(then.mongo)
          this.vote = then.mongo.vote
          this.addAlert("Eligibility check done. Epe can now be rated", Alert.SUCCESS)
        
      }
    )
  
  }

  submitVote() : void {
    if(this.standardisation && this.engagment)
      this.mongoService.updateVote(this.standardisation!, this.engagment!, this.epe.id, this.auth.getUsername())
      .pipe(    
          catchError(
            err => {
              this.addAlert("Vote submit failed! Please contact the administrator", Alert.ERROR)
              return EMPTY
          }
          )
        )
        .subscribe(
          then => {
            this.logger.info(then)
            this.getVotes()
            this.votedByMe = true
            this.votedBySomeone = true
            this.addAlert("Vote submitted!", Alert.SUCCESS)
            //this.cache.updateVote(this.epe.id, this.standardisation!, this.engagment!)
            

          }
        )
    else
      this.addAlert("Please add votes before submit", Alert.WARNING)
  }

  async stopVote() : Promise<void> {
    
    let projects = this.cache.getProjects()
    if(!projects){
      projects = await lastValueFrom(this.drupalService.getAll('project'))
      this.cache.setProjects(projects)
    }
    projects = projects.filter(x => x.sop_status == 'eligible')

    forkJoin({
      drupal: this.drupalService.setSopStatus("epe",this.epe.id, 'voted'),
      association: this.associationService.createAssociation(projects, [this.epe])
    })
    .pipe(
      catchError(
        err => {
          this.addAlert("Vote stop failed! Please contact the administrator", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
        this.logger.info(then.drupal, "Sop status updated, voted")
        this.cache.setSopStatus(this.epe.id, 'voted')
        this.addAlert("Voting completed! Now anyone can vote this Epe", Alert.SUCCESS)
      }
    )

  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }

  isWarning(): boolean{
    
    if(!this.epe.engVariance || !this.epe.standVariance)
      return false
    
    if(this.epe.engVariance >=2 || this.epe.standVariance >= 2)
      return true

    return false
  }

}
