import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'final-meeting-template',
  templateUrl: './final-meeting-template.component.html',
  styleUrls: ['../../../meeting-form.component.scss']
})
export class FinalMeetingTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
