import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyProjectAdviceComponent } from './survey-project-advice.component';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
  declarations: [
    SurveyProjectAdviceComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    SurveyProjectAdviceComponent
  ]
})
export class SurveyProjectAdviceModule { }
