import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MeetingFormService } from 'app/form/meeting-form/meeting-form.service';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { SessionStorage } from 'app/storage/session.storage';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'survey-first-meeting',
  templateUrl: './survey-first-meeting.component.html',
  styleUrls: ['./survey-first-meeting.component.scss']
})
export class SurveyFirstMeetingComponent implements OnInit {

  response: any
  response2: any
  response3: any
  response4: any
  dataEpe = {form: 'survey', user: 'epe'}
  dataProject = {form: 'survey', user: 'project'}

  constructor(
    
    private formService: MeetingFormService,
    private service: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    public auth: SessionStorage,
  ) { }

  async ngOnInit() {
    this.response = await lastValueFrom(this.service.getForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe(), 1), {defaultValue: null})
    console.log(this.response)
    this.response2 = await lastValueFrom(this.service.getForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe(), 2), {defaultValue: null})
    console.log(this.response2)
    this.response3 = await lastValueFrom(this.service.getForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe(), 3), {defaultValue: null})
    console.log(this.response3)
    this.response4 = await lastValueFrom(this.service.getForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe(), 4), {defaultValue: null})
  }

}
