import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { MeetingFormService } from '../../meeting-form.service';

@Component({
  selector: 'date-setted',
  templateUrl: './date-setted.component.html',
  styleUrls: ['../../meeting-form.component.scss']
})
export class DateSettedComponent implements OnInit {


  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

    meetingsOrder = [
      'first', 'second', 'third', 'advice', "final"
    ]

    MEETING_FORM: number = 0

    accepted: boolean = false
    form : FormGroup

  constructor(
    private service: MeetingFormService,
    private mongoService: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.form = service.getForm()
    this.MEETING_FORM = this.helper.getMeetingForm()
   }

  ngOnInit(): void {
    //this.form = this.formBuilder.group({
      //date: ['', Validators.required] // Add required validation to the date field
    //});
  }

  getIndex(): number{
    return this.MEETING_FORM > 0 ? this.MEETING_FORM - 1 : 0
  }

  accept(accept: boolean): void{
    this.form.patchValue({
        dateSetted: accept
    })
    this.accepted = accept
    !accept ? this.addAlert(`Please contact project to arrange ${this.meetingsOrder[this.getIndex()]} meeting.`, Alert.ERROR) : null
  }

  confirm(): void{
    if(this.form.valid){
      let meeting = this.MEETING_FORM
      if(meeting <= 0){
        meeting = 1
      }

      // console.log(this.helper.getSelectedProject())
      // console.log(this.helper.getSelectedEpe())
      // console.log(this.service.getSubmitMeetingValue(meeting))
      // console.log(this.service.getSubmit())


      this.mongoService.submitForm(
        this.helper.getSelectedProject(), 
        this.helper.getSelectedEpe(), 
        this.service.getSubmitMeetingValue(meeting), 
        this.service.getSubmit(),
        false,
        false
        ).subscribe(
          then => {
            console.log(then)
            this.router.navigateByUrl(`form/(meeting-form:meeting_${meeting}/(meeting_${meeting}:${this.service.getNextStep()}))`)
          }  
        )
    }else{
      this.addAlert('Please select a date', Alert.ERROR)
    }
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }

}
