import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { MeetingFormService } from '../../meeting-form.service';
@Component({
  selector: 'next-or-skip-meetings',
  templateUrl: './next-or-skip-meetings.component.html',
  styleUrls: ['../../meeting-form.component.scss']
})
export class NextOrSkipMeetingsComponent implements OnInit {

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  meetingsOrder = [
    'first', 'second', 'third', 'fourth', "final"
  ]

  MEETING_FORM = 0

  form : FormGroup

  constructor(
    private service: MeetingFormService,
    private helper: AssociationHelper,
    private mongoService: AssociationsService,
    private router: Router
  ) {
    this.form = service.getForm()
    this.MEETING_FORM = this.helper.getMeetingForm() - 1
    if (this.MEETING_FORM < 0)
      this.MEETING_FORM = 0
   }

  ngOnInit(): void {
    
  }

  skip(skip: boolean): void{
    this.form.patchValue({
        meetingCompleted: !skip
    })
    let meeting = this.helper.getMeetingForm()
    console.log(meeting)
    if(meeting <= 0){
      meeting = 1
    }

    let nextStep = this.service.getNextStep(meeting)
    let complete = true

    console.log(complete)
    
    this.mongoService.submitForm(
      this.helper.getSelectedProject(), 
      this.helper.getSelectedEpe(), 
      this.service.getSubmitMeetingValue(meeting), 
      this.service.getSubmit(),
      complete,
      skip
      ).subscribe(
        then => {
          if(!skip){
            meeting++
          }
          else{
            meeting = 4
          }

          console.log(then)
          this.helper.setMeetingForm(meeting)
          if(meeting <= 6)
            this.router.navigateByUrl(`form/(meeting-form:meeting_${meeting}/(meeting_${meeting}:${nextStep}))`)
          else
            this.router.navigate(['form'])

        }
      )
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }


}
