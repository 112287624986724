import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'advice-step',
  templateUrl: './advice-step.component.html',
  styles: [
  ]
})
export class AdviceStepComponent implements OnInit {

  form_structure_name = "advice"
  constructor() { }

  ngOnInit(): void {
  }

}
