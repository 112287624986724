<div class="form-container">
    <div class="form-box">
        <h3>Have you set a date for the {{meetingsOrder[getIndex()]}} meeting?</h3>
        <div *ngIf="!accepted" class="btn-layout">
            <button (click)="accept(false)" class="u-btn sop-btn">NO</button>
            <button (click)="accept(true)" class="u-btn sop-btn">YES</button>
        </div>
        <form [formGroup]="form" *ngIf="accepted" class="btn-layout">
            <input [formControlName]="'date'" type="date" class="u-input u-input-rectangle u-radius-6 u-border-1 date-input">
            <button (click)="confirm()" class="u-btn sop-btn">Save</button>
        </form>
    </div>
</div>
<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>