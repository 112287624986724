<section *ngIf="response">
    <h2  style="text-align:center">First Meeting Form</h2>
    <survey-project-meeting *ngIf="auth.isProject() || auth.isAdmin() || auth.isProjectBoard() || auth.isCallManager() " [step]="1"></survey-project-meeting>    
</section>

<section *ngIf="response2">
    <h2  style="text-align:center">Second Meeting Form</h2>
    <survey-project-meeting *ngIf="auth.isProject() || auth.isAdmin() || auth.isProjectBoard() || auth.isCallManager() " [step]="2"></survey-project-meeting>    
</section>

<section *ngIf="response3">
    <h2  style="text-align:center">Third Meeting Form</h2>
    <survey-project-meeting *ngIf="auth.isProject() || auth.isAdmin() || auth.isProjectBoard() || auth.isCallManager() " [step]="3"></survey-project-meeting>    
</section>

<section *ngIf="response4">
    <h2  style="text-align:center">Advice Form</h2>
    <survey-project-advice *ngIf="auth.isProject() || auth.isAdmin() || auth.isCallManager() || auth.isProjectBoard()"></survey-project-advice>
</section>

<h2  style="text-align:center">Final survey</h2>
<form-summary [data]="dataEpe" [report]="false"></form-summary>
<form-summary [data]="dataProject" [report]="false"></form-summary>
