import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AssociationMongo } from 'app/models/association';
import { Project, ProjectMongo } from 'app/models/project';
import { AssociationsService } from 'app/services/associations.service';
import { EpesService } from 'app/services/epes.service';
import { ProjectsService } from 'app/services/projects.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { Logger } from 'app/utilities/logger';
import { catchError, EMPTY, forkJoin, lastValueFrom, Observable, switchMap } from 'rxjs';
@Component({
  selector: 'project-sopcard-small',
  templateUrl: './project-sopcard-small.component.html',
  styleUrls: ['./project-sopcard-small.component.scss', '../sopcard.component.scss']
})
export class ProjectSopcardSmallComponent implements OnInit {

  @Input() project !: Project
  
  checked:boolean = false

  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  note = ''

  constructor(
    private drupalService: SopService,
    private mongoService: ProjectsService,
    private mongoAssociationService: AssociationsService,
    private cache: SopStorage,
    public auth: SessionStorage,
    private logger: Logger
  ) { }

  ngAfterViewInit(): void {
    this.project.sop_status = !this.project.sop_status ? 'pending' : this.project.sop_status
    if(this.project.sop_status != 'pending' && (this.auth.isCallManager() || this.auth.isProjectBoard())){
      this.getNote();
    }
  }

  private getNote(){
    this.mongoService.getNote(this.project.id).subscribe(
        then =>{
          this.note = then
          this.cache.setNote(this.project.id, this.note)
        }
    );
  }

  getNumber(x : number | number[]): number{

    if (typeof x == 'number')
      return x
    return -1

  }

  ngOnInit(): void {
  }

  change():void {
    this.checked = !this.checked
  }

  moveProjectToEligible(){
    this.drupalService.setSopStatus("project",this.project.id, "eligible", this.project.email, this.project.name +" "+ this.project.surname)
    .pipe(
      catchError(
        err => {
          this.addAlert("Eligible move failed! Please contact the administrator", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
          this.logger.info(then, 'Set Sop Status Response')
          this.cache.setSopStatus(this.project.id, "proactive")
          this.project.sop_status = "proactive"
          this.addAlert("The project has been moved to Eligible status.", Alert.SUCCESS)
      }
    )
  }

  moveProjectToProActive(){
    this.drupalService.setSopStatus("project",this.project.id, "proactive", this.project.email, this.project.name +" "+ this.project.surname)
    .pipe(
      catchError(
        err => {
          this.addAlert("ProActive move failed! Please contact the administrator", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
          this.logger.info(then, 'Set Sop Status Response')
          this.cache.setSopStatus(this.project.id, "proactive")
          this.project.sop_status = "proactive"
          this.addAlert("The project has been moved to Pro Active status.", Alert.SUCCESS)
      }
    )
  }

  submitNote(){
    this.mongoService.updateNote(this.project.id, this.note)
      .pipe(    
        catchError(
          err => {
            this.addAlert("Note submit failed! Please contact the administrator", Alert.ERROR)
            return EMPTY
        }
        )
      )
      .subscribe(
        then =>{
          this.logger.info(then)
          this.getNote()
          this.addAlert("Note submitted!", Alert.SUCCESS)
        }
      )
  }

  async submitEligibility(){
    
    let sop_status = this.checked ? 'eligible' : 'not_eligible'
    let project = this.cache.getProject(this.project.id)
    let epes = this.cache.getEpes()

    if(!project)
      return

    if(!epes){
      epes = await lastValueFrom(this.drupalService.getAll('epe'))
      this.cache.setEpes(epes)
    }

    epes = epes.filter( x => x.sop_status == 'voted')

    forkJoin({
      drupal: this.drupalService.setSopStatus("project",this.project.id, sop_status, this.project.email, this.project.name +" "+ this.project.surname),
      mongo: this.mongoService.createProject(new ProjectMongo(this.project.id, sop_status)),
      mongo_association: this.mongoAssociationService.createAssociation([project], epes)
    })
    .pipe(
      catchError(
        err => {
          this.addAlert("Eligibility check failed! Please contact the administrator", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
        
          this.logger.info(then.drupal, 'Set Sop Status Response')
          this.logger.info(then.mongo, 'Create Project Response')
          this.logger.info(then.mongo_association, 'Create Association Response')
          this.cache.setSopStatus(this.project.id, sop_status)
          this.project.sop_status = sop_status
          this.cache.setProjectMongo(then.mongo)
          this.cache.setAssociationMongo(then.mongo_association)
          this.addAlert("Eligibility check done.", Alert.SUCCESS)
      }
    )
  
  }

  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

}
