import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { MeetingFormService } from '../../meeting-form.service';

@Component({
  selector: 'fifth-meeting-third-step',
  templateUrl: './fifth-meeting-third-step.component.html',
  styleUrls: ['../../meeting-form.component.scss']
})
export class FifthMeetingThirdStepComponent implements OnInit {
  
  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  meetingsOrder = [
    'first', 'second', 'third', 'fourth', "final"
  ]

  MEETING_FORM = 0

  form : FormGroup

  constructor(
    private service: MeetingFormService,
    private helper: AssociationHelper,
    private mongoService: AssociationsService,
    private router: Router
  ) {
    this.form = service.getForm()
    this.MEETING_FORM = this.helper.getMeetingForm() - 1
    if (this.MEETING_FORM < 0)
      this.MEETING_FORM = 0
   }

  ngOnInit(): void {
    
  }

  accept(accept: boolean): void{
    
    // console.log()
    // var formSubmission = this.service.getReportForm().value
    // var valid = true
    // for (let formKey in formSubmission) {
    //   //console.log(formSubmission[formKey])
    //   // if(Array.isArray(formSubmission[formKey]) && formSubmission[formKey].length == 0){
    //   //   valid = false
    //   // }
    //   // else 
    //   if(typeof formSubmission[formKey] == "string" && formSubmission[formKey]==""){
    //     valid = false
    //   }
    // }
    console.log(this.service.getReportForm())
    if(this.service.getReportForm().status == "VALID"){
      this.form.patchValue({
          meetingCompleted: accept
      })
      let meeting = this.helper.getMeetingForm()
      if(meeting <= 0){
        meeting = 1
        console.log("meeting <= 0")
      }

      let nextStep = this.service.getNextStep(meeting)
      let complete = false
      
      if(nextStep == 'first' || nextStep == 'summary')
        complete = true

      if(accept)
        this.mongoService.submitForm(
          this.helper.getSelectedProject(), 
          this.helper.getSelectedEpe(), 
          this.service.getSubmitMeetingValue(meeting), 
          this.service.getSubmit(),
          complete,
          false
          ).subscribe(
            then => {
              if(complete)
                meeting++
              console.log(nextStep)
              console.log(meeting)
              this.helper.setMeetingForm(meeting)
              if(meeting < 6)
                this.router.navigateByUrl(`form/(meeting-form:meeting_${meeting}/(meeting_${meeting}:${nextStep}))`)
              else if(meeting == 6){
                this.router.navigateByUrl(`form/(meeting-form:survey-form)/(survey-form:survey-form)`)
              }
              else{
                this.router.navigate(['form'])
              }

            }
          )
        else
          this.addAlert(`Please come back when you have completed the ${this.meetingsOrder[this.MEETING_FORM]} meeting.`, Alert.ERROR)
    }
    else{
      this.addAlert('Please compile all the field in the Activity report before submitting it', Alert.WARNING)
    }
          

  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }


}
