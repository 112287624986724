import { SessionStorage } from 'app/storage/session.storage';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MeetingFormService } from 'app/form/meeting-form/meeting-form.service';
import { Question } from '../form-composer.component';

export interface Fields{
    [key:string]:string[]
}

@Component({
  selector: 'tick-box',
  template: `
    <div class="box__tick" *ngFor="let question of getKeys()">
        <label>{{training ? 'Trainings' :question}}</label>
        <div [innerHTML]="description"></div>
        <form [formGroup]="form" class="ans">
            <div *ngFor="let answer of getField(question)" [ngClass]="rate ? 'ans-checkbox-rate' : 'ans-checkbox'">
                <div>
                    <input 
                        formArrayName="{{question}}" 
                        (change)="onCheckboxChange($event, question)"
                        [value]="answer" [checked]="isChosen(answer, question)"
                        class="inp-cbx" id="{{question+answer}}"  type="checkbox"/>
                    <label class="cbx" for="{{question+answer}}">
                        <span>
                            <svg width="12px" height="10px">
                                <use xlink:href="#check"></use>
                            </svg>
                        </span>
                        <span>{{answer}}</span>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <!--SVG Sprites-->
    <svg class="inline-svg">
    <symbol id="check" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
    </symbol>
    </svg>
  `,
  styles: [`
    .ans {
        display: flex;
        flex-wrap: wrap;
    }
    .ans-checkbox{
        flex: 100%;
    }
    .ans-checkbox-rate{
        flex: 20%;
    }
    .box__tick{
        margin-bottom: 20px;
    }
    .cbx {
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.2s ease;
    }
    .cbx:hover {
        background: rgba(0,119,255,0.06);
    }
    .cbx span {
        float: left;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);
    }
    .cbx span:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        transform: scale(1);
        border: 1px solid #cccfdb;
        transition: all 0.2s ease;
        box-shadow: 0 1px 1px rgba(0,16,75,0.05);
    }
    .cbx span:first-child svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
    }
    .cbx span:last-child {
        padding-left: 8px;
        line-height: 18px;
    }
    .cbx:hover span:first-child {
        border-color: #07f;
    }
    .inp-cbx {
        position: absolute;
        visibility: hidden;
    }
    .inp-cbx:checked + .cbx span:first-child {
        background: #07f;
        border-color: #07f;
        animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
        stroke-dashoffset: 0;
    }
    .inline-svg {
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        user-select: none;
    }
    @media screen and (max-width: 640px) {
        .cbx {
            width: 100%;
            margin-bottom: 4px;
            display: inline-block;
        }
        }
        @-moz-keyframes wave {
        50% {
            transform: scale(0.9);
        }
        }
        @-webkit-keyframes wave {
        50% {
            transform: scale(0.9);
        }
        }
        @-o-keyframes wave {
        50% {
            transform: scale(0.9);
        }
        }
        @keyframes wave {
        50% {
            transform: scale(0.9);
        }
    }

  `]
})
export class TickBoxComponent implements OnInit, AfterViewInit {

    @Input()
        fields: Question = {
            'Question 1': ['A', 'B', 'C'],
            'Question 2': ['A', 'B', 'C', 'E', 'F']
        }

    @Input()
        type: string = ""

    @Input()
        description: string[] |string = ""
        
    unique: boolean = false
    rate: boolean = false
    training: boolean = false

    meetingsOrder = [
        'first', 'second', 'third', 'fourth', "final"
    ]

    MEETING_FORM = 0

    form : FormGroup = this.fb.group({})

    constructor(
        private formService: MeetingFormService,
        private fb: FormBuilder,
        private auth: SessionStorage
    ) {}

    ngAfterViewInit(): void {
        let question = Object.values(this.fields)[0]
        let first = Object.values(question)[0]
        if(first === 'type_radio'){
            this.unique = true

            var question_2 = Object.values(this.fields)[0]
            var second = Object.values(question_2)[1]
            if(second === 'rate'){
                this.rate = true
            }
        } else if (first === 'trainings'){
            this.training = true
        }
    }

   getKeys(): string[]{
    return Object.keys(this.fields).filter( x => x != "description")
   }

   getField(key: string): string[]{
    
    if(this.unique){
        if(this.rate){
            return Object.values(this.fields[key]).filter( x => x != 'type_radio').filter( x => x != 'rate')
        }
        else{
            return Object.values(this.fields[key]).filter( x => x != 'type_radio')
        }
    } else if (this.training){
        return Object.values(this.fields[key]).filter( x => x != 'trainings')
    }
    return Object.values(this.fields[key])
   }

  ngOnInit(): void {
    
    switch(this.type){
        case "survey":
            if(this.auth.isProject()){
                this.form = this.formService.getProjectSurveyForm()   
            }
            else{
                this.form = this.formService.getEpeSurveyForm()   
            }
            break
        case "report":
            this.form = this.formService.getReportForm()   
            break
        case "advice_project_survey":
            this.form = this.formService.getProjectAdviceForm()   
            break
        case "project_survey_meeting":
            this.form = this.formService.getProjectSurveyMeetingForm()
            break
        default:
            this.form = this.formService.getForm()
    }

  }

  onCheckboxChange(event: Event, question: string): void {

    const target = event.target as HTMLInputElement;
    const selectedAnswers = (this.form.controls[question] as FormArray)
    if(this.unique)
        selectedAnswers.clear()
    if(!target)
        return
    if(target.checked)
        selectedAnswers.push(new FormControl(target.value))
    else{
        const index = selectedAnswers.controls.findIndex(x => x.value === target.value);
        selectedAnswers.removeAt(index);
    }        
  }

  isChosen(answer: string, question: string): boolean{

    const selectedAnswers = (this.form.controls[question] as FormArray)

    return selectedAnswers.controls.findIndex(x => x.value === answer) > -1

  }

  


}
