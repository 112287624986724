<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
    <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
        <div class="u-clearfix u-expanded-width u-layout-wrap sop-header-layout">
            <div class="u-layout">
                <div class="u-layout-col">
                    <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
                        <div class="u-border-1 u-border-no-left u-border-no-right u-border-no-top u-border-palette-5-base u-container-layout u-container-layout-1 sop-header">
                            <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Assigned CEN Workshop Agreements</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<loader *ngIf="loading"></loader>

<div *ngIf="!loading" class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card" style="width: 98%">
                    <div class="header" style="display: flex; justify-content: space-between;">
                        <div>
                            <h4 class="title">CEN Workshop Agreements Service</h4>
                        </div>
                    </div>
                    <div class="content table-responsive">
                        <div style="overflow-x: auto;">
                            <table class="table table-hover table-striped">
                                <thead>
                                <!--                                <tr>-->
                                <!--                                    <th *ngFor="let cell of headerRow">-->
                                <!--                                        <span *ngIf="sortSelected == cell && sortDirection == 'ASC'" (click)="sortCell(cell)">{{ cell }} <i style="color: #ec8b00; cursor: pointer;" onclick="sortCell(cell)" class="fa fa-arrow-up"></i></span>-->
                                <!--                                        <span *ngIf="sortSelected == cell && sortDirection == 'DESC'" (click)="sortCell(cell)">{{ cell }} <i style="color: #ec8b00; cursor: pointer;" class="fa fa-arrow-down"></i></span>-->
                                <!--                                        <span *ngIf="sortSelected != cell" (click)="sortCell(cell)">{{ cell }} <i style="cursor: pointer;" class="fa fa-arrow-up"></i></span>-->
                                <!--                                    </th>-->
                                <!--                                    <th>-->
                                <!--                                        ACTION-->
                                <!--                                    </th>-->
                                <!--                                </tr>-->
                                <tr>
                                    <!--                                    <th>ID</th>-->
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Epes</th>
                                    <th>Your Status</th>
                                    <th>Effort</th>
                                    <th>Report</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let row of assignedDeepDives; let i = index;">
                                    <!--                                    <td>{{ i+1 }}</td>-->
                                    <td>{{ row['name'] }}</td>
                                    <td>{{ row['description'] }}</td>
                                    <td>
                                        <div class="selected-epe">
                                            <div *ngFor="let epe of getEpes(row['epes'])"
                                                 class="selected-table-epe-item" style="background-color: #e0e0e0"
                                            >
                                                <span>{{ getEpeString(epe) }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ getSelfStatus(getEpes(row['epes'])) }}</td>
                                    <td>{{ row['effort'] }}</td>
                                    <td>
                                        <div  class="form-group" style="display: ruby-text; align-items: center; margin-right: 50px; margin-bottom: 0">
                                            <div *ngIf="getReport(row)===''">
                                                <label for="fileInput">Choose Report:</label>
                                                <input type="file" id="fileInput" (change)="onFileSelected($event)" class="form-control-file" style="width: 230px">
                                            </div>
                                            <a download href="{{downloadReport(row)}}" *ngIf="getReport(row)!==''" style="cursor: pointer; color: #ec8b00;">
                                                {{ getReport(row) }}
                                            </a>
                                            <button *ngIf="getReport(row)===''" class="u-btn sop-btn" (click)="uploadReport(row)">Upload</button>
                                            <button *ngIf="getReport(row)!==''" class="u-btn danger-btn" (click)="deleteReport(row)">Delete</button>
                                        </div>
                                    </td>
                                    <td>
                                        <button class="u-btn sop-btn" data-toggle="modal" data-target="#myModal" (click)="loadModalData(row)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        <!--                                        <button class="u-btn danger-btn" (click)="deleteDeepDive(row)">-->
                                        <!--                                            <i class="fa fa-trash"></i>-->
                                        <!--                                        </button>-->
                                    </td>
                                    <!--                                    <td>-->
                                    <!--                                        <span style="color: #ec8b00; cursor: pointer;" (click)="openNewTab('/#/project/'+row['project_id'])">{{ row['Project'] }}</span>-->
                                    <!--                                    </td>-->
                                    <!--                                    <td><button (click)="goToDashboard(row['project_id'], row['epe_id'])" class="u-btn sop-btn">Go to all surveys</button></td>-->
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading" class="modal fade" id="myModal" tabindex="-1" role="dialog" data-backdrop="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i style="color: #d9534f;" class="fa fa-times"></i>
                </button>
                <h4 *ngIf="getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'PENDING'" class="modal-title">Do you want to approve this CEN Workshop Agreements?</h4>
                <h4 *ngIf="getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'NOT APPROVED'" class="modal-title">You previously rejected this CEN Workshop Agreements. Do you want to approve it now?</h4>
                <h4 *ngIf="getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'APPROVED'" class="modal-title">You previously approved this CEN Workshop Agreements. Do you want to reject it now?</h4>
            </div>
            <div class="modal-body">
                <p *ngIf="getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'PENDING' || getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'NOT APPROVED'">
                    Do you want to approve the <b>{{ selectedDeepDive['name'] }}</b> CEN Workshop Agreements?
                </p>
                <p *ngIf="getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'APPROVED'">
                    Do you want to reject the <b>{{ selectedDeepDive['name'] }}</b> CEN Workshop Agreements?
                </p>
            </div>
            <div class="modal-footer" style="display: flex; justify-content: end;">
                <button type="button" class="u-btn secondary-btn" data-dismiss="modal" style="margin-right: 10px;">Close</button>
                <button
                    *ngIf="getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'APPROVED' || getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'PENDING'"
                        type="button" class="u-btn danger-btn" data-dismiss="modal" style="margin-right: 10px;" (click)="reject(selectedDeepDive)">Reject</button>
                <button
                    *ngIf="getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'PENDING' || getSelfStatus(getEpes(selectedDeepDive['epes'])) === 'NOT APPROVED'"
                        type="button" class="u-btn sop-btn" data-dismiss="modal" (click)="approve(selectedDeepDive)">Approve</button>
            </div>
        </div>

    </div>
</div>

<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert" ></simple-alert>