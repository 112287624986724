<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
    <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
      
        <div class="u-layout">
          <div class="u-layout-col">
            <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
              <div class="u-container-layout filter-layout">
                <a *ngIf="exportAuthorized()" (click)="exportCSV()" class="u-input u-input-rectangle u-radius-6 u-border-1 export">Export Surveys Status (CSV)</a>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  </section>
  
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="header">
              <h4 class="title">SOP surveys status</h4>
              <p class="category">You can see only approved, closed, and completed services</p>
            </div>
            <loader *ngIf="loading"></loader>
            <div class="content table-responsive">
              <div style="overflow-x: auto;">
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th *ngFor="let cell of headerRow">
                        <span *ngIf="sortSelected == cell && sortDirection == 'ASC'" (click)="sortCell(cell)">{{ cell }} <i style="color: #ec8b00; cursor: pointer;" onclick="sortCell(cell)" class="fa fa-arrow-up"></i></span>
                        <span *ngIf="sortSelected == cell && sortDirection == 'DESC'" (click)="sortCell(cell)">{{ cell }} <i style="color: #ec8b00; cursor: pointer;" class="fa fa-arrow-down"></i></span>
                        <span *ngIf="sortSelected != cell" (click)="sortCell(cell)">{{ cell }} <i style="cursor: pointer;" class="fa fa-arrow-up"></i></span>
                      </th>
                      <th>
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of associations">
                      <td>{{ row['ID'] }}</td>
                      <td>
                        <span style="color: #ec8b00; cursor: pointer;" (click)="openNewTab('/#/project/'+row['project_id'])">{{ row['Project'] }}</span>
                      </td>
                      <td>
                        <span style="color: #ec8b00; cursor: pointer;" (click)="openNewTab('/#/epe/'+row['epe_id'])">{{ row['EPE'] }}</span>
                      </td>
                      <td class="d-none d-md-table-cell">{{ row['Status'] }}</td>
                      <td class="d-none d-md-table-cell">{{ row['Project Survey 1'] }}</td>
                      <td class="d-none d-md-table-cell">{{ row['Project Survey 2'] }}</td>
                      <td class="d-none d-md-table-cell">{{ row['Project Survey 3'] }}</td>
                      <td class="d-none d-md-table-cell">{{ row['Project Survey 4'] }}</td>
                      <td class="d-none d-md-table-cell">{{ row['Final Epe Survey'] }}</td>
                      <td class="d-none d-md-table-cell">{{ row['Final Project Survey'] }}</td>
                      <td><button (click)="goToDashboard(row['project_id'], row['epe_id'])" class="u-btn sop-btn">Go to all surveys</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  