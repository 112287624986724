import { Alert } from './../../../../shared/alerts/simple-message/simple-message.component';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { MeetingFormService } from '../../meeting-form.service';
import { Fields } from './components/tick-box.component';

@Component({
  selector: 'form-composer',
  templateUrl: './form-composer.component.html',
  styleUrls: ['../../meeting-form.component.scss', './form-composer.component.scss']
})
export class FormComposerComponent implements OnInit {

  @Input() form_structure_name: string = ""


  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  fields: Fields = {
    'Question 1': ['A', 'B', 'C'],
    'Question 2': ['A', 'B', 'C', 'E', 'F'],
    'Question 3': ['A', 'B', 'C', 'E']
  }

  /*questions: Question[] = [
    {
      "Question 1": "open"
    },
    {
      "Question 2": ['A', 'B', 'C', 'E', 'F']
    },
    {
      "Quesiton 3": "editor"
    }
  ]*/

  questions: Question[];
  title: string; 

  MEETING_FORM = 0


  constructor(
    private mongoService: AssociationsService,
    private helper: AssociationHelper,
    private service: MeetingFormService,
    private router: Router
  ) {
    let meeting = this.helper.getMeetingForm()

    console.log(this.form_structure_name)
    if(meeting <= 0){
      meeting = 1
    }
    try{
      let data = require(`assets/meeting-form/advice.json`)
      this.questions = data['questions']
      this.title = data['title']
      this.MEETING_FORM = meeting
      console.log(data)
    } catch (e){
      console.log(e)
      this.questions = []
      this.title = ''
    }
  
   }

  ngOnInit(): void {
    
  }

  isOpenQuestion(question: Question) {

    return typeof question[this.getQuestionKey(question)] === 'string'

  }

  getQuestionKey(question: Question): string{
    return Object.keys(question)[0]
  }

  getQuestion(question: Question): string[] | string{
    return Object.values(question)[0]
  }

  confirm(): void{
    var submission = this.service.getSubmit()["Advice"]
    if(!submission || submission === ''){
      this.addAlert("'Advice Area' required to submit form", Alert.WARNING)
    }
    else{
      let meeting = this.helper.getMeetingForm()
      if(meeting <= 0)
        meeting = 1
      
      let nextStep = this.service.getNextStep(meeting)
      console.log(nextStep)
      let complete = false
      if(nextStep == 'first')
        complete = true
      this.mongoService.submitForm(
          this.helper.getSelectedProject(), 
          this.helper.getSelectedEpe(), 
          this.service.getSubmitMeetingValue(meeting), 
          this.service.getSubmit(),
          complete,
          false
          ).subscribe(
            then => {
              if(complete)
                meeting++
              this.helper.setMeetingForm(meeting)
              this.router.navigateByUrl(`form/(meeting-form:meeting_${meeting}/(meeting_${meeting}:${nextStep}))`)
            }
          )
    }

    
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }

}

export interface Question {
  [key:string]: string[] | string
}
