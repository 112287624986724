import { Epe } from 'app/models/epe';
import { Project } from 'app/models/project';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { SessionStorage } from 'app/storage/session.storage';
import { lastValueFrom } from 'rxjs';
import { SopStorage } from 'app/storage/sop.storage';
import { SopService } from 'app/services/sop.service';

@Component({
  selector: 'survey-dashboard',
  templateUrl: './survey-dashboard.component.html',
  styleUrls: ['./survey-dashboard.component.scss']
})
export class SurveyDashboardComponent implements OnInit {
  
  MEETING: number = 0
  project: Project = new Project()
  epe: Epe = new Epe()

  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  constructor(
    private router: Router,
    private service: AssociationsService,
    private helper: AssociationHelper,
    public auth: SessionStorage,
    private cache: SopStorage,
    private drupalService: SopService,
  ) { 
  
  }

  async ngOnInit(){

    let project_id = this.helper.getSelectedProject()
    let epe_id = this.helper.getActiveEpe() 

    let projects = this.cache.getProjects()
    let epes = this.cache.getEpes()

    if(!epes){
      epes = await lastValueFrom(this.drupalService.getAll('epe'))
      this.cache.setEpes(epes)
    }


    projects = projects ? projects.filter(project => project.id == project_id) : null
    if(!projects){
      projects = await lastValueFrom(this.drupalService.getAll('project'))
      this.cache.setProjects(projects)
    }
    console.log("PROJECT")
    console.log(projects)
    this.project = projects ? projects[0] : new Project()

    epes = epes ? epes.filter(epe => epe.id == epe_id) : null
    console.log("EPE")
    console.log(epes)
    this.epe = epes ? epes[0] : new Epe()

    if(this.helper.getSelectedEpe() < 0){
      this.router.navigate(['/associations'])
    }

    this.MEETING = (await lastValueFrom(this.service.getFormStatus(this.helper.getSelectedProject(), this.helper.getSelectedEpe()))).meeting
    
    
  }

}
