import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from './services/user.service';
import { SessionStorage } from './storage/session.storage';
import { SopStorage } from './storage/sop.storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{


  logged:boolean = false

  constructor(
    public location: Location, 
    private userService: UserService,
    private cache: SessionStorage,
    private sopCache: SopStorage) {}
    

  ngOnInit(){
    
    this.sopCache.clear()
  }

  isMap(path:any){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice( 1 );
    if(path == titlee){
      return false;
    }
    else {
      return true;
    }
  }
}
