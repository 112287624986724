import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {DeepDive} from "../models/deep_dive";


@Injectable({
    providedIn: 'root'
})

export class DeepDivesService {
    private url = '/api/v1/deepdives/';

    constructor(private httpClient: HttpClient) { }

    getAll(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.url);
    }

    createDeepDive(deepDive: any): Observable<any> {
        return this.httpClient.post<DeepDive>(this.url, deepDive);
    }

    deleteDeepDive(id: string): Observable<any> {
        return this.httpClient.delete<any>(this.url + id);
    }

    updateDeepDive(deepDive: any): Observable<any> {
        return this.httpClient.put<any>(this.url + deepDive._id.$oid, deepDive);
    }

    uploadReport(deepDiveId: string, epeId: string, report: any): Observable<any> {
        return this.httpClient.post<any>(this.url + 'report/' + deepDiveId + '/' + epeId, report);
    }

    downloadReport(deepDiveId: string, epeId: string): string {
        return this.url + 'report/' + deepDiveId + '/' + epeId;
    }

    deleteReport(deepDiveId: string, epeId: string): Observable<any> {
        return this.httpClient.delete<any>(this.url + 'report/' + deepDiveId + '/' + epeId);
    }
}