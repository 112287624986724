import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationHelper } from 'app/storage/association.storage';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { MeetingFormService } from '../meeting-form.service';
import { AssociationsService } from 'app/services/associations.service';
import { catchError, EMPTY, lastValueFrom } from 'rxjs';
import { SessionStorage } from 'app/storage/session.storage';
import { SopService } from 'app/services/sop.service';


@Component({
  selector: 'survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['../meeting-form.component.scss']
})
export class SurveyFormComponent implements OnInit {

  rating: number = 3

  form_structure_name = "survey"
  form: FormGroup
  loading: boolean = true

  questions: Question[];
  title: string; 

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  toSubmit: boolean = true

  levels: string[] = []
  levelsSelected: boolean[] = []
  materials: any[] = []
  materialsQuestions: any

  constructor(
    private formService: MeetingFormService,
    private mongoService: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    public auth: SessionStorage,
    private fb: FormBuilder,
    private sop: SopService
  ) { 
    
    try{
      if(this.auth.isProject()){
        this.form = this.formService.getProjectSurveyForm()
        var data = require(`assets/meeting-form/survey_project.json`)
      }
      else{
        this.form = this.formService.getEpeSurveyForm()
        var data = require(`assets/meeting-form/survey_epe.json`)
      }
      this.questions = data['questions']
      this.title = data['title']
    } catch (e){
      console.log(e)
      this.questions = []
      this.title = ''
      this.form = this.fb.group({})
    }
  }

  async ngOnInit() {
    if(this.helper.getSelectedProject() == -1 || this.helper.getSelectedEpe() == -1){
      this.router.navigateByUrl(`assigned-services`)
    }

    if(this.auth.isProjectBoard() || this.auth.isCallManager() || this.auth.isAdmin()){
      this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:admin))`)
    }
    if(this.auth.isProject()){
      var response = await lastValueFrom(this.mongoService.getProjectSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    }
    else{
      var response = await lastValueFrom(this.mongoService.getEpeSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    }

    
    console.log(response)
    if(!response){
      this.loading = false
      return
    }

    if(this.auth.isProject()){
      var form: { [key: string]: any; } = response['project_survey']
      this.sop.getAllMaterials().subscribe(data => {      
        this.materials = data
        this.levels = this.materials.map(x => x.level).filter((v, i, a) => a.indexOf(v) === i);
        this.levelsSelected = this.levels.map(x => false)
        for(let level of this.levels){
          this.form.addControl(level, new FormArray([]))
        }
      })
    }
    else{
      var form: { [key: string]: any; } = response['epe_survey']
    }

    if(form){
      if(this.auth.isProject()){
        this.formService.updateProjectSurveyForm(form)
      }
      else{
        this.formService.updateEpeSurveyForm(form)
      }
    }
    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false
  }

  expand(level: string): void{
    this.levelsSelected[this.levels.indexOf(level)] = !this.levelsSelected[this.levels.indexOf(level)]
  }

  expandedStatus(level: string): string{
    return this.levelsSelected[this.levels.indexOf(level)] ? 'in' : ''
  }

  getQuestionsByLevel(level: string): Question{
    let trainings = this.materials.filter(x => x.level === level).map(x => x.title)
    trainings.unshift('trainings')
    let questionsLevel: Question = {}
    questionsLevel[level] = trainings
    console.log('questions', questionsLevel)
    return questionsLevel
  }

  isDropdown(question: Question) {
    return this.getQuestion(question)[0] === 'drop_down'
  }

  isOpenQuestion(question: Question) {
    return typeof question[this.getQuestionKey(question)] === 'string'
  }

  getQuestionKey(question: Question): string{
    return Object.keys(question)[0]
  }

  getQuestion(question: Question): string[] | string{
    return Object.values(question)[0]
  }

  on(i: number): string{
    if(i<=this.rating)
      return 'on'
    return ''
  }

  rate(i:number): void{
    this.rating = i
  }

  confirm(): void{


    if(this.auth.isProject()){
        if (this.formService.getProjectSurveyForm().status == "INVALID") {
            this.addAlert("Please fill in all the required fields", Alert.ERROR)
            return
        }


      this.mongoService.submitProjectSurveyForm(
        this.helper.getSelectedProject(), 
        this.helper.getSelectedEpe(), 
        this.auth.isProject() ? this.formService.getProjectSurveySubmit() : this.formService.getEpeSurveySubmit()
        )
        .pipe(
          catchError(
            err => {
              this.addAlert("Survey update failed! Please contact the administrator", Alert.ERROR)
              return EMPTY
            }
          )
        )
        .subscribe(
          then => {
            this.helper.setMeetingForm(7)
            this.addAlert("Survey succesfully updated", Alert.SUCCESS)
            this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:summary))`)
          }
        )
    }
    else{
      if (this.formService.getEpeSurveyForm().status == "INVALID") {
          this.addAlert("Please fill in all the required fields", Alert.ERROR)
          return
      }
      this.mongoService.submitEpeSurveyForm(
        this.helper.getSelectedProject(), 
        this.helper.getSelectedEpe(), 
        this.auth.isProject() ? this.formService.getProjectSurveySubmit() : this.formService.getEpeSurveySubmit()
        )
        .pipe(
          catchError(
            err => {
              this.addAlert("Survey update failed! Please contact the administrator", Alert.ERROR)
              return EMPTY
            }
          )
        )
        .subscribe(
          then => {
            this.helper.setMeetingForm(7)
            this.addAlert("Survey succesfully updated", Alert.SUCCESS)
            this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:summary))`)
          }
        )
      }
  }

  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

}

export interface Question {
  [key:string]: string[] | string
}