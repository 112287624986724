<div class="form-container">
    <div class="form-box template-email">
        <p>Please contact your project and set a date for the final meeting</p>
        <p>The contact details for this project is can be found under the useful material and information section of the welcome page</p>
        <p>Please send this contact an e-mail using <strong>this suggested template</strong></p>
        <p>&nbsp;</p>


        <div class="panel-group" id="accordion">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h5 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Final Meeting email contact template</a>
                </h5>
              </div>
              <div id="collapse1" class="panel-collapse collapse">
                <div class="panel-body">
                  <p><strong>Subject: HSBooster.eu &ndash; Final meeting with Expert</strong></p>
                  <p>Dear [Project Applicant Name],</p>
                  <p>I am contacting you regarding standardisation consultancy service delivered to your project [project name]</p>
                  <p>I would like to schedule a call with you to discuss the main outcomes of this service. Here are some options that you can choose from:</p>
                  <p>Insert option 1</p>
                  <p>Insert option 2</p>
                  <p>Insert option 3</p>
                  <p>This will be an final meeting which will help us extract key lessons learned,, evaluate the progress and delineate possible steps for a future standardisation strategy</p>
                  <p>&nbsp;</p>
                  <p>Looking forward to your feedback</p>
                  <p>Best regards</p>
                  <p>Expert signature</p>
                </div>
              </div>
            </div>
        
          </div> 

    </div>
</div>