<div class="form-container">
    <div class="form-box template-email">
        <p>Thanks for booking the call with the project! You can self-manage the arrangement of the call and use the platform you prefer. In case you need support for arranging a call link, contact us at <a href="mailto:info@hsbooster.eu">info@hsbooster.eu</a>.</p>
        <p>You are now set for the meeting. Please make sure to collect relevant information for your activity report</p>
        <p>When you have completed the meeting, please make sure to send a follow-up e-mail with the main points discussed. If you wish, you can use <strong>this template</strong>, then come back to the platform to proceed</p>
        <div class="panel-group" id="accordion">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h5 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">Followup email template</a>
                </h5>
              </div>
              <div id="collapse1" class="panel-collapse collapse">
                <div class="panel-body">
                    <p><strong>Subject: HSBooster.eu &ndash; Meeting with Expert - Followup</strong></p>
                    <p>Dear [Project Applicant Name],</p>
                    <p>Thanks for joining the call.</p>
                    <p>Here is a recap of the topics and actions discussed:</p>
                    <p>[Insert summary]</p>
                    <p>I will be in touch for the next steps</p>
                    <p>Best Regards</p>
                    <p>Expert signature</p>
                </div>
              </div>
            </div>
        
          </div> 
    </div>
</div>