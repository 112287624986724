<div class="wrapper">
    <div class="sidebar" data-color="gray_plain">
        <app-sidebar></app-sidebar>
        <!-- <div class="sidebar-background" style="background-image: url(assets/img/sidebar-5.jpg)"></div> -->
    </div>
  
    <div class="main-panel">
        <navbar-cmp></navbar-cmp>
        <router-outlet></router-outlet>
        <footer-cmp></footer-cmp>
    </div>
  
  </div>
  