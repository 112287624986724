import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyAdminComponent } from './survey-admin.component';
import { SharedModule } from 'app/shared/shared.module';
import { FormSummaryModule } from '../form-steps/form-summary/form-summary.module';

@NgModule({
  declarations: [
    SurveyAdminComponent,
    
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormSummaryModule
  ],
  exports: [
    SurveyAdminComponent
  ]
})
export class SurveyAdminModule { }
